import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

export default function DeleteStoryDialog(props) {

  const { open, close, handleDelete } = props



  return (
    <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this story?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={close} color="primary" autoFocus>
                Cancel
            </Button>
            <Button onClick={handleDelete} color="primary" >
                Delete
            </Button>
        </DialogActions>
    </Dialog>
  )
}
