// TODO : admin text
// TODO : user image
// TODO: create typescript version of library

import React from 'react'
import DefaultUserPhoto from '../../images/user_default_photo.jpg'
import { Textfit } from 'react-textfit'
import UserContext from '../../context/UserContext'
import { useTheme } from '@material-ui/core'

function User(props) {
  const { user, showUserDetails } = props

  const theme = useTheme()

  const context = React.useContext(UserContext)
  const localUser = context
  const localUserEmail = localUser.email

  return (
    <div className="platform_tile" onClick={() => showUserDetails(user._id)}>
      <div
        className="platform_tile_logo"
        style={{
          position: 'relative',
          background: `url(${DefaultUserPhoto})center center/contain no-repeat`,
        }}
      />
      <Textfit
        mode="single"
        className={[
          'platform_tile_name',
          'smtitle',
          theme.palette.primary.main,
        ]}
      >
        {user.name ? user.name : user.email}
        {user.email === localUserEmail ? ', (Me)' : ''}
      </Textfit>
    </div>
  )
}

export default User
