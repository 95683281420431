import { configureStore } from '@reduxjs/toolkit'
import organizationReducer from './organizationSlice'
import exhibitReducer from './exhibitSlice'
import templateReducer from './templateSlice'
import mapStyleReducer from './mapStyleSlice'
import snackBarReducer from './snackBarSlice'
import assetsReducer from './assetsSlice'

export default configureStore({
  reducer: {
    organization: organizationReducer,
    exhibit: exhibitReducer,
    template: templateReducer,
    mapstyle: mapStyleReducer,
    snackBar: snackBarReducer,
    assets: assetsReducer
  }
})
