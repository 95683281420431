import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = makeStyles({
  root: {
    color: '#06c',
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    backgroundColor: '#39f',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: '#06c',
    },
  },
})


// TODO: class does not need to accept children props. Should just have a prop for text
export default function CategoryButton(props) {
  const { className, click } = props

  const classes = styles()

  return (
    <div
      onClick={click}
      className={clsx(classes.root, { [className]: className })}
    >
      {props.children}
    </div>
  )
}

CategoryButton.propTypes = {
  click: PropTypes.func.isRequired,
  className: PropTypes.string,
}
