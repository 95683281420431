import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1/2),
  },
  chip: {
    margin: theme.spacing(1/2),
  },
})

class StoryTag extends React.Component {

  render() {
    const { classes, data, handleDelete } = this.props

    return (
        <Chip
            label={data.value}
            onDelete={handleDelete(data)}
            className={classes.chip} />
    )
  }
}



export default withStyles(styles)(StoryTag)
