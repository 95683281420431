import React from 'react'
import { Route } from 'react-router-dom'
import AllCollectionsPage from './AllCollectionsPage'

function CollectionsPage(SingleAppPageProps){
  const { org, user } = SingleAppPageProps

    return (
        <div>
          <Route exact render={(props) =>
            <AllCollectionsPage
              {...props}
              {...SingleAppPageProps}
              org={org}
              user={user}
              drawerOpen={props.drawerOpen}
              toggleDrawer={props.toggleDrawer}/>}/>
        </div>
    )
}

export default CollectionsPage
