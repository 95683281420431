import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarContent } from '@material-ui/core'
import { getIsOpen, closeSnackBar, getMessage } from '../../store/snackBarSlice'

export default function UtilSnackbar() {
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsOpen)
  const message = useSelector(getMessage)

  const handleClose = () => dispatch(closeSnackBar())

  return (
    <Snackbar
      data-testid="snackbar"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}>

      <SnackbarContent
        aria-describedby="message-id"
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ]} />

    </Snackbar>
  )
}
