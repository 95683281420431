import React, { useState } from 'react'
import DarkLogo from '../../images/logo-dark.png'
import DefaultUserPhoto from '../../images/user_default_photo.jpg'
import DownArrowDark from '../../images/down-arrow-dark.png'
import PassitDownLogo from '../../images/passitdown-logo.png'
import Menu from './Menu'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Util from '../../Util'

const useStyles = makeStyles((theme) => ({
  adminBar: {
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    position: 'relative',
    color: '#333',
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      height: 110,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
      background: `url(${PassitDownLogo})center center/contain no-repeat`,
      float: 'left',
      position: 'relative',
      top: 0,
      left: 10,
    },
    [theme.breakpoints.up('md')]: {
      width: 260,
      height: 80,
      marginLeft: 20,
      background: `url(${DarkLogo})center center/contain no-repeat`,
    },
  },
  subnav: {
    color: '#333',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: 16,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
    paddingRight: 70,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
    display: 'flex',
    alignItems: 'center',
    height: 70,
  },
  down: {
    position: 'relative',
    right: -14,
    float: 'right',
    background: `url(${DownArrowDark})center center/contain no-repeat`,
    height: 9,
    width: 10,
  },
  items: {
    background: 'rgba(0,0,0,.5)',
    position: 'absolute',
    width: 150,
    left: 0,
    top: 60,
  },
  line: {
    padding: '3px 10px 3px 15px',
    fontSize: 14,
    textDecoration: 'none',
    fontWeight: 600,
    color: '#fff',
    borderTop: '1px solid rgba(0,0,0,.5)',
    '&:hover': {
      background: '#06c',
      color: '#fff',
    },
  },
  subPic: {
    float: 'left',
    width: 50,
    height: 50,
    marginRight: 10,
    borderRadius: '100%',
    border: '2px solid #333',
    position: 'relative',
    top: 0,
    background: `url(${DefaultUserPhoto})center center/cover no-repeat`,
  },
}))


export default function Header(props) {
  const [mouseEntered, setMouseEntered] = useState(false)

  const classes = useStyles()

  const isMobile = useMediaQuery('(max-width:600px)')

  const handleLogoClick = () => {
    props.history.push('/dashboard')
  }

  const handleOnMouseEnter = () => {
    setMouseEntered(true)
  }

  const handleOnMouseLeave = () => {
    setMouseEntered(false)
  }

  const handleSettingsClicked = () => {
    setMouseEntered(false)
    props.openSettingsDialog()
  }

  const handleLogoutClicked = () => {
    setMouseEntered(false)
    props.logout()
  }

  const handleMenuItemClick = (e, id) => {
    if (id === 'settings') {
      handleSettingsClicked()
    } else {
      // logout
      handleLogoutClicked()
    }
  }

  return (
    <div className={classes.adminBar}>
      <div onClick={handleLogoClick} className={classes.logo} />
      <div
        className={classes.subnav}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <div className={classes.subPic} />
        {!isMobile && (
          <>
            {Util.getDisplayName()}
            <div className={classes.down} />
            <div
              className={`${classes.items}`}
              style={{ display: mouseEntered ? 'block' : 'none' }}
            >
              <div className={classes.line} onClick={handleSettingsClicked}>
                Settings
              </div>
              <div className={classes.line} onClick={handleLogoutClicked}>
                Logout
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <Menu name={Util.getDisplayName()} click={handleMenuItemClick} />
        )}
      </div>
    </div>
  )
}
