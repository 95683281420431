import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export default function AppAccess(props){

    const { app, change, checked } = props

    return (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={change(app._id)}
                    checked={checked}
                    value= {app._id}/>}
                    label={app.name}/>
    )
}