import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'


const styles = {
    root: {
        float: 'left',
        fontFamily: '\'Roboto\', sans-serif',
        letterSpacing: -1,
        fontWeight: 700,
        lineHeight: 1.3,
        fontSize: 18,
        padding: '12px 2% 10px',
        background: '#222',
        color: '#FFF',
        fontSmoothing: 'antialiased',
        border: 'none',
        boxSizing: 'border-box',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        '&:hover': {
            background: '#444',
            cursor: 'pointer'
        }
    }
}


function Button(props){

    const { classes, text, className, click } = props

    return (
        <div 
            className={`${classes.root} ${className ? className : ''}`}
            onClick={click}>{text}</div>
    )
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    click: PropTypes.func.isRequired
}

export default withStyles(styles)(Button)