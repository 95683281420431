import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    padding: '4px 20px',
    float: 'left',
    textTransform: 'unset',
    borderRadius: 0,
    marginRight: 1,
    background: '#fff',
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: 500,
    lineHeight: 1.3,
    fontSize: 14,
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
    oFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    border: 'none',
    margin: '5px 5px 0 0',
  },
  filterLeft: {
    borderRadius: '30px 0 0 30px',
  },
  filterRight: {
    borderRadius: '0 30px 30px 0',
  },
})


export default function Filter(props) {
  const { leftMost, rightMost, orderBy, id, name, click } = props

  const classes = useStyles()

  const theme = useTheme()

  return (
    <div
      id={id}
      className={`${classes.root} ${leftMost ? classes.filterLeft : ''} ${
        rightMost ? classes.filterRight : ''
      }`}
      style={{
        backgroundColor: orderBy === id ? theme.palette.primary.main : '',
        color: orderBy === id ? '#FFF' : theme.palette.primary.main,
      }}
      onClick={() => click(id)}
    >
      {name}
    </div>
  )
}

Filter.propTypes = {
  leftMost: PropTypes.bool.isRequired,
  rightMost: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
}
