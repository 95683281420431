import './globals' // <-- first line
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import './index.css'
import Main from './Main'
import store from './store/store'
import * as serviceWorker from './serviceWorker'
import { IntercomProvider } from 'react-use-intercom'

const intercomId = process.env.REACT_APP_INTERCOM_APP_ID

ReactDOM.render(
    <Provider store={store}>
        <IntercomProvider appId={intercomId}>
            <Main />
        </IntercomProvider>
    </Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
