import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: 500,
    webkitFontSmoothing: 'antialiased',
    fontSize: 48,
    paddingLeft: '10px',
  },
}))


export default function Title(props) {
  const { app } = props

  const classes = useStyles()

  const [name, setName] = useState('')

  useEffect(() => {
    if (app) {
      setName(app.name)
    }
  }, [app])

  return <div className={classes.root}>{name}</div>
}
