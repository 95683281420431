import React, { useState, useEffect } from 'react'
import { List, withStyles } from '@material-ui/core'
import AppAccess from './AppAccess'

const styles = {
    appAccessListRoot: {
      display: 'flex',
      flexDirection: 'column'
    }
}

function AppAccessList(props){

    const {  classes, user, changeAppAccess, apps } = props

    const [userAppsIds, setUserAppsIds] = useState([])

    useEffect(() => {
        const arr = []
        user.apps.forEach(app => {
            arr.push(app._id)
        })
        setUserAppsIds(arr)
    }, [user.apps])


    return (
        <List classes={{
            root: classes.appAccessListRoot
            }}>
            {/* ignoring the last entry which is needed for adding new apps */}
            {apps.filter(a => a._id !== undefined).map((app, index) => (
              <AppAccess 
                key={index} 
                app={app} 
                checked={userAppsIds.indexOf(app._id) !== -1}
                change={changeAppAccess}/>
            ))}
        </List>
    )
}

export default withStyles(styles)(AppAccessList)