import React from 'react'
import { Dialog, TextField } from '@material-ui/core'
import GrayArrow from '../../images/gray_arrow.png'
import ColorPicker from './ColorPicker'
import FormTitle from '../UI/form/FormTitle'
import CustomSwitch from '../UI/form/Switch'
import MultilineText from '../UI/form/MultilineText'
import { storiesOrder, mapZooms } from '../../Util'
import FormRow from '../UI/form/FormRow'
import Geosuggest from 'react-geosuggest'
import { withStyles, FormControl } from '@material-ui/core'
import SquaresTemplateDisplayChooser from './SquaresTemplateDisplayChooser'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'
import { createExhibitValidator } from '@Pass-It-Down/exhibition-admin-client'
import { showMessage } from '../../store/snackBarSlice'
import { addExhibit } from '../../store/exhibitSlice'
import { loadByOrgId as loadTemplatesByOrg } from '../../store/templateSlice'
import { connect } from 'react-redux'
import adminApi from '../../store/exhibitAdminApi'


const mapStateToProps = (state) => ({
  templates: state.template.all
})

const mapDispatchToProps = { showMessage, loadTemplatesByOrg, addExhibit }

const styles = {
  mapCenterAddressInput: {
    height: 46,
    border: '1px solid #ccc',
    fontSize: 15,
    color: '#666',
    paddingLeft: 15,
    fontSmoothing: 'subpixel-antialiased',
    boxSizing: 'border-box',
    width: '100%',
    fontWeight: 300,
    fontFamily: '\'Roboto\', Helvetica, Arial, Tahoma, Sans-Serif',
    borderRadius: 30,
  },
  mapStyle: {
    paddingRight: 5,
  },
  downArrow: {
    width: '100%',
    paddingRight: '10%',
    background: `url(${GrayArrow})center center/contain no-repeat`,
    backgroundPosition: 'right',
    backgroundSize: 35,
  },
  saveAppButton: {
    margin: 10,
    textAlign: 'center',
  },
}


class CreateAppDialog extends React.Component {

  state = adminApi.exhibit().new()

  async componentDidMount() {
    await this.props.loadTemplatesByOrg(this.props.org._id)
  }

  handleChange = (e) => {
    const id = e.target.id
    const value = e.target.value

    switch(id) {
      case 'name':
        this.setState({ name: value })
        break
      case 'description':
        this.setState({ description: value })
        break
      case 'googleAnalyticsToken':
        this.setState({ googleAnalyticsToken: value })
        break
      case 'redirect-link':
        this.setState({ userGeneratedContentRedirectionLink: value })
        break
      default:
        console.error('error')
    }
  }

  handleSave = async () => {
    const exhibit = {
      name: this.state.name,
      description: this.state.description,
      template: this.state.template,
      displayOption: this.state.displayOption,
      storiesOrder: this.state.storiesOrder,
      googleAnalyticsToken: this.state.googleAnalyticsToken,
      mainColor: this.state.mainColor,
      secondaryColor: this.state.secondaryColor,
      viewTagsPublicly: this.state.viewTagsPublicly,
      userGeneratedContentAllowed: this.state.userGeneratedContentAllowed,
      mapStyle: this.state.mapStyle,
      termsOfService: this.state.termsOfService,
      userGeneratedContentRedirectionLink: this.state.userGeneratedContentRedirectionLink,
      mapCenterAddress: this.state.mapCenterAddress,
      mapCenterAddressCoords: this.state.mapCenterAddressCoords,
      mapZoom: this.state.mapZoom,
    }

    exhibit.orgId = this.props.org._id
    const user = this.props.user

    const v = createExhibitValidator(exhibit)

    if(v.isValid) {
      const newExhibit = await this.props.addExhibit(exhibit, user._id)
      if(newExhibit) {
        this.props.handleClose()
        this.props.showMessage('Exhibit added')
      }
    } else {
      const message = v.messages.map(m => m.message).join('. ')
      this.props.showMessage(message)
    }
  }

  handleTemplateChange = (e) => {
    this.setState({ template: e.target.value })
  }

  handleStoriesOrderChange = (e) => {
    this.setState({ storiesOrder: e.target.value })
  }

  handleSecondaryColorSwatchClick = () => {
    this.setState({ displaySecondaryColorPicker: !this.state.displaySecondaryColorPicker })
  }

  handleCloseSecondaryColorPicker = () => {
    this.setState({ displaySecondaryColorPicker: false })
  }

  handleMainColorPickerChange = (color) => {
    this.setState({ mainColor: color.hex })
  }

  handleSecondaryColorPickerChange = (color) => {
    this.setState({ secondaryColor: color.hex })
  }

  handleTagPublicityChange = (e) => {
    this.setState({ viewTagsPublicly: e.target.checked })
  }

  handleUserGeneratedContent = (e) => {
    this.setState({ userGeneratedContentAllowed: e.target.checked })
  }

  handleMapStyleChange = (e) => {
    this.setState({ mapStyle: e.target.value })
  }

  handlePrivacyPolicyChange = (e) => {
    this.setState({ termsOfService: e.target.value })
  }

  resetState = () => {
    const newState = adminApi.exhibit().new()
    delete (newState.templates)
    this.setState(newState)
  }

  handleClose = () => {
    this.resetState()
    this.props.handleClose()
  }

  handleMapCenterAddressSelect = (suggest) => {
    let newState = {
      mapCenterAddress: '',
      mapCenterAddressCoords: { lat: null, lng: null }
    }

    if(suggest) {
      newState = {
        mapCenterAddress: suggest.label,
        mapCenterAddressCoords: {
          lat: suggest.location.lat,
          lng: suggest.location.lng,
        }
      }
    }

    this.setState(newState)
  }

  handleGeoSuggestChange = (value) => {
    this.setState({ mapCenterAddress: value })
  }

  handleMapZoomChange = (e) => {
    this.setState({ mapZoom: e.target.value })
  }

  handleDisplayOptionChange = (displayOption) => {
    this.setState({ displayOption: displayOption })
  }

  render() {
    const { classes, open, org, mapStyles, user } = this.props

    return (
      <Dialog open={open} fullWidth maxWidth={'md'} onClose={this.handleClose}>
        <div className={clsx(['whitebg animated fadeIn'])}>
          <div style={{ margin: 16 }}>
            <div className='title'>{org.name}</div>
            <div className='subtitle maincolor'>Add a New App:</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 16px 16px 16px',
                width: '50%',
              }}>
              <div className='formrow'>
                <MultilineText
                  id={'name'}
                  title={'Name: (100 char limit)'}
                  placeholder={'Name of your app'}
                  value={this.state.name}
                  change={this.handleChange}
                  maxLength={100} />
              </div>

              <FormRow fullWdith>
                <FormTitle text={'App Description:'} />
                <textarea
                  style={{ width: '100%', minHeight: 150 }}
                  rows={10}
                  id='description'
                  value={this.state.description}
                  onChange={this.handleChange}
                  placeholder='App description' />
              </FormRow>

              <FormRow>
                <FormTitle text={'Map Center Address:'} />
                <Geosuggest
                  onSuggestSelect={this.handleMapCenterAddressSelect}
                  onChange={this.handleGeoSuggestChange}
                  initialValue={this.state.mapCenterAddress}
                  inputClassName={classes.mapCenterAddressInput}
                  placeholder={'Address, City, State Zip'} />
              </FormRow>

              <div style={{ display: 'flex' }}>

                <FormRow halfWidth className={classes.mapStyle}>
                  <FormTitle text={'Map Style:'} />
                  <select
                    select={this.state.mapStyle}
                    onChange={this.handleMapStyleChange}
                    className={classes.downArrow}>
                    {mapStyles.map((style) => (
                      <option key={style.value} value={style.value}>
                        {style.label}
                      </option>
                    ))}
                  </select>
                </FormRow>

                <FormRow halfWidth>
                  <FormTitle text={'Map Zoom:'} />
                  <select
                    select={this.state.mapZoom}
                    onChange={this.handleMapZoomChange}
                    className={classes.downArrow}>
                    {mapZooms.map(zoom => (
                      <option key={zoom.value} value={zoom.value}>
                        {zoom.label}
                      </option>
                    ))}
                  </select>
                </FormRow>

              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 16,
                width: '50%',
              }}>
              <div style={{ paddingLeft: 0 }}>
                <div className='formrow'>
                  <FormTitle text={'Template:'} />
                  <select
                    id='plattemplate'
                    defaultValue={'DEFAULT'}
                    onChange={this.handleTemplateChange}
                    style={{
                      width: '98%',
                      background: `url(${GrayArrow})center center/contain no-repeat`,
                      backgroundPosition: 'right',
                      backgroundSize: 35,
                    }}>
                    <option value='DEFAULT' hidden disabled>Select template</option>
                    {this.props.templates.map((template, _index) => (
                      <option key={template.id} value={template.name}>
                        {template.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <FormControl
                margin='normal'
                fullWidth
                style={{ display: this.state.template === 'squares' ? 'block' : 'none' }}>
                <SquaresTemplateDisplayChooser
                  handleChange={this.handleDisplayOptionChange}
                  displayOption={this.state.displayOption} />
              </FormControl>
              <div style={{ paddingLeft: 0 }}>
                <div className='formrow'>
                  <FormTitle text={'Order of Stories:'} />
                  <select
                    id='stories-order'
                    value={this.state.storiesOrder}
                    onChange={this.handleStoriesOrderChange}
                    style={{
                      width: '98%',
                      background: `url(${GrayArrow})center center/contain no-repeat`,
                      backgroundPosition: 'right',
                      backgroundSize: 35,
                    }}>
                    {storiesOrder.map((order, index) => (
                      <option key={index} value={order.value}>
                        {order.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {user?.isSuperAdmin && (
                <>
                  <div className='formrow'>
                    <TextField
                      id='googleAnalyticsToken'
                      label='Google Analytics Token'
                      placeholder='UA-######-#'
                      value={this.state.googleAnalyticsToken}
                      onChange={this.handleChange}
                      multiline
                      margin='normal'
                    />
                  </div>
                </>
              )}

              <CustomSwitch
                checked={this.state.viewTagsPublicly}
                change={this.handleTagPublicityChange}
                label='View Tags Publicly' />

              <CustomSwitch
                checked={this.state.userGeneratedContentAllowed}
                change={this.handleUserGeneratedContent}
                label='Allow users to submit content' />

              {this.state.userGeneratedContentAllowed && (
                <>
                  <div className='formrow'>
                    <FormTitle text={'Link to redirect to:'} />

                    <input
                      id='redirect-link'
                      type='text'
                      value={this.state.userGeneratedContentRedirectionLink}
                      onChange={this.handleChange}
                      placeholder='Default is passitdown.com' />

                  </div>

                  <MultilineText
                    title={'Terms of Service'}
                    placeholder={'Enter your Terms of Service (default is Pass It Down\'s)'}
                    value={this.state.termsOfService}
                    change={this.handlePrivacyPolicyChange}
                  />
                </>
              )}

            </div>
          </div>

          <ColorPicker
            type={'Main'}
            color={this.state.mainColor}
            colorPickerChange={this.handleMainColorPickerChange} />

          <ColorPicker
            type={'Secondary'}
            color={this.state.secondaryColor}
            colorPickerChange={this.handleSecondaryColorPickerChange} />

          <CategoryButton
            click={this.handleSave}
            className={clsx(classes.saveAppButton, 'm10', 'bold', 'white')}>
            Save Exhibit
          </CategoryButton>
        </div>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateAppDialog))
