import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from './components/header/Header'
import Dashboard from './components/Dashboard'
import { Route } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import LogoutDialog from './LogoutDialog'
import { Redirect } from 'react-router-dom'
import AccountDetailsDialog from './AccountDetailsDialog'
import OrganizationDetailsPage from './components/organization/OrganizationDetailsPage'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  drawerHeader: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  organization: {
    margin: 0,
    padding: '15px 2%',
    background: '#f1f1f1',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginTop: 20,
  },
  categoryButton: {
    color: '#06c',
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    background: '#06c',
    fontWeight: '700',
    letterSpacing: '-.6px',
  },
})

class LandingPage extends Component {
  state = {
    drawerOpen: false,
    loggedIn: false,
    showDialog: false,
    direction: 'up',
    open: false,
    hidden: false,
    redirecToLoginPage: false,
    openAccountDetailsDialog: false,
    showSnackBar: false,
    openCreateOrganizationDialog: false,
    organizations: [],
    snackbar: {
      variant: '',
      message: '',
    },
    openSnackbar: false,
    snackbarMessage: '',
  };

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    })
  };

  handleDrawerItemClick = (index) => () => {
    this.toggleDrawer()
    switch (index) {
      case 0:
        this.props.history.push('/dashboard')
        break
      default:
        console.error('should not be here')
    }
  };

  handleCloseLogoutDialog = () => {
    this.setState({
      showDialog: false,
    })
  };

  handleOpenLogoutDialog = () => {
    this.setState({
      showDialog: true,
    })
  };

  handleLogout = async () => {
    await firebase.auth().signOut()
    console.log('user has signed out')
    this.setState({
      redirecToLoginPage: true,
    })
  };

  handleClick = () => () => {
    this.setState({ open: !this.state.open })
  };

  handleOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleOpenAccountDialog = () => {
    this.setState({ openAccountDetailsDialog: true })
  };

  handleShowSnackBar = (message) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
    })
  };

  handleCloseSnackbar = () => {
    this.setState({ showSnackBar: false })
  };

  handleCloseAccountDetailsDialog = () => {
    this.setState({ openAccountDetailsDialog: false })
  };

  handleCloseCreateOrganizationDialog = () => {
    this.setState({ openCreateOrganizationDialog: false })
  };

  handleOrgClick = (orgId) => {
    this.props.history.push(`/organization/${orgId}`)
  };

  handleLogoutUser = () => {
    this.handleOpenLogoutDialog()
  };

  handleOpenSettingsDialog = () => {
    this.handleOpenAccountDialog()
  };

  render() {
    const { classes } = this.props

    const pathName = this.props.location.pathname

    if (pathName === '/') {
      return <Redirect to="/dashboard" />
    }

    if (this.state.redirecToLoginPage) {
      return <Redirect to="/login" />
    }

    return (
      <div className={classes.root}>
        <Header
          {...this.props} // passing props so that header has access to react router for going back to dashboard
          openSettingsDialog={this.handleOpenSettingsDialog}
          logout={this.handleLogoutUser}
        />

        <Route
          path={'/dashboard'}
          exact
          render={(props) => (
            <Dashboard
              {...props}
              drawerOpen={this.state.drawerOpen}
              toggleDrawer={this.toggleDrawer}
            />
          )}
        />

        <Route
          path={'/organization/:orgId'}
          render={(props) => (
            <OrganizationDetailsPage
              {...props}
              showSnackBar={this.handleShowSnackBar}
              drawerOpen={this.state.drawerOpen}
              toggleDrawer={this.toggleDrawer}
            />
          )}
        />

        <LogoutDialog
          showDialog={this.state.showDialog}
          handleLogout={this.handleLogout}
          handleCloseDialog={this.handleCloseLogoutDialog}
        />

        <AccountDetailsDialog
          open={this.state.openAccountDetailsDialog}
          handleClose={this.handleCloseAccountDetailsDialog}
        />
      </div>
    )
  }
}

export default withStyles(styles)(LandingPage)
