import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'


class DeleteConfirmationDialog extends React.Component {
  static defaultProps = {
    name: '',
    open: false,
  };

  render() {
    const { open, close, name, deleteConfirmed } = this.props

    return (
      <Dialog open={open} onClose={close}>
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete ${name}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={close} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={deleteConfirmed} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DeleteConfirmationDialog
