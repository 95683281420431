import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Container from '../UI/Container'
import SubTitle from '../UI/SubTitle'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'

const styles = () =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    leftAlignedButtons: {
      backgroundColor: 'unset',
      display: 'flex',
      alignItems: 'center',
    },
    flex: {
      flex: 1,
      margin: '0 16px',
    },
    delete: {
      display: 'flex',
      marginTop: 20,
    },
    saveButton: {
      margin: '10px 0 15px !important',
    },
  })

// TODO: change design to make it consistent with other dialogs
class DeleteOrganizationDialog extends React.Component {
  render() {
    const { classes, org, open, close, deleteOrg } = this.props

    return (
      <Dialog open={open} onClose={close}>
        <Container>
          <SubTitle>Delete {org.name}</SubTitle>
        </Container>

        <Typography variant="h6" color="inherit" className={classes.flex}>
          Are you sure you want to delete {org.name}?
        </Typography>

        <div
          style={{ display: 'flex', alignItems: 'center', margin: '0 16px' }}
        >
          <CategoryButton
            click={close}
            className={clsx(classes.leftAlignedButtons, 'bold')}
          >
            Cancel
          </CategoryButton>
          <div style={{ flexGrow: 1 }} />
          <CategoryButton
            click={deleteOrg(org._id)}
            className={clsx(classes.saveButton, 'white', 'bold')}
          >
            Delete {org.name}
          </CategoryButton>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(DeleteOrganizationDialog)
