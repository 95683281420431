import React, { useState, useEffect } from 'react'
import UploadLogo from '../../images/upload_logo.png'
import UserContext from '../../context/UserContext'
import PlatformTile from '../UI/PlatformTile'
import { makeStyles } from '@material-ui/core'
import adminApi from '../../store/exhibitAdminApi'

const useStyles = makeStyles((theme) => ({
  organization: {
    margin: '0 15px',
  },
  mainColor: {
    color: theme.palette.primary.main,
  },
}))

function Organization(props) {
  const classes = useStyles()

  const { org, handleClickEdit } = props

  const context = React.useContext(UserContext)

  const [isAdmin, setIsAdmin] = useState(false)

  const user = context

  const hasAdminAccess = () => {
    if (!user.orgAccess) return false
    const found = user.orgAccess.find(access => access.org === org._id)
    if (found && found.isAdmin) return true
    return false
  }

  useEffect(() => {
    if (user.isSuperAdmin || hasAdminAccess()) {
      setIsAdmin(true)
    }
  }, [])

  const handleClick = function () {
    props.handleClick(org._id)
  }

  let platformLogo = org.logo ? org.logo : UploadLogo
  if(org.logo) {
    platformLogo = adminApi.image().imageUrl(org.logo, 400, 400)
  }

  if (platformLogo.startsWith('/images')) {
    platformLogo = UploadLogo
  }

  return (
    // counteracts negative margin on parent container for equal spacing between items when using flexbox
    <PlatformTile className={classes.organization}>
      <div
        onClick={handleClick}
        className="platform_tile_logo"
        style={{
          background: `url(${platformLogo})center center/contain no-repeat #fff`,
        }}
      />
      <div
        className={['platform_tile_name', 'smtitle', classes.mainColor].join(
          ' '
        )}
      >
        {props.org.name}
      </div>
      <div data-testid='edit-org' className="tc m10" style={{ display: isAdmin ? 'block' : 'none' }}>
        <span
          onClick={() => handleClickEdit(org._id)}
          className="category_button smbutton secondbg white"
          style={{ float: 'unset' }}
        >
          Edit
        </span>
      </div>
    </PlatformTile>
  )
}

export default Organization
