import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = makeStyles({
  root: {
    marginLeft: 15,
    width: 'fit-content',
    position: 'relative',
    zIndex: 1,
    fontSize: 10,
    padding: '1px 3px 0 3px',
    top: 6,
    left: 0,
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#222',
    lineHeight: 1,
    fontSmoothing: 'antialiased',
    background: '#fff',
    textTransform: 'uppercase',
    letterSpacing: '.5px'
  },
})


export default function FormTitle(props) {
  const { className, text } = props

  const classes = styles()

  return (
    <div
      className={clsx(classes.root, className && { [className]: className })}
    >
      {text}
    </div>
  )
}

FormTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}
