import { createSlice } from '@reduxjs/toolkit'
import adminApi from './exhibitAdminApi'

export const exhibitSlice = createSlice({
  name: 'exhibit',
  initialState: {
    current: null,
    isAdmin: false,
    all: []
  },
  reducers: {
    setCurrentExhibit: (state, action) => {
      state.current = action.payload
    },
    setExhibits: (state, action) => {
      state.all = action.payload
    },
    setExhibitAdmin: (state, action) => {
      state.isAdmin = action.payload
    }
  }
})

export const { setCurrentExhibit, setExhibits, setExhibitAdmin } = exhibitSlice.actions

export const loadByOrgIdAndUserId = (orgId, user) => async (dispatch) => {
  dispatch(setExhibits([]))
  dispatch(setExhibitAdmin(false))

  const { exhibits, isAdmin } = user.isSuperAdmin ?
    { exhibits: await adminApi.exhibit().getAllByOrgId(orgId), isAdmin: true } :
    await adminApi.exhibit().getByOrgIdAndUserId(orgId, user._id)

  dispatch(setExhibits(exhibits))
  dispatch(setExhibitAdmin(isAdmin || user.isSuperAdmin))
}

export const addExhibit = (exhibit, userId) => async (dispatch, getState) => {
  const savedExhibit = await adminApi.exhibit().create({exhibit, userId})
  if(!savedExhibit) return null

  const exhibits = [ ...getState().exhibit.all, savedExhibit]
  dispatch(setExhibits(exhibits))
  return savedExhibit
}

export const updateExhibit = (exhibitUpdate) => async (dispatch, getState) => {
  const updatedExhibit = await adminApi.exhibit().save(exhibitUpdate)
  if(!updatedExhibit) return null

  const orgs = getState().exhibit.all.map(org => {
    return org._id === updatedExhibit._id ?  updatedExhibit : org
  })

  dispatch(setExhibits(orgs))
  return updatedExhibit
}

export const deleteExhibit = (orgId) => async (dispatch, getState) => {
  const success = await adminApi.exhibit().deleteById(orgId)
  if(success) {
    const orgs = getState().exhibit.all.filter(org => org._id !== orgId)
    dispatch(setExhibits(orgs))
  }
  return success
}

export const allExhibits = state => state.exhibit.all
export const isAdmin = state => state.exhibit.isAdmin

export default exhibitSlice.reducer

