import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import clsx from 'clsx'

const styles = makeStyles((theme) => ({
  root: {
    margin: '10px 0',
  },
  oldFormTitle: {
    fontSize: 20,
    width: '100%',
    margin: '10px 0 2px',
    fontWeight: 600,
  },
  collection: {
    float: 'left',
    margin: '0 10px 10px 0',
    borderRadius: 50,
    fontSize: 15,
    padding: '7px 16px',
    background: '#666',
  },
  collectionClicked: {
    background: theme.palette.primary.main,
  },
  subCollectionClicked: {
    background: `${theme.palette.primary.main} !important`,
    color: 'white !important',
  },
  addNew: {
    background: '#06c',
  },
  subCollection: {
    fontSize: 14,
    fontWeight: 600,
    padding: '4px 13px',
    background: '#f1f1f1',
    color: '#666',
    '&:hover': {
      background: '#444',
      cursor: 'pointer',
      color: 'white',
    },
  },
  formTitle: {
    margin: '15px 0',
  },
  cancel: {
    background: '#666',
  },
  save: {
    background: '#0C3',
  },
  addNewCollectionStyles: {
    width: 250,
    borderRadius: 50,
  },
  marginOffset: {
    margin: '10px 0 0 10px',
  },
}))


export default function AddNew(props) {
  const { cancel, add, text, placeholder } = props

  const classes = styles()

  const [value, setValue] = useState('')

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleEnter = e => {
    if(e.keyCode !== 13) return
    handleAdd(value)
  }

  const handleCancel = () => {
    setValue('')
    cancel()
  }

  const handleAdd = value => {
    add(value)
    setValue('')
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 250 }}>
        <Input
          className={classes.addNewCollectionStyles}
          text={text}
          placeholder={placeholder}
          value={value}
          change={handleChange}
          keyDown={handleEnter}
        />
      </div>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <Button
          text={'Add'}
          click={() => handleAdd(value)}
          className={clsx(classes.collection, classes.save, classes.marginOffset)} />

        <Button
          text={'Cancel'}
          className={clsx(classes.collection, classes.cancel, classes.marginOffset)}
          click={handleCancel} />
      </div>
    </div>
  )
}