import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import clsx from 'clsx'

const styles = makeStyles(() =>
  createStyles({
    root: {
      color: '#999',
      fontSize: 12,
      fontWeight: 300,
      fontFamily: 'HelveticaNeue, "OpenSans", Helvetica, sans-serif',
      letterSpacing: 0,
    },
  })
)

export default function SmallText(props) {
  const { className, text } = props
  const classes = styles()

  return (
    <div className={clsx(classes.root, className && className)}>{text}</div>
  )
}
