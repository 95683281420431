import { createSlice } from '@reduxjs/toolkit'
import adminApi from './exhibitAdminApi'
import Util from '../Util'

export const templateSlice = createSlice({
  name: 'templateSlice',
  initialState: {
    all: []
  },
  reducers: {
    setTemplates: (state, action) => {
      state.all = action.payload
    }
  }
})

export const { setCurrentTemplate, setTemplates } = templateSlice.actions

export const loadByOrgId = (orgId) => async (dispatch) => {
  dispatch(setTemplates([]))

  const [templates, portResponse, lflbResponse ]  = await Promise.all([
    adminApi.template().getAll(),
    adminApi.organization().getPortland(),
    adminApi.organization().getLFLB()
  ])
  const [ newTemplates ] = Util.specialTemplateSuperWow(templates, orgId, portResponse, lflbResponse)

  dispatch(setTemplates(newTemplates))
}

export default templateSlice.reducer

