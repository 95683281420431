import React, { useCallback, useState } from 'react'
import Trumbowyg from 'react-trumbowyg'
import { makeStyles } from '@material-ui/core'

import 'react-trumbowyg/dist/trumbowyg.min.css'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    marginRight: 10,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

function TextUpload(props) {
  const { index, asset, handleTextChange } = props
  const [text] = useState(asset.text)

  const classes = useStyles()

  const rootRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [])

  const handleChange = e => {
    // Change state in the parent without triggering a rerender
    // due to a bug in Trumbowyg causing cursor to move to front
    // of text when props change.
    handleTextChange(index, e.target.innerHTML)
  }

  return (
    <div ref={rootRef} className={classes.root}>
      <Trumbowyg
        id={`${index}`}
        buttons={
          // added buttons so I can remove "Insert Image" option
          [
            ['viewHTML'],
            ['h1', 'h2', 'h3', 'h4', 'p'],
            'btnGrp-semantic',
            ['link'],
            'btnGrp-justify',
            'btnGrp-lists',
            ['fullscreen'],
            ['removeformat'],
            ['strong', 'em', 'del'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['formatting'],
          ]
        }
        data={text}
        // on blur used instead of onChange since this is a bug with the editor that causes the cursor to remain at
        // the front of the text on every change if onChange is used  -https://github.com/RD17/react-trumbowyg/issues/1
        onChange={handleChange}
        onPaste={handleChange}
      />
    </div>
  )
}

export default TextUpload
