import React from 'react'
import NoMediaImage from '../../images/no_media.png'
import AudiotrackIcon from '../../images/headphones-monochrome.png'
import PDFIcon from '../../images/pdf_icon.jpg'
import Util from '../../Util'
import StoryEditLink from './StoryEditLink'
import StoryStatus from './StoryStatus'
import { makeStyles } from '@material-ui/core'
import StoryType from './StoryType'
import adminApi from '../../store/exhibitAdminApi'

const useStyles = makeStyles({
  storyCoverImage: {
    width: '250px',
    height: '250px',
    objectFit:  'cover',
    objectPosition: 'center',
    cursor: 'grab',
  }
})

function StoryCover(props) {
  const { story, app } = props

  const classes = useStyles()

  let image = story.image

  if (!image) {
    image = NoMediaImage
  } else if (Util.isAudioFile(image)) {
    image = AudiotrackIcon
  } else if (image.includes('.pdf')) {
    image = PDFIcon
  } else {
    image = adminApi.image().imageUrl(image, 400, 400)
  }


  const imageStyle = {
    background: `url(${image})center center/cover no-repeat`,
    borderRadius: '8px 8px 9px 9px',
  }


  return (
    <div style={imageStyle}>
      <StoryType type={story.storyType} />
      <StoryStatus status={story.status} />
      <img className={classes.storyCoverImage} src={image} alt="" />
      <StoryEditLink
        id={story.id}
        title={story.title}
        mainColor={app.mainColor}
        secondaryColor={app.secondaryColor}
        handleClickStory={props.handleClickStory}
      />
    </div>
  )
}

export default StoryCover
