import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Route } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { Redirect } from 'react-router-dom'
import AccountDetailsDialog from '../../AccountDetailsDialog'
import Apps from '../apps/Apps'
import { loadCurrentOrganization } from '../../store/organizationSlice'

const mapStateToProps = (state) => ({
  org: state.organization.current
})

const mapDispatchToProps = { loadCurrentOrganization }

const styles = theme => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column'
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  drawerHeader: {
    display: 'flex'
  },
  margin: {
    margin: theme.spacing(1),
  },
  organization: {
    margin: 0,
    padding: '15px 2%',
    background: '#f1f1f1',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginTop: 20
  },
  categoryButton: {
    color: '#06c',
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    background: '#06c',
    fontWeight: '700',
    letterSpacing: '-.6px'
  }
})


class OrganizationDetailsPage extends Component {

  state = {
    loggedIn: false,
    showDialog: false,
    direction: 'up',
    open: false,
    hidden: false,
    redirecToLoginPage: false,
    openAccountDetailsDialog: false,
    message: '',
    openCreateOrganizationDialog: false
  }

  async componentDidMount() {
    const orgId = this.props.match.params.orgId
    this.props.loadCurrentOrganization(orgId)
  }


  handleCloseLogoutDialog = () => {
    this.setState({ showDialog: false })
  }

  handleOpenLogoutDialog = () => {
    this.setState({ showDialog: true })
  }

  handleLogout = () => {
    firebase.auth().signOut()
    this.setState({ redirecToLoginPage: true })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpenAccountDialog = () => {
    this.setState({ openAccountDetailsDialog: true })
  }


  handleCloseAccountDetailsDialog = () => {
    this.setState({ openAccountDetailsDialog: false })
  }

  handleCloseCreateOrganizationDialog = () => {
    this.setState({ openCreateOrganizationDialog: false })
  }

  handleCloseCreateAppDialog = () => {
    this.setState({ openCreateAppDialog: false })
  }

  handleOrgClick = (orgId) => {
    this.props.history.push(`/organization/${orgId}`)
  }

  handleAppClick = () => {
    this.props.history.push(`${this.props.match.url}/apps/120`)
  }


  render() {

    const { classes } = this.props


    if(this.state.redirecToLoginPage) {
      return <Redirect to='/login' />
    }

    return (
      <div className={classes.root}>

        {this.props.org &&
          <Route path={`${this.props.match.path}/apps`}
            render={(props) =>
              <Apps
                {...props}
                drawerOpen={this.props.drawerOpen} toggleDrawer={this.props.toggleDrawer}
                handleAppClick={this.handleAppClick} />
            }
          />
        }

        <AccountDetailsDialog
          open={this.state.openAccountDetailsDialog}
          handleClose={this.handleCloseAccountDetailsDialog}
        />

      </div>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationDetailsPage))