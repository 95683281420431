import React from 'react'

export default function Container(props) {
  // "container" class name does not appear anywhere in the CSS except in the Bootstrap that's been added
  return (
    <div className={'container'} style={{ minWidth: 500 }}>
      {props.children}
    </div>
  )
}
