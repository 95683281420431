import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

const styles = makeStyles(_theme => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#222',
    fontSize: 18,
    lineHeight: 1.2,
    webkitFontSmoothing: 'antialiased',
  },
}))

export default function SmallTitle(props) {
  const { text, className } = props
  const classes = styles()

  if (className) {
    return <div className={clsx(classes.root, className)}>{text}</div>
  }
  return <div className={classes.root}>{text}</div>
}
