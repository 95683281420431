import React from 'react'
import { withStyles, FormControl,TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = theme => ({
    root: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    cssOverrides: {
        border: 'unset !important'
    },
})


function MultilineText(props){

    const { classes, className, id, title, placeholder, value, change, maxLength } = props

    return (
        <FormControl margin="normal" fullWidth>
            <TextField
                id={id}
                label={title}
                placeholder={placeholder}
                value={value}
                onChange={change}
                multiline
                className={clsx(classes.textField, {[className]: className})} 
                margin="normal"
                inputProps={{
                    maxLength: maxLength
                  }} />
        </FormControl>
    )
}


MultilineText.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    className: PropTypes.string
}

export default withStyles(styles)(MultilineText)