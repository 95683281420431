import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  withStyles,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import UgcTile from './UgcTile'
import UgcInfoDialog from './UgcInfoDialog'
import EmptyImage from '../../../images/undraw_empty.svg'
import DeleteConfirmationDialog from '../../util/DeleteConfirmationDialog'
import Util from '../../../Util'
import { showMessage } from '../../../store/snackBarSlice'
import adminApi from '../../../store/exhibitAdminApi'


const mapDispatchToProps = { showMessage }

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
    marginRight: 50,
  },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Loading() {
  return <CircularProgress color="secondary" />
}

class ReviewUserSubmittedContent extends Component {
  state = {
    loading: true,
    data: [],
    openDialog: false,
    contentToReview: adminApi.ugc().new(),
    idUpdating: undefined,
    openDeleteDialog: false,
    idToDelete: undefined,
  };

  componentDidUpdate(prevProps) {
    if(!prevProps.open && this.props.open) {
      // opening
      this.fetchContent(this.props.app._id)
    } else if(prevProps.open && !this.props.open) {
      //closing
      this.setLoadingStatus(true)
    }
  }

  fetchContent = async (id) => {
    const data = await adminApi.ugc().getAllByExhibitId(id)
    this.setState({ data })
    this.setLoadingStatus(false)
  }

  ugcFind = id => this.state.data.find(d => d.id === id)

  setLoadingStatus = (status) => {
    this.setState({ loading: status })
  }

  handleTileClick = (ugcId) => {
    const ugc = this.ugcFind(ugcId)
    this.setState({ contentToReview: ugc, openDialog: true })
  }

  handleCloseDialog = () => {
    this.setState({ contentToReview: adminApi.ugc().new(), openDialog: false })
  }

  setUpdatingId = (id) => {
    this.setState({ idUpdating: id })
  }

  handleSaveChanges = async () => {
    this.handleCloseDialog()
    this.setUpdatingId(undefined)
    this.fetchContent(this.props.app._id)
  }

  handleClose = () => {
    this.setState({
      data: [],
      loading: true,
      openDialog: false,
      contentToReview: {},
    })

    this.props.close()
  }

  handleDeleteUgc = async () => {
    this.handleCloseDialog()
    this.handleCloseDeleteDialog()

    const id = this.state.idToDelete
    this.setUpdatingId(id)
    const isSuccessful = await adminApi.ugc().deleteById(id)

    let message = 'Failed to delete item'
    if(isSuccessful) {

      const index = this.state.data.findIndex(d => d._id === this.state.idToDelete)
      const copy = [...this.state.data]
      copy.splice(index, 1)
      this.setState({ data: copy })

      message = 'Delete successful'
    }

    this.props.showMessage(message)

    this.setUpdatingId(undefined)
    this.setState({ idToDelete: undefined })
  }

  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false })
  }

  handleDeleteRequested = (ugcId) => {
    this.setState({ idToDelete: ugcId })
    this.handleOpenDeleteDialog()
  }

  handleOpenDeleteDialog = () => {
    this.setState({ openDeleteDialog: true })
  }

  handleViewSubmissionPage = () => {
    Util.openInNewTab(`/present/${this.props.app._id}/ugc`)
  }

  render() {
    const { classes, open } = this.props

    const loadingStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }

    const approvedUgc = this.state.data.filter(d => d.approved)
    const waitingForApprovalUgc = this.state.data.filter(d => !d.approved)

    const approvedDisplay = approvedUgc.length > 0 ? 'block' : 'none'
    const waitingForApprovalDisplay = waitingForApprovalUgc.length > 0 ? 'block' : 'none'

    const approvedTiles = approvedUgc.map(data => (
      <UgcTile
        key={data.id}
        data={data}
        click={this.handleTileClick}
        updating={this.state.idUpdating === data.id}
      />))

    const waitingForApprovalTiles = waitingForApprovalUgc.map(data => (
      <UgcTile
        key={data.id}
        data={data}
        click={this.handleTileClick}
        updating={this.state.idUpdating === data.id}
      />))

    return (
      <>
        <Dialog
          fullScreen
          open={open}
          TransitionComponent={Transition}
          onClose={this.handleClose}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close" >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Review User Submitted Content
              </Typography>
              <Button
                style={{ float: 'right', color: 'white' }}
                onClick={this.handleViewSubmissionPage}
              >
                View Submission Page
              </Button>
            </Toolbar>
          </AppBar>

          {this.state.loading && (
            <div style={loadingStyle} >
              <Loading />
            </div>
          )}

          {!this.state.loading && this.state.data.length === 0 && (
            <div style={loadingStyle} >
              <h3 style={{ textAlign: 'center', marginBottom: 50 }}>
                No Content Submitted
              </h3>

              <div
                style={{
                  backgroundImage: `url(${EmptyImage})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '50%',
                  width: '100%',
                }}
              />
            </div>
          )}

          {this.state.data.length > 0 && (
            <>
              <div style={{ display: approvedDisplay, padding: '16px' }} >
                <h4>Approved</h4>
                <Divider />
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap', padding: 16 }}>
                {approvedTiles}
              </div>

              <div style={{ display: waitingForApprovalDisplay, padding: '16px' }} >
                <h4>Waiting for Approval</h4>
                <Divider />
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap', padding: 16 }}>
                {waitingForApprovalTiles}
              </div>
            </>
          )}
        </Dialog>

        <UgcInfoDialog
          open={this.state.openDialog}
          close={this.handleCloseDialog}
          data={this.state.contentToReview}
          save={this.handleSaveChanges}
          deleteUgc={this.handleDeleteRequested}
          orgId={this.props.app?.orgId}
          stories={this.props.stories}
        />

        <DeleteConfirmationDialog
          open={this.state.openDeleteDialog}
          close={this.handleCloseDeleteDialog}
          name={'this content'}
          deleteConfirmed={this.handleDeleteUgc}
        />
      </>
    )
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ReviewUserSubmittedContent))
