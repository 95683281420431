// TODO : app access options
// TODO: if user is removed from all apps, ask admin if he/she wants to completely remove user access.
// easier case to handle in server
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  createStyles,
} from '@material-ui/core'
import AppAccessList from './AppAccessList'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'
import UserContext from '../../context/UserContext'

const styles = (theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      paddingRight: '0px !important',
    },
    flex: {
      flex: 1,
    },
    margin: {
      marginBottom: theme.spacing(1),
    },
    adminMessageGreen: {
      color: '#0C0',
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.2,
    },
    adminMessageSmallText: {
      color: '#666',
      fontFamily: 'Roboto',
      fontSize: 14,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    button: {
      float: 'left',
      width: 'fit-content',
      backgroundColor: 'unset',
    },
  })


class UserDetailsDialog extends React.Component {

  isAllowedToDeleteUser = () => {
    return this.context.isSuperAdmin ||
      (this.context._id !== this.props.user._id && !this.props.user.isAdmin)
  }

  render() {
    const {
      classes,
      open,
      close,
      user,
      org,
      apps,
      changeOrgAccess,
      changeAppAccess,
      save,
      deleteUser,
      sendPasswordResetEmail,
    } = this.props

    return (
      <Dialog open={open} onClose={close}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Edit {user.name ? user.name : user.email} on {org.name}
            </Typography>
            <IconButton color="inherit" onClick={close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div>&nbsp;</div>
        <Grid container spacing={3}>
          <Grid item xs={9}>
          </Grid>
          <Grid item xs={3}>
          <Button  variant="contained" color="primary" onClick={save}>
            Save
          </Button>
        </Grid>
      </Grid>

        <form style={{ margin: 16 }}>
          {user.name && (
            <TextField
              fullWidth
              className={classes.margin}
              value={user.name}
              id="name"
              label="Name"
            />
          )}
          <Input
            fullWidth
            disabled
            className={classes.margin}
            value={user.email}
            id="email"
          />
          <FormControl fullWidth className={classes.margin}>
            <InputLabel htmlFor="org-access">Organization Access</InputLabel>
            {/* TODO: isAdmin is not a user property but an org access property */}
            <Select
              value={user.isAdmin ? 'admin' : 'read-write'}
              onChange={changeOrgAccess}
              inputProps={{
                name: 'org-access',
                id: 'org-access',
              }}
            >
              <MenuItem value={'read-write'}>Read and Write Access</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: user.isAdmin ? 'block' : 'none',
              margin: '10px 0',
            }}
          >
            <div className={classes.adminMessageGreen}>
              Has Admin access to edit all apps
            </div>
            <div className={classes.adminMessageSmallText}>
              This user has ability to add users, create new apps and view all
              apps.
            </div>
          </div>
          {/* only show list if user is not admin - admin users have access to all apps */}
          {!user.isAdmin && (
            <AppAccessList
              apps={apps}
              user={user}
              changeAppAccess={changeAppAccess}
            />
          )}
        </form>

        <div style={{ display: 'flex' }}>
          {this.isAllowedToDeleteUser() && (
            <CategoryButton
              className={clsx(classes.button, 'bold')}
              click={sendPasswordResetEmail}
            >
              Send Password Reset Email
            </CategoryButton>
          )}
          {this.isAllowedToDeleteUser() && (
            <CategoryButton
              className={clsx(classes.button, 'bold')}
              click={deleteUser}
            >
              Delete User
            </CategoryButton>
          )}
        </div>
      </Dialog>
    )
  }
}

UserDetailsDialog.contextType = UserContext

export default withStyles(styles)(UserDetailsDialog)
