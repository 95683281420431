import React from 'react'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: theme.palette.secondary.main,
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    marginLeft: 10,
    borderRadius: 30,
    float: 'left',
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: 700,
    fontSize: 14,
    background: '#333',
    border: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
}))

export default function Control(props) {
  const { show, type, text, click } = props

  const classes = styles()

  return (
    <div
      className={classes.root}
      style={{ display: show ? 'block' : 'none' }}
      onClick={() => click(type)}
    >
      {text}
    </div>
  )
}
