import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    display: 'flex', height: 200
  },
  container: {
    width: '50%'
  },
  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    height: '100%'
  },
  buttonRoot: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    padding: 16,
  },
  buttonContainer: {
    display: 'flex', flexDirection: 'column'
  },
  button: {
    margin: 0, textAlign: 'center'
  }
})

export default function UgcAsset(props) {

  const { image, changeAsset } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.image} style={{ backgroundImage: `url(${image})` }} />
      </div>
      <div className={classes.buttonRoot}>
        <div className={classes.buttonContainer}>
          <div
            className={clsx(classes.button, 'category_button m10 bold')}
            onClick={changeAsset}>
            Change Asset
          </div>
        </div>
      </div>
    </div>
  )
}