import React, { useEffect, useState, useRef } from 'react'
import {
  Dialog,
  FormControl,
  InputLabel,
  Input,
  TextField,
  withStyles,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import InfoDialog from '../../util/InfoDialog'
import NoMediaImage from '../../../images/no_media.png'
import { getFileType, getImage } from '../../../Util'
import { showMessage } from '../../../store/snackBarSlice'
import { StoryValidator, formatMessages } from '@Pass-It-Down/exhibition-admin-client'
import { connect } from 'react-redux'
import adminApi from '../../../store/exhibitAdminApi'
import clsx from 'clsx'
import { logInfo } from '../../../Sentry'
import UgcAsset from './UgcAsset'
import ugcUpdateValidator from '../../../validations/ugcUpdateValidator'
import { Alert } from '@material-ui/lab'



const updateStoryAssets = async (storyId, assets) => {
  const story = await adminApi.story().getById(storyId)
  story.assets = story.assets.concat(assets)
  story.image = ''
  await adminApi.story().save(story)
}

const isNotStory = title => title === 'Select Story' || title === 'Create Story' || title === ''

const isAddingToStory = title => title === 'Create Story' || title !== 'Select Story'

const isAddingToExistingStory = title => title !== 'Create Story' && title !== 'Select Story'

const mapDispatchToProps = { showMessage }

const styles = {
  menu: {
    width: 200,
  },
  button: {
    marginTop: 15, marginBottom: 15
  },
  buttonsContainer: {
    display: 'flex', justifyContent: 'space-evenly'
  }
}


function UgcInfoDialog(props) {
  const { classes, orgId, open, close, data, stories, save, deleteUgc } = props

  const [ugc, setUgc] = useState({})

  const [image, setImage] = useState(NoMediaImage)
  const [selectedStory, setSelectedStory] = useState('')
  const [openInfoDialog, setOpenInfoDialog] = useState({ open: false, message: '' })
  const [showStoryTitleInput, setShowStoryTitleInput] = useState(false)
  const [storyTitle, setStoryTitle] = useState('')
  const [savingAsset, setSavingAsset] = useState(false)

  const dropdownList = [{ title: 'Select Story' }, { title: 'Create Story' }, ...stories]

  const pickAssetRef = useRef(null)

  useEffect(() => {
    if (!open) return

    setUgc(data)

    if (data.assetId) {
      const image = getImage(data.assetId)
      setImage(image)
    }

    setSelectedStory(dropdownList[0].title)

  }, [open, data])

  useEffect(() => {
    setShowStoryTitleInput(selectedStory === 'Create Story')
  }, [selectedStory])


  const handleChange = (e) => {
    const id = e.target.id
    const value = e.target.value
    switch(id) {
      case 'name':
        setUgc(state => ({ ...state, name: value }))
        break
      case 'email':
        setUgc(state => ({ ...state, email: value }))
        break
      case 'caption':
        setUgc(state => ({ ...state, caption: value }))
        break
      case 'desc':
        setUgc(state => ({ ...state, desc: value }))
        break
      case 'location-name':
        setUgc(state => ({ ...state, locationName: value }))
        break
      case 'year':
        setUgc(state => ({ ...state, year: value }))
        break
      case 'month':
        setUgc(state => ({ ...state, month: value }))
        break
      case 'day':
        setUgc(state => ({ ...state, day: value }))
        break
      case 'lat':
        setUgc(state => ({ ...state, lat: value }))
        break
      case 'lng':
        setUgc(state => ({ ...state, lng: value }))
        break
      case 'story-title':
        setStoryTitle(value)
        break
      default:
        console.error('error')
    }
  }

  const handleChangeApproval = (e) => {
    const checked = e.target.checked
    setUgc(state => ({ ...state, approved: checked }))
  }

  const handleChangeAsset = () => {
    pickAssetRef.current.click()
  }

  const handleReceiveFile = async e => {
    const file = e.target.files[0]
    const type = getFileType(file.type)
    if (type !== 'IMAGE') {
      setOpenInfoDialog({ open: true, message: 'Only images supported currently' })
      return
    }

    const url = window.URL.createObjectURL(file)
    setImage(url)
    setSavingAsset(true)

    const newAsset = adminApi.asset().newAsset({ orgId, file, _window: window })

    const { asset } = await adminApi.asset().save(newAsset, file)
    const copy = JSON.parse(JSON.stringify(ugc))
    copy.assetId = asset
    setUgc(copy)
    const image = getImage(asset)
    setImage(image)
    setSavingAsset(false)
  }

  const addAssetsToStory = async () => {
    const newAsset = adminApi.asset().newAsset({ orgId, type: 'IMAGE', link: ugc.assetId.link })
    newAsset.caption = ugc.caption

    const { asset } = await adminApi.asset().save(newAsset)

    const assets = [asset]

    if (ugc.desc) {
      const newTextAsset = adminApi.asset().newAsset({ orgId, type: 'TEXT', text: ugc.desc })
      const { asset } = await adminApi.asset().save(newTextAsset)
      assets.push(asset)
    }


    let storyId
    if (isNotStory(selectedStory)) {
      storyId = await createStory()
    } else {
      storyId = stories.find(s => s.title === selectedStory).id
    }

    await updateStoryAssets(storyId, assets)
  }

  const getDateValue = key => {
    if (!ugc[key]) return undefined
    return ugc[key]
  }

  const createStory = async () => {
    let storyId

    let story = adminApi.story().new({
      app: ugc.appId,
      title: storyTitle.trim(),
      image: ugc.assetId?.link,
      startYear: getDateValue('year'),
      startMonth: getDateValue('month'),
      startDay: getDateValue('day'),
      locationName: ugc.locationName,
      location: {
        lat: parseFloat(ugc.lat),
        lng: parseFloat(ugc.lng),
      }
    })

    story.rank = adminApi.rank().generateAppend({ stories, story })

    const sv = StoryValidator(story)

    if(sv.isValid) {
      story = await adminApi.story().save(story)
      storyId = story.id
    } else {
      props.showMessage(formatMessages(sv))
    }

    return storyId
  }

  const handleStorySelected = (e) => {
    const value = e.target.value
    setShowStoryTitleInput(value)

    if (isNotStory(value)) {
      setSelectedStory(value)
    } else {
      const story = stories.find(s => s.title === e.target.value)
      setSelectedStory(story.title)
    }
  }

  const hasAsset = () => typeof ugc.assetId === 'object'

  const canSaveUGC = () => {
    if (selectedStory === 'Create Story' && !storyTitle.trim()) return { ok: false, message: 'Please enter new story title' }

    if (savingAsset) return { ok: false, message: 'Please wait for asset to save' }

    if (isAddingToStory(selectedStory) && !ugc.approved) return { ok: false, message: 'Please approve content first.' }

    if (!hasAsset() && ugc.caption) return { ok: false, message: 'Caption needs an asset.' }

    if (!hasAsset() && isAddingToStory(selectedStory)) return { ok: false, message: 'Please add an asset first.' }

    const { isValid, message } = ugcUpdateValidator(ugc)

    return { ok: isValid, message }
  }


  const handleSave = async () => {
    const { ok: canSave, message } = canSaveUGC()
    if (!canSave) {
      setOpenInfoDialog({ open: true, message })
      return
    }

    props.showMessage('Saving...')

    if (isAddingToStory(selectedStory)) await addAssetsToStory()

    const { error } = await adminApi.ugc().save(ugc)

    if (error) logInfo(error, 'error')

    const saveMessage = error ? 'Error saving ugc' : 'Saved'

    props.showMessage(saveMessage)

    save()

    resetState()
  }

  const resetState = () => {
    setImage(NoMediaImage)
    setSelectedStory('')
    setStoryTitle('')
  }

  const handleDelete = () => {
    deleteUgc(ugc.id)
  }

  const handleClose = () => {
    resetState()
    close()
  }

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={'sm'} onClose={handleClose}>
        <div style={{ padding: 20 }}>
          <div className={clsx('subtitle', 'maincolor')}>Review Content</div>
        </div>
        <form style={{ margin: 16 }}>
          <FormControlLabel
            control={
              <Switch
                checked={ugc.approved}
                onChange={handleChangeApproval}
                color='primary' />
            }
            label={ugc.approved ? 'Approved' : 'Not Approved'} />
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='name'>Submitted by:</InputLabel>
            <Input id='name' type='text' onChange={handleChange} value={ugc.name} />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='email'>Email:</InputLabel>
            <Input
              id='email'
              type='text'
              onChange={handleChange}
              value={ugc.email}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='caption'>Caption:</InputLabel>
            <Input
              id='caption'
              type='text'
              onChange={handleChange}
              value={ugc.caption}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='desc'>Description:</InputLabel>
            <Input id='desc' type='text' onChange={handleChange} value={ugc.desc} />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='year'>Year:</InputLabel>
            <Input
              id='year'
              type='number'
              onChange={handleChange}
              value={ugc.year}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='month'>Month:</InputLabel>
            <Input
              id='month'
              type='number'
              onChange={handleChange}
              value={ugc.month}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='day'>Day:</InputLabel>
            <Input id='day' type='number' onChange={handleChange} value={ugc.day} />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='location-name'>Location:</InputLabel>
            <Input
              id='location-name'
              type='text'
              onChange={handleChange}
              value={ugc.locationName}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='lat'>Latitude:</InputLabel>
            <Input
              id='lat'
              type='text'
              onChange={handleChange}
              value={ugc.lat}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='lng'>Longitude:</InputLabel>
            <Input
              id='lng'
              type='text'
              onChange={handleChange}
              value={ugc.lng}
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <TextField
              id='add-to-story'
              select
              label='Add to story'
              onChange={handleStorySelected}
              value={selectedStory}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin='normal'
            >
              {dropdownList.map((story, index) => (
                <MenuItem key={index} value={story.title}>
                  {story.title}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {showStoryTitleInput &&
            <FormControl margin='normal' fullWidth>
              <InputLabel htmlFor='story-title'>Story Title:</InputLabel>
              <Input
                id='story-title'
                type='text'
                onChange={handleChange}
                value={storyTitle}
              />
            </FormControl>}

          {isAddingToExistingStory(selectedStory) && <Alert severity='info'>Only assets will be added to the story</Alert>}

          <UgcAsset image={image} changeAsset={handleChangeAsset} />

        </form>

        <div className={classes.buttonsContainer}>
          <div
            className={clsx(classes.button, 'category_button whitebg m10 bold')}
            onClick={handleDelete}>
            Delete UGC
          </div>
          <div
            className={clsx(classes.button, 'category_button secondbg m10 white bold')}
            onClick={handleSave}>
            Save Changes
          </div>
        </div>

        <input
          ref={pickAssetRef}
          id='pickPhoto'
          style={{ display: 'none' }}
          type='file'
          accept='image/jpg, image/jpeg, image/png, image/tif, image/tiff, image/webp'
          onChange={handleReceiveFile} />
      </Dialog>

      <InfoDialog
        open={openInfoDialog.open}
        close={() => setOpenInfoDialog(false)}
        info={openInfoDialog.message}
      />
    </>
  )
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(UgcInfoDialog))
