import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import withStyles from '@material-ui/core/styles/withStyles'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { Redirect } from 'react-router-dom'
import Util from './Util'
import clsx from 'clsx'
import SubTitle from './components/UI/SubTitle'
import SmallTitle from './components/UI/SmallTitle'

import './LoginAndForgotPasswordPage.css'

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'flex', // Fix IE 11 issue.
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  close: {
    padding: theme.spacing(1 / 2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  cssOverrides: {
    border: 'unset !important',
  },
  email: {
    margin: '10px 0 2px 15px',
  },
  password: {
    margin: '20px 0 2px 15px',
  },
})

class LoginPage extends Component {
  state = {
    emailAddress: '',
    password: '',
    confirmPassword: '',
    openSnackBar: false,
    snackBarMessage: '',
    loggingIn: false,
    redirectToPreviousRoute: false,
    openForgotPasswordDialog: false,
    forgotPasswordEmail: '',
  };

  handleChange = (e) => {
    const target = e.target
    const id = target.id
    const value = target.value

    switch (id) {
      case 'loginemail':
        this.setState({
          emailAddress: value,
        })
        break
      case 'loginpass':
        this.setState({
          password: value,
        })
        break
      default:
        console.error('unsupported case')
        break
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    })
  };

  handleClick = (e) => {
    e.preventDefault()
    if (this.state.emailAddress === '') {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Please enter email address',
      })
    } else if (this.state.password === '') {
      this.setState({
        openSnackBar: true,
        snackBarMessage: 'Please enter password',
      })
    } else {
      this.setState({ loggingIn: true })
      this.loginUser()
    }
  };

  loginUser = async () => {
    try {
      const email = this.state.emailAddress
      const password = this.state.password

      if (location.hostname === 'localhost') {
        firebase.auth().useEmulator(process.env.REACT_APP_FIREBASE_EMULATOR_URL)
      }

      await firebase.auth().signInWithEmailAndPassword(email, password)
      this.setState({ loggingIn: false, redirectToPreviousRoute: true })
    } catch(err) {
      this.setState({ loggingIn: false })
      console.error(err)
      this.setState({ openSnackBar: true, snackBarMessage: err.message })
    }
  }

  handleSignupClick = (e) => {
    e.preventDefault()
    this.props.history.push('/signup')
  };

  handleForgotPassword = () => {
    this.setState({
      openForgotPasswordDialog: true,
    })
  };

  handleForgotPasswordEmailChange = (e) => {
    const target = e.target
    const value = target.value
    this.setState({
      forgotPasswordEmail: value,
    })
  };

  handleSendPasswordReset = async () => {
    try {
      await Util.getSecondaryApp()
        .auth()
        .sendPasswordResetEmail(this.state.forgotPasswordEmail)
      this.setState({
        openForgotPasswordDialog: false,
        forgotPasswordEmail: '',
      })
    } catch (error) {
      console.error(error)
    }
  };

  handleCloseForgotPasswordDialog = () => {
    this.setState({
      openForgotPasswordDialog: false,
    })
  };

  forgotPasswordClicked = () => {
    this.props.history.push('/forgot-password')
  };

  handleEnter = (e) => {
    if (e.keyCode !== 13) {
      // Enter key
      return
    }
    this.loginUser()
  };

  render() {
    const { classes, user} = this.props
    const { from } = this.props.location.state || {
      from: { pathname: '/dashboard' },
    }

    if (this.state.redirectToPreviousRoute || (user && user.email)) {
      return <Redirect to={from} />
    }

    return (
      <div style={{ height: '100%' }}>
        <div className="adminbar">
          <div className="logo" />
        </div>
        <SubTitle className={'blue tc'}>Login to your account.</SubTitle>
        <div className="loginForgotPasswordPageForm">
          <SmallTitle
            classes={clsx('blue', 'tl', classes.email)}
            text={'Email:'}
          />

          <input
            id="loginemail"
            type="email"
            className="roundform"
            style={{
              width: '100%',
              background: '#fff',
              paddingLeft: 15,
              height: 45,
              border: '1px solid #ccc',
              fontSize: 18,
            }}
            value={this.state.emailAddress}
            onChange={this.handleChange}
            placeholder="Enter your email"
            autoFocus
          />

          <SmallTitle
            classes={clsx('blue', 'tl', classes.password)}
            text={'Password:'}
          />

          <input
            id="loginpass"
            type="password"
            className="roundform"
            style={{
              width: '100%',
              background: '#fff',
              paddingLeft: 15,
              height: 45,
              border: '1px solid #ccc',
              fontSize: 18,
            }}
            value={this.state.password}
            onChange={this.handleChange}
            onKeyDown={this.handleEnter}
            placeholder="Password"
          />

          <div
            id="loginBtn"
            className="category_button bluebg white m10"
            style={{
              display: 'flex',
              width: 'fit-content',
              marginTop: 10,
            }}
            onClick={this.loginUser}
          >
            Login
          </div>

          <div
            className="m10 ex_type darkgraybg"
            onClick={this.forgotPasswordClicked}
          >
            Forgot Password?
          </div>
        </div>

        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          message={this.state.snackBarMessage}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    )
  }
}

export default withStyles(styles)(LoginPage)
