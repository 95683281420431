import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import UploadImage from '../util/UploadImage'
import {
  withStyles,
  createStyles,
} from '@material-ui/core'
import UserContext from '../../context/UserContext'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'
import Container from '../UI/Container'
import SubTitle from '../UI/SubTitle'
import FormRow from '../UI/form/FormRow'
import FormTitle from '../UI/form/FormTitle'

const styles = createStyles({
  leftAlignedButtons: {
    backgroundColor: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    margin: '10px 0 15px !important',
  },
})


class EditOrganizationDialog extends React.Component {
  static contextType = UserContext

  state = {
    hasSuperAdminAccess: false,
  }

  componentDidMount() {
    const user = this.context
    // TODO
    // console.log(`user in org`, user) // sometimes the user object might be null in case user logs in and
    // redirect happens before user details have been fetched
    if(user.isSuperAdmin) {
      this.setState({
        hasSuperAdminAccess: true,
      })
    }
  }
  // TODO: [P3MVP-334] use API
  /**
   * Opens all-apps template that shows all the apps for the org
   * hostname should be dev or create depending on where user clicks button
   */
  handleViewOrgTemplate = () => {
    this.openInNewTab(
      `https://${window.location.hostname}/present/org/${this.props.orgToEdit._id}`
    )
  }

  openInNewTab = (url) => {
    const win = window.open(url, '_blank')
    win.focus()
  }

  render() {
    const {
      classes,
      open,
      orgToEdit,
      handleClose,
      handleSave,
      receiveImage,
      deleteOrg,
      handleChange,
      handleChangePlan,
    } = this.props

    return (
      <Dialog open={open} onClose={handleClose}>
        <Container>
          <SubTitle>Edit {orgToEdit.name}</SubTitle>
          <FormRow>
            <div className='formtitle'>Organization Name:</div>
            <input
              type='text'
              id='orgname'
              value={orgToEdit.name}
              onChange={handleChange}
              placeholder='Name of your organization.'
            />
          </FormRow>
          <FormRow>
            <FormTitle text={'Plan:'} />
            <select
              disabled={this.state.hasSuperAdminAccess === false}
              value={orgToEdit.plan}
              onChange={handleChangePlan}
              id='plan'
            >
              <option value='1'>1 Application</option>
              <option value='2'>2 Applications</option>
              <option value='3'>3 Applications</option>
              <option value='4'>4 Applications</option>
              <option value='5'>5 Applications</option>
              <option value='6'>6 Applications</option>
              <option value='7'>7 Applications</option>
              <option value='8'>8 Applications</option>
              <option value='9'>9 Applications</option>
              <option value='10'>10 Applications</option>
              <option value='20'>20 Applications</option>
              <option value='25'>25 Applications</option>
              <option value='30'>30 Applications</option>
              <option value='100000'>Unlimited</option>
            </select>
          </FormRow>

          <UploadImage
            id={'edit-org-logo'}
            image={orgToEdit.logo}
            handleReceiveImage={receiveImage}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CategoryButton
              click={handleClose}
              className={clsx(classes.leftAlignedButtons, 'bold')}
            >
              Cancel
            </CategoryButton>

            <CategoryButton
              click={deleteOrg(orgToEdit._id)}
              className={clsx('bold', classes.leftAlignedButtons)}
            >
              Delete
            </CategoryButton>
            <div style={{ flexGrow: 1 }} />
            <CategoryButton
              click={handleSave}
              className={clsx(classes.saveButton, 'white', 'bold')}
            >
              Save Organization
            </CategoryButton>
          </div>
        </Container>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditOrganizationDialog)
