import React, { useState } from 'react'
import { ChromePicker } from 'react-color'

export default function ColorPicker(props){

    const { type, color, colorPickerChange } = props

    const [displayChromePicker, setDisplayChromePicker] = useState(false)


    const handleColorSwatchClick = () => {
        setDisplayChromePicker(!displayChromePicker)
    }

    const handleCloseColorPicker = () => {
        setDisplayChromePicker(false)
    }


    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            margin: 16
          }}>
            <div style={{marginRight: 16, width: 200}}>{type} Color:</div>
            <div style={{
              padding: '5px',
              background: '#fff',
              borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inline-block',
              cursor: 'pointer',
            }} onClick={ handleColorSwatchClick }>
              <div style={{
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: color
              }} />
            </div>
            { displayChromePicker ? 
            <div>
                <div style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }} onClick={ handleCloseColorPicker }/>
                <ChromePicker color={ color } onChange={ colorPickerChange } />
            </div> : 
            null }
          </div>
    )

}