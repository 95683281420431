import React from 'react'
import AudiotrackIcon from  '../../images/headphones-monochrome.png'

function AudioUpload(props){
    
    return (
        <div 
            style={{
                backgroundImage: `url(${AudiotrackIcon})`, 
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: 100, 
                height: 100, 
                marginRight: 10,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'}}>
            {props.children}
        </div>
    )
}

export default AudioUpload