import React, { useEffect, useState } from 'react'
import TiffImagePlaceholder from '../../images/tiff_placeholder.png'
import ExifOrientationImg from 'react-exif-orientation-img'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex', flexDirection: 'column'
  },
  image: {
    width: 100, height: 100, objectFit: 'cover'
  }
})

export default function GameDayImageUpload(props) {

  const { asset } = props
  const classes = useStyles()

  const [image, setImage] = useState('')

  useEffect(() => {
    if(asset.isTiffImage) {
      setImage(TiffImagePlaceholder)
    } else {
      setImage(asset.link)
    }
  }, [asset.link])


  return (
    <div data-testid='imageUpload' className={classes.root}>
      <ExifOrientationImg src={image} className={classes.image} />
    </div>
  )
}