import React from 'react'
import {
  createStyles,
  withStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'

const styles = (_theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    flex: {
      flex: 1,
      marginRight: 50,
    },
  })

class ChangeSubCollectionNameDialog extends React.Component {
  render() {
    const { classes, open, subCollection, close, change, save } = this.props

    return (
      <Dialog open={open} onClose={close}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={close} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Change Sub Collection Name
            </Typography>
            <Button color="inherit" onClick={save}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <form style={{ margin: 16 }}>
          <TextField
            fullWidth
            value={subCollection?.title || ''}
            onChange={change}
            label="Title"
            margin="normal"
          />
        </form>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ChangeSubCollectionNameDialog)
