import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import FormRow from '../UI/form/FormRow'
import OldFormTitle from '../UI/form/OldFormTitle'
import Util from '../../Util'
import { default as MetaDataClient } from '@Pass-It-Down/exhibition-admin-client/src/metadata'



const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    margin: 16,
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  }
}))

let items = MetaDataClient.new()
items = Object.keys(items).map(key => {
  return {
    name: key,
    label: Util.capitalizeFirstLetter(key)
  }
})


export default function MetaData(props) {

  const { open, handleChange, metaData, isPortland } = props

  const classes = useStyles()
  const [metadataItems, setMetaDataItems] = useState(items)

  useEffect(() => {
    if (!isPortland) return

    let items = MetaDataClient.new({ isPortland: true })
    items = Object.keys(items).map(key => {
      switch (key) {
        case 'raccNo':
          return {
            name: key,
            label: 'RACC #'
          }
        case 'conceptualInfo':
          return {
            name: key,
            label: 'Conceptual Information'
          }
        case 'displayLocation':
          return {
            name: key,
            label: 'Display Location'
          }
        case 'buildingName':
          return {
            name: key,
            label: 'Building Name'
          }
        default:
          return {
            name: key,
            label: Util.capitalizeFirstLetter(key)
          }
      }
    })

    setMetaDataItems(items)
  }, [isPortland, metaData])

  return (
    <div className={classes.container}>
      {/* Conditionally applies the timeout property to change the entry speed. */}
      <Grow
        in={open}
        style={{ transformOrigin: '0 0 0' }}
        {...(open ? { timeout: 1000 } : {})}>
        <div id='metadata' className='grayblock' style={{ marginTop: 8, display: open ? 'block' : 'none' }}>
          {metadataItems.map(item => {
            return (
              <FormRow fullWidth key={item.name}>
                <OldFormTitle text={`${item.label}:`} />
                <input type='text' id={item.name} onChange={handleChange} className='roundform' value={metaData[item.name]} placeholder='' />
              </FormRow>
            )
          })}
        </div>
      </Grow>
    </div>
  )
}
