import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import GameDayImageUpload from './GameDayImageUpload'
import { Card } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    margin: 16,
    width: 250,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    position: 'relative',
  },
  main: {
    display: 'flex', flexDirection: 'column'
  },
  deleteIcon: {
    position: 'absolute', zIndex: 1
  }
})


export default function GameDayUploadItem(props) {
  const { asset, handleDelete } = props
  const classes = useStyles()


  return (
    <Card className={classes.root}>
      <div className={classes.main}>
        <GameDayImageUpload asset={asset} />
      </div>
      <div className={classes.progress}>
        <div
          className={classes.deleteIcon}
          onClick={handleDelete(asset)}>
          <DeleteOutlineIcon />
        </div>
      </div>
    </Card>
  )
}