import React, { useEffect, useRef } from 'react'
import Asset from './Asset'
import { makeStyles } from '@material-ui/core'
import SmallTitle from '../UI/SmallTitle'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    padding: '15px 15px',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  noResults: {
    width: '100%',
    marginTop: 100,
  },
})

function AssetsList(props) {
  const { assets, assetClicked, addToStory, fetchMoreAssets } = props

  const classes = useStyles()

  const rootDivRef = useRef(null)

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling)
    return () => {
      document.removeEventListener('scroll', trackScrolling)
    }
  }, [])

  const trackScrolling = () => {
    const wrappedElement = rootDivRef.current
    if (wrappedElement && isBottom(wrappedElement)) {
      console.log('reached bottom of page')
      fetchMoreAssets()
    }
  }

  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight
  }

  return (
    <div className={classes.root} ref={rootDivRef}>
      {assets.length > 0 ? (
        assets.map((asset, index) => {
          return (
            <Asset
              key={index}
              creatingStory={true}
              asset={asset}
              assetClicked={assetClicked}
              addToStory={addToStory}
            />
          )
        })
      ) : (
        <SmallTitle
          text={'No results found'}
          className={[classes.noResults, 'tc'].join(' ')}
        />
      )}
    </div>
  )
}

AssetsList.propTypes = {
  assets: PropTypes.array.isRequired,
  assetClicked: PropTypes.func.isRequired,
  addToStory: PropTypes.func.isRequired,
  fetchMoreAssets: PropTypes.func.isRequired,
}

export default AssetsList
