
export default class User {
  _id = ''
  _orgAccess = []
  _name = ''
  _email = ''
  _tempPassword = ''
  _isSuperAdmin = false

  makeAdmin = false
  makeReadWrite = false

  constructor(userJSON) {
    this._id = userJSON['_id']
    this._orgAccess = userJSON['orgAccess']
    this._name = userJSON['name']
    this._email = userJSON['email']
    this._tempPassword = userJSON['tempPassword']
    this._isSuperAdmin = userJSON['isSuperAdmin']
  }

  get id() {
    return this._id
  }

  get orgAccess() {
    return this._orgAccess
  }

  get name() {
    return this._name
  }

  get email() {
    return this._email
  }

  get isSuperAdmin() {
    return this._isSuperAdmin
  }
}
