import { newValidator, validateLength, validateNotBlank, validateUrl } from 'simple-valley'


export function ImageUrlPageStoryValidator(story) {
  let v = newValidator(story)
  v = validateNotBlank(v, 'title', { message: 'Please provide a title' })
  v = validateNotBlank(v, 'imageUrl', { message: 'Please provide a URL' })
  v = validateUrl(v, 'imageUrl', { message: 'Please provide a valid URL' })
  v = validateLength(v, 'assets', { min: 1, max: 1, message: 'Please select an image' })

  return v
}
