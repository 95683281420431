import React, { useEffect, useRef, useState } from 'react'
import { AppBar, Button, Dialog, FormControl, IconButton, Input, InputLabel, makeStyles, MenuItem, TextField, Toolbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import Util, { sleep } from '../../Util'
import StoryUploadPhoto from '../../images/story_upload_photo.png'
import { assetValidator, formatMessages } from '@Pass-It-Down/exhibition-admin-client'
import { statuses } from '../util/story'
import { Transition } from '../story/Transition'
import GameDayUploadItem from './GameDayUploadItem'
import { ImageUrlPageStoryValidator } from './ImageUrlPageStoryValidator'
import adminApi from '../../store/exhibitAdminApi'
import DeleteStoryDialog from '../story/DeleteStoryDialog'
import UploadProgress from '../story/UploadProgress'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
    marginRight: 50,
  },
  buildStoryTop: {
    padding: '15px 0',
    boxSizing: 'unset',
    marginBottom: 20,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 700,
    color: '#222',
    webkitFontSmoothing: 'antialiased',
    [theme.breakpoints.up(1)]: {
      fontSize: 32
    },
    [theme.breakpoints.up(860)]: {
      fontSize: 36
    },
    [theme.breakpoints.up(1025)]: {
      fontSize: 42
    }
  },
  white: {
    color: '#fff'
  },
  tc: {
    textAlign: 'center'
  },
  main: {
    width: '80%', margin: 'auto'
  },
  form: {
    margin: 16
  },
  cssOverrides: {
    border: 'unset !important',
  },
  grayBlock: {
    padding: '0 10px 10px 10px',
    background: '#f1f1f1',
    border: '1px solid #ccc',
    margin: '40px 0',
    borderRadius: 10,
    position: 'relative'
  },
  fadeIn: {
    transition: 'opacity 0.5s',
    opacity: 1
  },
  uploadButton: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  hide: {
    display: 'none'
  },
  newUploadButton: {
    width: 120,
    float: 'left',
    height: 168,
    padding: '5px 20px',
    margin: '10px 2%',
    background: `url(${StoryUploadPhoto})center center/92% no-repeat`
  },
  statusSection: {
    display: 'flex',
    width: 200
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonsSection: {
    display: 'flex'
  },
  alignEnd: {
    marginLeft: 'auto'
  },
  prompt: {
    textAlign: 'center',
    color: 'grey'
  },
  deleteButton: {
    display: props => props.storyToEdit ? 'block' : 'none',
  }
}))


export function AddImageUrlPage(props){

  const { open, orgId, app, stories, newStory, storyToEdit, editedStory,
    close, showMessage, toggleProgress, storyDeleted } = props

  const classes = useStyles(props)

  const [title, setTitle] = useState('')
  const [uploadPercent, setUploadPercent] = useState(0)
  const [url, setUrl] = useState('')
  const [assets, setAssets] = useState([])
  const [status, setStatus] = useState(statuses.DRAFT)
  const [saving, setSaving] = useState(false)
  const [imageSelected, setImageSelected] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const pickPhotoRef = useRef()
  const countRef = useRef(0)

  const waitForAssetsToSave = async () => {
    let uploadPercent = 0
    setUploadPercent(uploadPercent)

    while(countRef.current != 0) {
      uploadPercent = (uploadPercent + 5) % 100
      await setUploadPercent(uploadPercent)
      await sleep(500)
    }
  }

  useEffect(() => {
    assets.length === 0 ? setImageSelected(false) : setImageSelected(true)
  }, [assets])


  useEffect(() => {

    if(!storyToEdit) return

    setTitle(storyToEdit.title)
    setUrl(storyToEdit.imageUrl)
    setStatus(storyToEdit.status)

  }, [storyToEdit])

  const handleOnEntered = () => {
    if(storyToEdit) fetchStoryAssets()
  }

  const isNewStory = () => {
    return storyToEdit === undefined
  }

  const fetchStoryAssets = async () => {
    const story = await adminApi.story().getById(storyToEdit.id)
    if(!story) {
      console.error('failed to fetch story')
      return
    }

    const { assets } = story

    setAssets(assets)
  }

  const handleClose = () => {
    close()
    reset()
  }

  const reset = () => {
    setTitle('')
    setUrl('')
    setAssets([])
    setStatus(statuses.DRAFT)
    setImageSelected(false)
    setUploadPercent(0)
  }

  const handleChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    switch(id){
      case 'title':
        setTitle(value)
        break
      case 'url':
        setUrl(value)
        break
    }
  }

  const handlePickPhoto = () => {
    pickPhotoRef.current.click()
  }


  const handleReceiveFile = async (e) => {
    setImageSelected(true)

    const type = 'IMAGE'
    const file = e.target.files[0]

    const newAsset = adminApi.asset().newAsset({ orgId, file, type, _window: window })
    const validator = assetValidator(newAsset)
    if(!validator.isValid) {
      showMessage(formatMessages(validator))
      return
    }

    countRef.current++

    const { error, asset } = await adminApi.asset().save(newAsset, file)
    if(error) {
      showMessage(error)
    }

    setAssets([...assets, asset])

    countRef.current--
    console.log('finished uploading...', countRef.current)
  }

  const handleDeleteFile = obj => () => {
    const assetsCopy = [...assets]
    const assetToDeleteIndex = assetsCopy.indexOf(obj)
    assetsCopy.splice(assetToDeleteIndex, 1)
    setAssets(assetsCopy)

  }

const handleStatusChange = (e) => {
  const value = e.target.value
  setStatus(value)
}

const validateSaveStory = (story) => {
  let valid = true
  let message = ''

  const validator = ImageUrlPageStoryValidator(story)

  if(!validator.isValid){
    valid = false
    message = validator.messages[0].message
  }

  return { valid, message }
}

const saveNewStory = async () => {

  let story = {
    app: app._id,
    title: title.trim(),
    status,
    assets,
    storyType: 'imageUrl',
    imageUrl: url.trim(),
    tags: []
  }

  story.rank = adminApi.rank().generateAppend({ stories, story })

  const { valid, message } = validateSaveStory(story)
  if (!valid) {
    showMessage(message)
    return
  }

  await waitForAssetsToSave()

  setSaving(true)

  story = await adminApi.story().save(story)
  if(!story) {
    setSaving(false)
    showMessage('Error saving story. Try again later')
    return
  }

  newStory(story)
  handleClose()
  showMessage('Story saved')
  setSaving(false)
  reset()
}

const saveEditedStory = async () => {

  let story = {
    app: app._id,
    title: title.trim(),
    status,
    assets,
    storyType: 'imageUrl',
    imageUrl: url.trim(),
    tags: []
  }

  story.id = storyToEdit.id
  story.rank = storyToEdit.rank

  const { valid, message } = validateSaveStory(story)
  if (!valid) {
    showMessage(message)
    return
  }

  await waitForAssetsToSave()

  setSaving(true)

  story = await adminApi.story().save(story)
  if(!story) {
    setSaving(false)
    showMessage('Error saving story. Try again later')
    return
  }

  editedStory(story)

  handleClose()
  showMessage('Story saved')
  setSaving(false)
  reset()
}

const handleSave = () => {
  if(isNewStory()){
    saveNewStory()
  }else{
    saveEditedStory()
  }
}

const handleDeleteButtonClicked = () => {
  setOpenDeleteDialog(true)
}

const handleCloseDeleteDialog = () => {
  setOpenDeleteDialog(false)
}

const handleDeleteStory = async () => {

  const storyId = storyToEdit.id
  handleCloseDeleteDialog()
  toggleProgress()
  handleClose()

  const { error } = await adminApi.story().delete(storyId)
  if(error){
    console.error(error)
    return
  }

  storyDeleted(storyId)
}

  return (
    <Dialog
      open={open}
      TransitionProps={{
        onEntered: () => handleOnEntered()
      }}
      fullScreen
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color='inherit'
              onClick={handleClose}
              aria-label='Close'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' className={classes.flex}>
              {'GameDay'}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          className={clsx(classes.buildStoryTop, classes.title, classes.white, classes.tc)}>
          Add Image URL
        </div>
        <div className={classes.main}>
          <form className={classes.form}>
            <FormControl margin='normal' fullWidth>
                <InputLabel htmlFor='title'>
                  Enter a title
                </InputLabel>
                <Input
                  id='title'
                  type='text'
                  classes={{ input: classes.cssOverrides }}
                  inputProps={{ maxLength: 150 }}
                  multiline
                  onChange={handleChange}
                  value={title}
                />
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <InputLabel htmlFor='url'>
                  Enter URL
                </InputLabel>
                <Input
                  id='url'
                  type='text'
                  classes={{
                    input: classes.cssOverrides,
                  }}
                  multiline
                  onChange={handleChange}
                  value={url}
                />
              </FormControl>
          </form>
        <div style={{ display: assets.length === 0 ? 'none' : 'flex', justifyContent: 'center' }}>
          {assets.length > 0 && (
            <GameDayUploadItem
              asset={assets[0]}
              handleDelete={handleDeleteFile} />
              )}
          </div>
          <div className={clsx(imageSelected && classes.hide, classes.grayBlock)}>
            <div>
              <div className={classes.prompt}>Select image</div>
              <div className={clsx(classes.fadeIn, classes.uploadButton)}>
                <input
                  className={classes.hide}
                  ref={pickPhotoRef}
                  type='file'
                  accept={Util.allowable_image_types()}
                  onChange={handleReceiveFile}
                  onClick={e => e.target.value = null} />

                <div
                  className={clsx(classes.newUploadButton, classes.floatCssOverride)}
                  onClick={handlePickPhoto} />
              </div>
            </div>
          </div>
          <FormControl
              margin='normal'
              className={classes.statusSection}>
              <TextField
                fullWidth={false}
                id='select-status'
                select
                label='Status'
                value={status}
                onChange={handleStatusChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
              >
                {Object.values(statuses).map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormControl>
            <div className={classes.buttonsSection}>
              <Button
                className={clsx(classes.deleteButton, classes.button)}
                variant='outlined'
                color='secondary'
                onClick={handleDeleteButtonClicked}
                disabled={saving}>
                Delete
              </Button>

              <div className={classes.alignEnd}>
                <Button
                  className={classes.button}
                  variant='outlined'
                  color='secondary'
                  onClick={handleClose}
                  disabled={saving}>
                  Cancel
                </Button>

                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={handleSave}>
                  Save Story
                </Button>
              </div>
            </div>
        </div>

        <DeleteStoryDialog
          open={openDeleteDialog}
          close={handleCloseDeleteDialog}
          handleDelete={handleDeleteStory} />

        {uploadPercent > 0 && (
          <UploadProgress percentCompleted={uploadPercent} />
        )}

    </Dialog>
  )
}
