import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import NoMediaImage from '../../../images/no_media.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import { getImage } from '../../../Util'


function UgcTile(props) {
  const { data, click, updating } = props

  const theme = useTheme()

  const [image, setImage] = useState(NoMediaImage)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if(data.assetId) {
      const asset = data.assetId
      const image = getImage(asset)
      setImage(image)
    }
  }, [data])

  useEffect(() => {
    if(updating) {
      setShowLoader(true)
    } else {
      setShowLoader(false)
    }
  }, [updating])

  let statusClasses

  if(data.approved) {
    statusClasses = ['story_active', 'greenbg', 'white', 'normal']
  } else {
    statusClasses = ['story_active', 'redbg', 'white', 'normal']
  }

  return (
    <div className='story_tile' style={{ position: 'relative' }}>
      <div
        style={{
          filter: updating ? 'opacity(0.5)' : 'unset',
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          background: `url(${image})center center/cover no-repeat`,
        }}
        onClick={() => click(data.id)}>
        <div className={statusClasses.join(' ')}>
          {data.approved ? 'Approved' : 'Not Approved'}
        </div>
        <div
          className={clsx('story_tile_title', 'subtitle', 'white', 'storybottom')}
          style={{ borderBottom: `3px solid ${theme.palette.primary.main}` }}>
          {data.desc}
        </div>
        <div className={clsx('demo_bottom', 'gradient_bottom')} />
      </div>
      {showLoader && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default UgcTile
