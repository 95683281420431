import React, { useEffect, useState } from 'react'
import {
  withStyles,
  createStyles,
} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import SubTitle from '../UI/SubTitle'
import FormRow from '../UI/form/FormRow'
import Input from '../UI/Input'
import CategoryButton from '../UI/CategoryButton'
import DeleteConfirmationDialog from '../util/DeleteConfirmationDialog'
import ReactPlayer from 'react-player'
import AudiotrackIcon from '../../images/headphones-monochrome.png'
import PDF from './PDF'
import adminApi from '../../store/exhibitAdminApi'

const defaultAssetState = {
  _id: '',
  local: false,
  type: '',
  tags: [],
  name: '',
  originalImage: '',
  caption: '',
  link: '',
  orgId: '',
  srcSet: [],
  status: '',
  isTiffImage: false,
  added: false,
  thumbnail: '',
}

function assetDownloadable(asset) {
  return !asset.local && asset.type !== 'TEXT' && asset.type !== 'YOUTUBE'
}

const styles = (theme) =>
  createStyles({
    flex: {
      flex: 1,
      marginRight: 50,
    },
    chip: {
      margin: theme.spacing(1),
    },
    asset: {
      height: 0,
      paddingBottom: '56%',
      position: 'relative',
      paddingTop: 0,
    },
    download: {
      margin: '30px 25%',
      width: '50%',
    },
    save: {
      margin: '30px 25%',
      width: '50%',
    },
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    audio: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundImage: `url(${AudiotrackIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    youtube: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  })


function EditAssetDialog(props) {
  const [asset, setAsset] = useState(defaultAssetState)
  const [tags, setTags] = useState('')
  const [
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
  ] = useState(false)

  const { classes, open, close } = props

  useEffect(() => {
    if (props.asset) {
      setAsset(props.asset)
      let tags = ''

      if (props.asset.tags) {
        tags = props.asset.tags.join(',')
        setTags(tags)
      }
    }
  }, [props.asset])

  const handleSave = async () => {
    props.close()
    props.showProgress()
    const  resp = await adminApi.asset().save(asset, tags)
    if(resp.success) {
      props.assetUpdated(resp.asset)
    }
    props.hideProgress()
    props.showMessage(resp.message)
  }

  const handleEditAsset = (e) => {
    const id = e.target.id
    const value = e.target.value
    const assetCopy = { ...asset }

    switch (id) {
      case 'title':
        assetCopy.name = value
        setAsset(assetCopy)
        break
      case 'caption':
        assetCopy.caption = value
        setAsset(assetCopy)
        break
      case 'tags': // not updating assets since the string will be split using commas
        setTags(value)
        break
      default:
        console.error('should not be here')
    }
  }

  const handleDeleteOpenDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(true)
  }

  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false)
  }

  const handleDelete = async () => {
    handleCloseDeleteConfirmationDialog()

    props.close()
    props.showProgress()

    const { success, message } = await adminApi.asset().delete(asset)
    if(success) {
      props.assetDeleted(asset._id)
      resetState()
    }
    props.showMessage(message)
    props.hideProgress()
  }

  const resetState = () => {
    setAsset({ ...defaultAssetState })
    setTags('')
    setOpenDeleteConfirmationDialog(false)
  }

  const handleDownloadAsset = () => {
    window.open(asset.originalImage)
  }

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} onClose={close}>
      <div style={{ padding: 20 }}>
        <SubTitle>Edit Asset Details:</SubTitle>
        <FormRow>
          <Input
            id={'title'}
            text={'Asset Title:'}
            placeholder={'Title of your asset'}
            value={asset.name || ''}
            change={handleEditAsset}
          />
        </FormRow>

        <FormRow>
            <Input
              id={'caption'}
              text={'Asset Caption:'}
              placeholder={'Add a caption to your asset for use in search'}
              value={asset.caption || ''}
              change={handleEditAsset}
            />
        </FormRow>

        <FormRow>
          <Input
            id={'tags'}
            text={'Asset Tags:'}
            placeholder={'Add comma separated tags to your asset for use in search'}
            value={tags || ''}
            change={handleEditAsset}
          />
        </FormRow>

        <CategoryButton
          click={handleSave}
          className={['white', 'bold', 'tc', classes.save].join(' ')}
        >
          Finish & Save
        </CategoryButton>

        <div className={classes.asset}>
          {asset.type === 'YOUTUBE' && (
            <ReactPlayer
              className={classes.youtube}
              controls
              youtube={asset.type === 'YOUTUBE'}
              url={asset.link}
              width="100%"
              height="100%"
            />
          )}
          {asset.type === 'VIDEO' && (
            <ReactPlayer
              className={classes.video}
              controls
              url={asset.link}
              width="100%"
              height="100%"
            />
          )}

          {asset.type === 'IMAGE' && (
            <div
              style={{
                backgroundImage: `url(${asset.link})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: 500,
                width: '100%',
              }}
            />
          )}

          {asset.type === 'AUDIO' && (
            <ReactPlayer
              className={classes.audio}
              controls
              url={asset.link}
              width="100%"
              height="100%"
            />
          )}

          {asset.type === 'PDF' && <PDF asset={asset} />}
        </div>

        {assetDownloadable(asset) && (
          <CategoryButton
            click={handleDownloadAsset}
            className={['white', 'bold', 'tc', classes.download].join(' ')}
          >
            Download Asset
          </CategoryButton>
        )}

        {props.allowDelete && (
          <CategoryButton
            click={handleDeleteOpenDeleteConfirmationDialog}
            className={['white', 'bold', 'tc', classes.save].join(' ')}
          >
            Delete Asset
          </CategoryButton>
        )}

        <DeleteConfirmationDialog
          open={openDeleteConfirmationDialog}
          close={handleCloseDeleteConfirmationDialog}
          name={'this asset'}
          deleteConfirmed={handleDelete}
        />
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(EditAssetDialog)
