import React from 'react'

function StoryStatus(props) {
  const { status } = props

  const cssStatus = status === 'Published' ? '#0c3' : '#c00'
  const StoryStatus = {
    backgroundColor: cssStatus,
    color: '#fff',
    textAlign: 'right',
    width: 'max-content',
    padding: '5px 16px 4px 16px',
    borderRadius: '20px',
    position: 'absolute',
    top: '8px',
    right: '8px',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Roboto',
  }


  return (
      <div style={StoryStatus}>{status}</div>
  )
}

export default StoryStatus
