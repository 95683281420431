import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: 2 * theme.spacing(1),
  },
}))


function ProgressDialog(props) {
  const { show } = props

  const classes = useStyles()

  return (
    <div
      style={{
        display: show ? 'block' : 'none',
        position: 'fixed',
        bottom: 0,
        left: 20,
      }}
    >
      <CircularProgress className={classes.progress} />
    </div>
  )
}

ProgressDialog.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default ProgressDialog
