import React from 'react'
import { TextField, Card, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import ImageUpload from './ImageUpload'
import VideoUpload from './VideoUpload'
import TextUpload from './TextUpload'
import AudioUpload from './AudioUpload'
import YouTubeVideoUpload from './YouTubeVideoUpload'
import PDFUpload from './PDFUpload'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const styles = {
  root: {
    margin: 16,
    minWidth: 400,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    position: 'relative',
  },
}

function assetDownloadable(asset) {
  return !asset.local && asset.type !== 'TEXT' && asset.type !== 'YOUTUBE'
}

function getCaption(asset) {
  // Setting caption to empty string since, if undefined, the TextField will overlap 'Uploading' text with caption
  const caption = asset.caption || ''

  return asset.status === 'UPLOAD' ? 'Uploading....' : caption
}

function UploadItem(props) {
  const {
    classes,
    index,
    asset,
    handleChange,
    handleOnBlur,
    handleTextChange,
    enhancePic,
    click,
    download,
  } = props

  const handleClick = () => {
    click(asset)
  }

  const handleDownload = () => {
    download(asset)
  }

  const handleVideoClick = () => {
    click(asset)
  }

  const handleYoutubeVideoClick = () => {
    click(asset)
  }

  const handlePDFClick = () => {
    click(asset)
  }

  if(asset.type === 'TEXT') {
    return (
      <Card className={classes.root}>
        <TextUpload
          index={index}
          asset={asset}
          handleTextChange={handleTextChange}
        />
        <div className={classes.progress}>
          {/* TODO : setting zIndex to 1 allows onclick to work */}
          <div
            style={{ position: 'absolute', zIndex: 1 }}
            onClick={props.handleDelete(asset)}
          >
            <DeleteOutlineIcon />
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card className={classes.root}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {asset.type === 'VIDEO' && (
          <VideoUpload
            thumbnail={asset.thumbnail}
            click={handleVideoClick}
          />
        )}
        {asset.type === 'IMAGE' && (
          <ImageUpload asset={asset} enhancePic={enhancePic} />
        )}
        {asset.type === 'AUDIO' && <AudioUpload />}
        {asset.type === 'YOUTUBE' && (
          <YouTubeVideoUpload
            link={asset.link}
            click={handleYoutubeVideoClick}
          />
        )}
        {asset.type === 'PDF' && (
          <PDFUpload click={handlePDFClick} />
        )}
        <Button color="primary" onClick={handleClick}>
          Preview
        </Button>

        {/* NOT a local file */}
        {assetDownloadable(asset) && (
          <Button color="primary" onClick={handleDownload}>
            Download
          </Button>
        )}
      </div>
      <div
        style={{
          marginLeft: 16,
          flexDirection: 'column',
          flexGrow: 1,
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <TextField
          inputProps={{
            maxLength: 150,
          }}
          id="caption"
          label="Caption"
          type="text"
          fullWidth
          multiline
          disabled={asset.status === 'UPLOAD'}
          onChange={handleChange(asset)}
          onBlur={handleOnBlur(asset.id)}
          value={getCaption(asset)}
          helperText="max 150 characters"
        />
      </div>
      <div className={classes.progress}>
        {/* TODO : setting zIndex to 1 allows onclick to work */}
        <div
          data-testid="deleteUploadItem"
          style={{ position: 'absolute', zIndex: 1 }}
          onClick={props.handleDelete(asset)}
        >
          <DeleteOutlineIcon />
        </div>
      </div>
    </Card>
  )
}

export default withStyles(styles)(UploadItem)
