import React from 'react'
import {  makeStyles } from '@material-ui/core'
import Asset from './Asset'
import Input from '../UI/Input'

const useStyles = makeStyles({
    asset: {
        width: '100%',
        height: '100%'
    }
})

function AddAssetList(props){

    const { assets, editAsset } = props

    const classes = useStyles()
    
    
    return(
        <>
            {assets.map((asset, index) => (
                <div key={index} style={{display: 'flex', margin: 16}}>
                    <div style={{width: '20%'}}>
                        <Asset assetClicked={() => {}} className={classes.asset} asset={asset} creatingStory={false}/>
                    </div>
                    <div style={{flexGrow: 1, margin: 16}}>
                        <Input 
                            id={`title-${index}`}
                            text={'Asset Title'}
                            placeholder={'Asset title'}
                            value={asset.name || ''}
                            change={(e) => editAsset(e, asset, 'title')} />

                        <Input
                            id={`caption-${index}`} 
                            text={'Asset Caption'}
                            placeholder={'Asset caption'}
                            value={asset.caption || ''}
                            change={(e) => editAsset(e, asset, 'caption')} />

                        <Input
                            id={`tags-${index}`} 
                            text={'Asset Tags'}
                            placeholder={'Asset tags'}
                            value={asset.tags ? asset.tags : ''}
                            change={(e) => editAsset(e, asset, 'tags')} />
                    </div>
                    
                </div>
            ))}
        </>
    )

}

export default AddAssetList