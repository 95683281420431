import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import firebase from 'firebase/compat/app'
import Util from './Util'
import Container from './components/UI/Container'
import FormRow from './components/UI/form/FormRow'
import FormTitle from './components/UI/form/FormTitle'
import SmallTitle from './components/UI/SmallTitle'
import { showMessage } from './store/snackBarSlice'

const mapDispatchToProps = { showMessage }

const styles = (theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    flex: {
      flex: 1,
      marginRight: 50,
    },
    chip: {
      margin: theme.spacing(1),
    },
    displayName: {
      color: theme.palette.primary.main,
    },
    inputFields: {
      marginLeft: '15px',
    },
  })


class AccountDetailsDialog extends Component {
  state = {
    displayName: Util.getDisplayName(),
    email: Util.getUserEmail(),
    showResetPasswordButton: false,
  }

  handleChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    switch (id) {
      case 'username':
        this.setState({ displayName: value })
        break
      default:
        console.error('should not be here')
    }
  }

  handleSave = async () => {
    const res = await this.saveAccountDetails()

    const message = res ? 'Profile successfully saved' : 'error saving account details'

    this.props.showMessage(message)
    this.props.handleClose()
  }

  saveAccountDetails = async () => {
    if (this.state.displayName === '') return

    try {
      await firebase.auth().currentUser.updateProfile({ displayName: this.state.displayName })

      return true
    } catch (error) {
      console.error(error)

      return false
    }
  }

  handleChangePassword = () => {
    this.setState({ showResetPasswordButton: !this.state.showResetPasswordButton })
  }

  render() {
    const { classes, open } = this.props

    return (
      <Dialog open={open} onClose={this.props.handleClose}>
        <div
          className="whitebg animated fadeIn"
          id="centerdata"
          style={{ width: 500 }}
        >
          <Container>
            <SmallTitle
              className={classes.displayName}
              text={`Edit ${
                this.state.displayName ? this.state.displayName : 'User'
              }`}
            />
            <FormRow>
              <FormTitle className={classes.inputFields} text={'User Name:'} />
              <input
                type="text"
                id="username"
                maxLength={40}
                className="roundform"
                onChange={this.handleChange}
                value={this.state.displayName ? this.state.displayName : ''}
                placeholder="Name of user"
              />
            </FormRow>
            <FormRow>
              <FormTitle
                className={classes.inputFields}
                text={'Email Address:'}
              />
              <input
                type="text"
                id="useremail"
                disabled={true}
                className="roundform"
                value={this.state.email ? this.state.email : ''}
                placeholder="Email Address"
              />
            </FormRow>
            <div className="formrow" style={{ float: 'left', width: '200px' }}>
              <FormTitle className={classes.inputFields} text={'Status:'} />
            </div>

            <div
              className="category_button secondbg m10 bold white"
              style={{ marginTop: '10px', float: 'left' }}
              onClick={this.handleSave}
            >
              Save
            </div>
          </Container>
        </div>
      </Dialog>
    )
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(AccountDetailsDialog))
