import React from 'react'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'

function VideoUpload(props){

    const { click } = props
    
    return (
        <div onClick={click} 
            style={{
                backgroundImage: `url(${props.thumbnail})`, 
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: 100, 
                height: 100, 
                marginRight: 10,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer'
                }}>
            <PlayCircleFilledIcon  style={{width: 80, height: 80}}/>
            {props.children}
        </div>
    )
}

export default VideoUpload