import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = {
  root: {
    paddingBottom: 3,
    minHeight: 40,
  },
}

function getWidth(fullWidth, halfWidth, width) {
  if (width) {
    return `${width}px`
  } else if (halfWidth) {
    return '50%'
  } else if (fullWidth) {
    return '100%'
  } else {
    // should not come here since default props has fullWidth as true
    return 'unset'
  }
}

function FormRow(props) {
  const { classes, className, fullWidth, halfWidth, width } = props

  return (
    <div
      className={clsx(classes.root, { [className]: className })}
      style={{
        width: getWidth(fullWidth, halfWidth, width),
      }}
    >
      {props.children}
    </div>
  )
}

FormRow.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  halfWidth: PropTypes.bool,
  width: PropTypes.number,
}

FormRow.defaultProps = {
  fullWidth: true,
  halfWidth: false,
  width: undefined,
}

export default withStyles(styles)(FormRow)
