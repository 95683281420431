import React from 'react'
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

export default function InfoDialog(props) {

  const { open, close, info } = props

  return (
    <Dialog
      open={open}
      onClose={close}>
      <DialogTitle>
        {info}
      </DialogTitle>
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
