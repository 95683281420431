import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import StoryUploadPhoto from '../../images/story_upload_photo.png'
import StoryUploadVideo from '../../images/story_upload_video.png'
import StoryUploadDropbox from '../../images/story_upload_dropbox.png'
import StoryUploadAudio from '../../images/story_upload_audio.png'
import DropboxChooser from 'react-dropbox-chooser'
import api from '../../api'
import Util from '../../Util'
import AddAssetList from './AddAssetList'
import SubTitle from '../UI/SubTitle'
import CategoryButton from '../UI/CategoryButton'
import adminApi from '../../store/exhibitAdminApi'
import { showMessage } from '../../store/snackBarSlice'
import { connect } from 'react-redux'
import clsx from 'clsx'

const mapDispatchToProps = { showMessage }

const styles = (theme) => ({
  flex: {
    flex: 1,
    marginRight: 50,
  },
  chip: {
    margin: theme.spacing(1),
  },
  save: {
    margin: '30px 25%',
    width: '50%',
  },
})

class AddAssetDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      orgName: '',
      assets: [],
    }

    this.pickPhotoRef = React.createRef()
    this.pickVideoRef = React.createRef()
    this.pickAudioRef = React.createRef()
  }

  async componentDidMount() {
    const orgName = await this.fetchOrgName()
    if(!orgName) return
    this.setState({
      orgName: orgName,
    })
  }

  fetchOrgName = async () => {
    return await adminApi.organization().getOrgName(this.props.orgId)
  }

  handleReceiveFile = (e) => {
    let type

    const id = e.target.id

    switch(id) {
      case 'pickPhoto':
        type = 'IMAGE'
        break
      case 'pickVideo':
        type = 'VIDEO'
        break
      case 'pickAudio':
        type = 'AUDIO'
        break
      default:
        console.error('should not be here')
    }

    const files = Array.from(e.target.files)

    const arr = []

    for(let index = 0; index < files.length; index++) {
      const file = files[index]
      const url = window.URL.createObjectURL(file)
      arr.push({
        name: '',
        link: url,
        type: type,
        caption: '',
        status: 'UPLOAD',
        file: file,
        isTiffImage:
          type === 'IMAGE' &&
          (file.name.includes('.tiff') || file.name.includes('.tif')),
      })
    }

    this.setState({
      assets: [...this.state.assets, ...arr],
    })
  }

  // subType and name only applicable when file is a dropbox file
  uploadAsset = async (asset) => {
    const formData = new FormData()

    if(asset.subType) {
      // dropbox
      formData.append('subType', asset.subType)
      formData.append('file', asset.file)
    } else {
      formData.append('upload', asset.file) // because of multer
    }

    formData.append('name', asset.name)
    formData.append('caption', asset.caption)
    formData.append('type', asset.type)

    let tags = []

    if(asset.tags) {
      tags = asset.tags.split(',')
    }

    formData.append('tags', tags)
    formData.append('url', asset.link)
    formData.append('thumbnail', asset.thumbnail)
    formData.append('orgId', this.props.orgId)

    try {
      const res = await api.put('/api/upload', formData)
      const uploadedAsset = res.data.result
      return uploadedAsset
    } catch(error) {
      console.error(error)
      return null
    }
  }

  startUpload = async () => {
    const assetsBeingSaved = [...this.state.assets]
    this.props.showMessage('Uploading asset(s)....')
    assetsBeingSaved.forEach(async asset => {
      const uploadedAsset = await this.uploadAsset(asset)
      this.setState({ assets: this.state.assets.filter(a => a !== asset) })
      this.props.assetsSaved(uploadedAsset)
      this.props.showMessage('Asset(s) uploaded successfully')
    })
  }

  onReceivedDropboxFiles = (files) => {
    const arr = []

    files.forEach(async (file) => {
      arr.push({
        orgId: this.props.orgId,
        file: file,
        type: Util.getFileType(file.link),
        link: file.link,
        thumbnail: file.thumbnailLink,
        status: 'UPLOAD',
        subType: 'DROPBOX',
        name: file.name,
      })
    })

    this.setState({
      assets: [...this.state.assets, ...arr],
    })
  }

  handleSave = async () => {
    this.props.close()
    this.props.toggleProgress()
    await this.startUpload()
    this.props.toggleProgress()
  }

  handlePickPhoto = () => {
    this.pickPhotoRef.current.click()
  }

  handlePickVideo = () => {
    this.pickVideoRef.current.click()
  }

  handlePickAudio = () => {
    this.pickAudioRef.current.click()
  }

  handleEditAsset = (e, asset, type) => {
    const value = e.target.value
    const assetsCopy = [...this.state.assets]
    const found = assetsCopy.find((a) => a === asset)

    switch(type) {
      case 'title':
        found.name = value
        break
      case 'caption':
        found.caption = value
        break
      case 'tags':
        found.tags = value
        break
      default:
        console.error('should not be here')
    }

    this.setState({
      assets: assetsCopy,
    })
  }


  render() {
    const { classes, open, close } = this.props

    return (
      <Dialog fullWidth maxWidth={'md'} open={open} onClose={close}>
        <div style={{ padding: 20 }}>
          <SubTitle>Add Asset to {this.state.orgName}:</SubTitle>
          <AddAssetList
            assets={this.state.assets}
            editAsset={this.handleEditAsset}
          />

          <form
            style={{ margin: 16, display: 'flex', justifyContent: 'center' }}>
            <input
              ref={this.pickPhotoRef}
              id='pickPhoto'
              style={{ display: 'none' }}
              type='file'
              multiple
              accept={Util.allowable_image_types()}
              onChange={this.handleReceiveFile} />

            <input
              ref={this.pickVideoRef}
              id='pickVideo'
              style={{ display: 'none' }}
              type='file'
              multiple
              accept={Util.allowable_video_types()}
              onChange={this.handleReceiveFile} />

            <input
              ref={this.pickAudioRef}
              id='pickAudio'
              style={{ display: 'none' }}
              type='file'
              multiple
              accept={Util.allowable_audio_types()}
              onChange={this.handleReceiveFile} />

            <div
              className={clsx('newuploadbutton', classes.floatCssOverride)}
              style={{
                background: `url(${StoryUploadPhoto})center center/92% no-repeat`,
              }}
              onClick={this.handlePickPhoto} />

            <div
              onClick={this.handlePickVideo}
              className={clsx('newuploadbutton', classes.floatCssOverride)}
              style={{
                background: `url(${StoryUploadVideo})center center/92% no-repeat`,
              }} />

            {this.props.listedAssetTypes.some(assetType => assetType === 'DROPBOX') &&
              <DropboxChooser
                appKey={'tzs7m4lywzjdnbu'}
                linkType={'direct'}
                success={(files) => this.onReceivedDropboxFiles(files)}
                cancel={() => this.onCancel()}
                multiselect={true}
                extensions={['.jpg', '.jpeg', '.png', '.mp4']}>
                <div
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{
                    background: `url(${StoryUploadDropbox})center center/92% no-repeat`,
                  }}
                />
              </DropboxChooser>
            }

            <div
              onClick={this.handlePickAudio}
              className={clsx('newuploadbutton', classes.floatCssOverride)}
              style={{
                background: `url(${StoryUploadAudio})center center/92% no-repeat`,
              }}
            />
          </form>

          <CategoryButton
            click={this.handleSave}
            className={clsx([
              'white',
              'bold',
              'tc',
              'm10',
              'secondbg',
              classes.save,
            ])}>
            Finish & Save
          </CategoryButton>
        </div>
      </Dialog>
    )
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(AddAssetDialog))

