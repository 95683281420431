import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import LandingPage from './LandingPage'
import LoginPage from './LoginPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import UserContext from './context/UserContext'
import { useIntercom } from 'react-use-intercom'
import SecretRoute from './SecretRoute'
import adminApi from './store/exhibitAdminApi'


export default function Main(_props) {
  const { show, boot } = useIntercom()

  const [user, setUser] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)

  const authy = () => {
    firebase.auth().onAuthStateChanged(async function(fUser) {
      const id = fUser ? fUser.uid : undefined
      const user = await adminApi.user().getById(id)
      setLoaded(true)
      setIsSuperAdmin(user.isSuperAdmin)
      setUser(user)
      boot({ email: user.email })
    })
  }

  useEffect(() => {
    authy()
  }, [])


  return (
    loaded && (
      <UserContext.Provider value={user}>
        <Router>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
            <SecretRoute
              exact
              path='/'
              component={LandingPage}
              user={user}
              isSuperAdmin={isSuperAdmin} />

            <SecretRoute
              path='/dashboard'
              component={LandingPage}
              user={user}
              intercomShow={show}
              isSuperAdmin={isSuperAdmin} />

            <SecretRoute
              path='/organization'
              component={LandingPage}
              user={user}
              isSuperAdmin={isSuperAdmin} />

            <Route
              exact
              path='/login'
              render={(props) => (
                <LoginPage {...props} user={user} />
              )} />

            <Route
              exact
              path='/forgot-password'
              user={user}
              component={ForgotPasswordPage} />

          </div>
        </Router>
      </UserContext.Provider>
    )
  )
}
