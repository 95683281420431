import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'



const styles = {
    root: {
        padding: 10,
        background: '#f1f1f1',
        border: '1px solid #ccc',
        margin: '40px 0',
        borderRadius: 10,
        position: 'relative'
    }
}


function GrayBlock(props){

    const { classes, className } = props

    return (
        
        <div className={`${classes.root} ${className ? className: ''}`}>
            {props.children}
        </div>

    )
}

GrayBlock.propTypes = {
    className: PropTypes.string
}

export default withStyles(styles)(GrayBlock)