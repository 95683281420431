import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Select from '@material-ui/core/Select'
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  List,
  Divider,
} from '@material-ui/core'
import adminApi from '../../store/exhibitAdminApi'
import AppAccess from './AppAccess'
import User from '../../classes/User'
import Input from '../UI/Input'


const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
    marginRight: 50,
  },
  chip: {
    margin: theme.spacing(1),
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  adminMessageGreen: {
    color: '#0C0',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.2,
  },
  adminMessageSmallText: {
    color: '#666',
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.3,
    fontWeight: 400,
  },
  appAccessListRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
})


class AddUserDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      email: '',
      password: '',
      retypedPassword: '',
      sendEmailInvite: false,
      orgAccess: 'read-write',
      newUser: {},
      users: [],
      appsToGiveUserAccessTo: [],
      dialogTitle: '',
      allApps: [],
      selectedApp: null,
    }
  }

  fetchExhibits = async () => {
    return await adminApi.exhibit().getAllByOrgId(this.props.orgId)
  };

  fetchOrgName = async () => {
    const org = await adminApi.organization().getById(this.props.orgId)

    return org.name
  }

  /**
   * Fetch org name and org apps whenever the dialog is opened
   */
  handleOnEnter = async() => {
    const allExhibits = await this.fetchExhibits()
    const selectedExhibit = allExhibits.find(a => a._id === this.props.appId)
    const dialogTitle = selectedExhibit ?
    selectedExhibit.name :
      await this.fetchOrgName()
    this.setState({
      allApps: allExhibits,
      selectedApp: selectedExhibit,
      dialogTitle: dialogTitle,
    })
  };

  handleChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    switch (id) {
      case 'search-text':
        this.setState({
          searchText: value,
        })
        if (value === '') this.setState({ users: [] })
        break
      case 'email':
        this.setState({
          email: value,
        })
        break
      case 'password':
        this.setState({
          password: value,
        })
        break
      case 'retyped-password':
        this.setState({
          retypedPassword: value,
        })
        break
      default:
        console.error('should not be here')
        console.log(id)
    }
  };

  searchedUsers = () => {
    return this.state.users.length > 0
  };

  saveSearchedUsers = async () => {
    this.props.handleClose()
    this.props.toggleProgress()

    // Not all users in list will be added
    // only adding those that the user has marked as admin or read-write
    const newUsers = this.state.users.filter(
      (user) => user.makeAdmin === true || user.makeReadWrite === true
    )

    const orgId = this.props.orgId
    const exhibitId = this.props.appId
    await adminApi.organization().addUsers({ users: newUsers, orgId, exhibitId })
    this.props.toggleProgress()
  };

  handleSave = async () => {
    if (this.searchedUsers()) {
      this.saveSearchedUsers()
      return
    }
    const { valid, message } = this.validateInput()
    if (!valid) {
      this.props.showMessage(message)
      return
    }

    this.props.toggleProgress()
    this.addUser()
  };

  addUser = async () => {
    const user = this.state.newUser

    const isAdmin = this.state.orgAccess === 'admin' ? true : false

    const newUser = {
      org: this.props.orgId,
      appId: this.props.appId,
      name: user.displayName,
      email: this.state.email.trim(),
      tempPassword: this.state.password.trim(),
      isAdmin: isAdmin,
      appIds: this.state.appsToGiveUserAccessTo,
    }

    const result = await adminApi.user().create(newUser, this.state.sendEmailInvite)
    if (result.error) {
      this.props.showMessage(result.error)
    } else {
      this.props.showMessage('Successfully added user')
    }
    this.props.handleClose()
  }

  /**
   * Checks if user has already been added to organization or is superAdmin
   */
  doesUserExist = async () => {
    const email = this.state.email.trim()

    return await adminApi.user().existsInOrg({ email, orgId: this.props.orgId })
  }

  validateInput = () => {
    let valid = true
    let message = ''

    const email = this.state.email.trim(),
      password = this.state.password.trim(),
      retypedPassword = this.state.retypedPassword.trim()

    if (email.trim() === '' || password.trim() === '') {
      valid = false
      message = 'please fill in all fields'
    } else if (password !== retypedPassword) {
      valid = false
      message = 'passwords do not match'
    } else {
      valid = true
      message = ''
    }

    return {
      valid: valid,
      message: message,
    }
  };

  handleChangeOrgAccess = (e) => {
    const value = e.target.value
    this.setState({
      orgAccess: value,
    })
  };

  handleChangeCheckbox = (e) => {
    const checked = e.target.checked
    this.setState({
      sendEmailInvite: checked,
    })
  };

  searchUsers = async () => {
    const searchText = this.state.searchText
    const orgId = this.props.orgId

    const { users } = await adminApi.user().searchInOrg({ searchText, orgId })

    const userObjects = []

    for (let index = 0; index < users.length; index++) {
      const user = users[index]
      const userClass = new User(user)
      userObjects.push(userClass)
    }

    this.setState({
      users: userObjects,
    })

    if (users.length === 0) {
      this.props.showMessage('No users found')
    }
  }

  handleSearchMakeAdminClicked = (makeAdmin, userId) => {
    const usersCopy = [...this.state.users]
    const user = usersCopy.find((user) => user._id === userId)
    user.makeAdmin = makeAdmin

    this.setState({
      users: usersCopy,
    })
  };

  searchAddUserClicked = (added, userId) => {
    const usersCopy = [...this.state.users]
    const user = usersCopy.find((user) => user._id === userId)
    user.makeReadWrite = added
    if (user.makeAdmin === undefined) {
      user.makeAdmin = false
    }
    this.setState({
      users: usersCopy,
    })
  };

  changeAppAccess = (appId) => (e) => {
    const checked = e.target.checked
    const oldAppAccess = [...this.state.appsToGiveUserAccessTo]
    let appsToGiveUserAccessTo =  checked ?
      oldAppAccess.push(appId) :
      appsToGiveUserAccessTo = appsToGiveUserAccessTo.filter((id) => id === appId)

    this.setState({
      appsToGiveUserAccessTo: appsToGiveUserAccessTo,
    })
  };

  resetState = () => {
    this.setState({
      searchText: '',
      email: '',
      password: '',
      retypedPassword: '',
      sendEmailInvite: false,
      orgAccess: 'read-write',
      newUser: {},
      users: [],
      appsToGiveUserAccessTo: [],
      orgName: '',
      apps: [],
    })
  };

  close = () => {
    this.resetState()
    this.props.handleClose()
  };

  /** Function called when user submits the form */
  handleFormSubmit = (e) => {
    e.preventDefault()

    // Prevent default action of submit button
    // If text is empty, show message that user should enter text
    if (this.state.searchText.trim() === '') {
      if (this.state.users.length > 0) {
        this.setState({
          users: [],
        })
      }
      this.props.showMessage('Please enter text for searching')
      return
    }

    // If search value is present, perform search
    this.searchUsers()
  };

  render() {
    const { classes, open } = this.props

    return (
      <Dialog
        TransitionProps={{
          onEnter: () => this.handleOnEnter()
        }}
        open={open}
        onClose={this.close}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Add a user to {this.state.dialogTitle}
            </Typography>
            <IconButton color="inherit" onClick={this.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div>&nbsp;</div>
        <Grid container spacing={3}>
            <Grid item xs={9}>
            </Grid>
            <Grid item xs={3}>
            <Button variant="contained" color="primary" size="medium" onClick={this.handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
        <form style={{ margin: 16 }}>
          <Input
            className={classes.margin}
            value={this.state.email}
            change={this.handleChange}
            id="email"
            text="Email Address:"
          />

          <Input
            type={'password'}
            className={classes.margin}
            value={this.state.password}
            change={this.handleChange}
            id="password"
            text="Password:"
          />

          <Input
            type={'password'}
            className={classes.margin}
            value={this.state.retypedPassword}
            change={this.handleChange}
            id="retyped-password"
            text="Retype Password:"
          />
          <FormControl fullWidth className={classes.margin}>
            <InputLabel htmlFor="org-access">Organization Access</InputLabel>
            <Select
              value={this.state.orgAccess}
              onChange={this.handleChangeOrgAccess}
              inputProps={{
                name: 'org-access',
                id: 'org-access',
              }}
            >
              <MenuItem value={'read-write'}>Read and Write Access</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: this.state.orgAccess === 'admin' ? 'block' : 'none',
              margin: '10px 0',
            }}
          >
            <div className={classes.adminMessageGreen}>
              Has Admin access to edit all apps
            </div>
            <div className={classes.adminMessageSmallText}>
              This user has ability to add users, create new apps and view all
              apps.
            </div>
          </div>

          {/* only show list if user is not admin - admin users have access to all apps */}
          {this.state.orgAccess !== 'admin' && (
            <>
              <Divider style={{ marginTop: 16 }} />
              <List
                classes={{
                  root: classes.appAccessListRoot,
                }}
              >
                {this.state.selectedApp &&
                  <AppAccess
                    key={0}
                    app={this.state.selectedApp}
                    checked={this.state.selectedApp.checked}
                    change={this.changeAppAccess}
                  />
                }

                {!this.state.selectedApp && this.state.allApps
                  .map((app, index) => (
                  <AppAccess
                    key={index}
                    app={app}
                    checked={app.checked}
                    change={this.changeAppAccess}
                  />
                ))}
              </List>
            </>
          )}

          <Divider />

          <FormControl fullWidth className={classes.margin}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendEmailInvite}
                  onChange={this.handleChangeCheckbox}
                />
              }
              label="Send Invitation Email"
            />
          </FormControl>
        </form>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddUserDialog)
