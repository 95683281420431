import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { makeStyles } from '@material-ui/core'

import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const useStyles = makeStyles({
  pdfCanvas: {
    '& .react-pdf__Page__canvas': {
      height: 'unset !important',
      width: '80% !important',
      margin: '0 auto'
    }
  }
})


export default function PDF(props) {
  const { asset } = props

  const [numPages, setNumPages] = useState(0)

  const classes = useStyles()

  const handlePDFSuccess = (document) => {
    const { numPages } = document
    setNumPages(numPages)
  }

  const handlePageSuccess = () => {
    window.dispatchEvent(new Event('resize'))
  }

  return (
    <Document file={asset.link} onLoadSuccess={handlePDFSuccess}>
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          className={classes.pdfCanvas}
          object-fit='fill'
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          onLoadSuccess={handlePageSuccess} />
      ))}
    </Document>
  )
}
