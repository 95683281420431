import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export default function CustomCheckbox(props) {
  const { id, label, checked, change } = props

  return (
    <FormControlLabel
      control={
        <Checkbox id={id} checked={checked} onChange={change} color="primary" />
      }
      label={label}
    />
  )
}
