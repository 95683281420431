import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = makeStyles({
  root: {
    fontSize: 20,
    width: '100%',
    margin: '10px 0 2px',
    fontWeight: 600,
  },
})

function OldFormTitle(props) {
  const { className, text } = props

  const classes = styles()

  if (className) {
    return (
      <div className={clsx(classes.root, { [className]: className })}>
        {text}
      </div>
    )
  } else {
    return <div className={classes.root}>{text}</div>
  }
}

OldFormTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default OldFormTitle
