import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import FormTitle from './form/FormTitle'

const styles = makeStyles({
  root: {
    height: 46,
    border: '1px solid #ccc',
    fontSize: 15,
    color: '#666',
    paddingLeft: 15,
    fontSmoothing: 'subpixel-antialiased',
    boxSizing: 'border-box',
    width: '100%',
    fontWeight: 300,
    fontFamily: '\'Roboto\', Helvetica, Arial, Tahoma, Sans-Serif',
    borderRadius: 30,
  },
  formTitle: {
    marginLeft: 15,
    zIndex: 2,
    width: 'fit-content',
  },
})

Input.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  keyDown: PropTypes.func,
}

export default function Input(props) {
  const classes = styles()

  const {
    id,
    className,
    text,
    placeholder,
    type,
    value,
    change,
    keyDown,
  } = props

  return (
    <>
      <FormTitle className={classes.formTitle} text={text} />
      <input
        className={`${classes.root} ${className ? className : ''}`}
        id={id}
        type={type ? type : 'text'}
        placeholder={placeholder}
        value={value}
        onChange={change}
        onKeyDown={keyDown}
      />
    </>
  )
}
