import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  progress: {
      height: 30
  },
  message: {
    position: 'absolute', 
    top: 0,    
    left: 0,
    right: 0,
    margin: '0 auto',
    textAlign: 'center',
    color: 'white',
    fontSize: 20
  }
})

export default function UploadProgress(props) {

  const classes = useStyles()

  const { percentCompleted } = props

  const ref = useRef(null)


  useEffect(() => {
    // Make progress bar visible
    if(percentCompleted > 0){
        if(ref.current){
            ref.current.scrollIntoView()
        }
    }
  }, [percentCompleted])

  return (
    <div className={classes.root} ref={ref}>
      <LinearProgress className={classes.progress} variant="determinate" value={percentCompleted} />
      <div className={classes.message}>Story uploading. Do not close or refresh page</div>
    </div>
  )
}
