import React from 'react'

function StoryEditLink(props) {
  const { id, title, mainColor, secondaryColor, handleClickStory } = props

  const storyEdit = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: mainColor,
    color: '#fff',
    padding: '8px',
    top: '8px',
    right: '8px',
    fontSize: '14px',
    fontWeight: '700',
  }

  const storyEditLink = {
    backgroundColor: secondaryColor,
    color: '#fff',
    padding: '5px 16px 4px 16px',
    borderRadius: '20px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
  }

  return (
    <div
      style={storyEdit}
      onClick={handleClickStory(id)} >
      <span>{title.slice(0, 20)}</span>
      <span style={storyEditLink} >Edit</span>
    </div>
  )
}

export default StoryEditLink
