import React, { useEffect, useState } from 'react'
import StoryCover from './StoryCover'
import { makeStyles } from '@material-ui/core'
import SmallTitle from '../UI/SmallTitle'
import arrayMove from 'array-move'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import lodash from 'lodash'
import adminApi from '../../store/exhibitAdminApi'

const styles = makeStyles({
  noResults: {
    width: '100%',
  },
  sortableElement: {
    width: 'max-content',
    height: 'auto',
    float: 'left',
    borderRadius: '10px',
    overflow: 'hidden',
    position: 'relative',
    margin: '8px',
  },
  storyContainer: {
    margin: '16px 16px',
  }
})

export default function SortableStoryList(props) {
  const { app, disabled, handleClickStory, fetching } = props
  const [stories, setStories] = useState([])

  const classes = styles()

  useEffect(() => {
    setStories(props.stories)
  }, [props.stories])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newStories = lodash.cloneDeep(stories)

    try {
      const moved = arrayMove(newStories, oldIndex, newIndex)
      const story = moved[newIndex]
      story.rank = adminApi.rank().generate({ stories: moved, newIndex })

      adminApi.story().save(story, { simple: true })

      setStories(moved)
    } catch(error) {
      console.log(error)
    }
  }


  const SortableItem = SortableElement(({story}) => (<div className={classes.sortableElement}><StoryCover app={app} story={story} handleClickStory={handleClickStory} /></div>))

  const SortableList = SortableContainer(({items}) => {
  return (
    <div>
      {
        app && items.map((story, index) => (<SortableItem disabled={disabled} key={index} index={index} story={story} />))
      }
    </div>
  )
})

  return (
    <div className={classes.storyContainer}>
      <div>
        <SortableList
            axis="xy"
            items={stories}
            distance={2}
            disableAutoscroll={true}
            onSortEnd={onSortEnd} />

        {stories.length === 0 && fetching === false && (
          <SmallTitle
            text={'No stories found'}
            className={[classes.noResults, 'tc'].join(' ')}
          />
        )}
      </div>
    </div>
  )
}
