import React, { useEffect, useState } from 'react'
import User from './User'
import adminApi from '../../store/exhibitAdminApi'


function UsersList(props) {
  const { orgId } = props

  const [users, setUsers] = useState([])

  useEffect(() => {
    async function fetchUsers(id) {
      const users = await adminApi.user().getAllByOrgId(id)
      setUsers(users)
    }

    fetchUsers(orgId)
  }, [orgId])

  const showUserDetails = (userId) => {
    const user = users.find((u) => u._id === userId)
    if (!user) {
      console.error('could not find user')
      return
    }
    props.showUserDetails(user)
  }

  return (
    <>
      {users.map((user, index) => (
        <User key={index} user={user} showUserDetails={showUserDetails} />
      ))}
    </>
  )
}

export default UsersList
