import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Button from '../../UI/Button'
import AddNew from './AddNew'
import OldFormTitle from '../../UI/form/OldFormTitle'
import clsx from 'clsx'

const styles = makeStyles((theme) => ({
  root: {
    margin: '10px 0',
  },
  oldFormTitle: {
    fontSize: 20,
    width: '100%',
    margin: '10px 0 2px',
    fontWeight: 600,
  },
  collection: {
    float: 'left',
    margin: '0 10px 10px 0',
    borderRadius: 50,
    fontSize: 15,
    padding: '7px 16px',
    background: '#666',
  },
  collectionClicked: {
    background: theme.palette.primary.main,
  },
  addNew: {
    background: '#06c',
  },
}))


function AddCollectionSection(props) {
  const {
    appName,
    storyId,
    collections,
    addCollection,
    addSubCollection,
    clickedCollection,
    clickedCollectionId,
  } = props

  const classes = styles()

  const [showAddNewCollection, setShowAddNewCollection] = useState(false)
  const [showAddNewSubCollection, setShowAddNewSubCollection] = useState(false)
  const [newCollectionTitle, setNewCollectionTitle] = useState('')

  const handleAddNewCollection = async (newCollectionTitle) => {
    const addedCollection = await addCollection(newCollectionTitle)
    if(!addedCollection) return
    setNewCollectionTitle(addedCollection.title)
    setShowAddNewCollection(false)
    setShowAddNewSubCollection(true)
  }

  const handleAddNewSubCollection = (newSubCollectionTitle) => {
    addSubCollection(newCollectionTitle, newSubCollectionTitle)
    setShowAddNewCollection(false)
  }

  const handleAddNewClicked = () => {
    setShowAddNewCollection(true)
    setShowAddNewSubCollection(false)
    clickedCollection('')
    setNewCollectionTitle('')
  }

  const handleCollectionSelected = (collectionId) => {
    clickedCollection(collectionId)
    setShowAddNewCollection(false)
    setShowAddNewSubCollection(false)
  }

  const cancelCollectionClicked = () => {
    setShowAddNewCollection(false)
    setShowAddNewSubCollection(false)
  }

  const storyNotInCollection = collection => {
    return !collection.new && !collection.stories?.includes(storyId)
  }

  const handleCancelAddNewSubCollection = () => {
    setShowAddNewSubCollection(false)
    setNewCollectionTitle('')
  }

  return (
    <>
      <div className={classes.root}>
        <OldFormTitle text={`Choose a collection from "${appName}":`} />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {collections
            .filter(storyNotInCollection)
            .map(collection => (
              <Button
                key={collection.id}
                text={collection.title}
                className={clsx(clickedCollectionId === collection.id && classes.collectionClicked, classes.collection)}
                click={() => handleCollectionSelected(collection.id)} />
            ))}
          <Button
            text={'+ New'}
            className={clsx(classes.collection, classes.addNew)}
            click={handleAddNewClicked}
          />
        </div>
      </div>

      {newCollectionTitle &&
        <div>
          <b>Created collection &quot;{newCollectionTitle}&quot;</b>
        </div>
      }

      {showAddNewCollection && (
        <AddNew
          text={'Add a new collection'}
          placeholder={'Title of your new collection'}
          cancel={() => cancelCollectionClicked()}
          add={handleAddNewCollection}
        />
      )}

      {showAddNewSubCollection && (
        <AddNew
          text={'Add a new sub collection'}
          placeholder={'Title of your new sub collection'}
          cancel={handleCancelAddNewSubCollection}
          add={handleAddNewSubCollection}
        />
      )}
    </>
  )
}

export default AddCollectionSection
