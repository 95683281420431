import React, { useState } from 'react'
import HamburgerDark from '../../images/hamburger-dark.png'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: 40,
    height: 40,
    background: `url(${HamburgerDark})center center/contain no-repeat`,
  },
  paper: {
    position: 'absolute',
    background: 'rgba(0,0,0,.5)',
    top: 50,
    right: 0,
  },
  item: {
    margin: theme.spacing(2),
    color: 'white',
  },
  divider: {
    height: 2,
    backgroundColor: 'white',
  },
}))

export default function Menu(props) {
  const classes = useStyles()

  const { name, click } = props

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root} onClick={handleClickOpen}>
      <ClickAwayListener onClickAway={handleClose}>
        <div style={{ height: 100 }}>
          {open && (
            <Paper className={classes.paper}>
              <div className={classes.item}>{name ? name : ''}</div>
              <Divider
                classes={{
                  root: classes.divider,
                }}
              />
              <div
                id="settings"
                className={classes.item}
                onClick={(e) => click(e, 'settings')}
              >
                Settings
              </div>
              <div
                id="logout"
                className={classes.item}
                onClick={(e) => click(e, 'logout')}
              >
                Logout
              </div>
            </Paper>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}
