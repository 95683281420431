import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import Chip from '@material-ui/core/Chip'
import UploadImage from '../util/UploadImage'
import FormTitle from '../UI/form/FormTitle'
import Checkbox from '../UI/form/Checkbox'
import ChangeSubCollectionNameDialog from './ChangeSubCollectionNameDialog'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'
import { withStyles, createStyles } from '@material-ui/core'
import DeleteConfirmationDialog from '../util/DeleteConfirmationDialog'
import SubTitle from '../UI/SubTitle'
import SmallText from '../UI/SmallText'
import adminApi from '../../store/exhibitAdminApi'
import { assetValidator, createSubCollectionValidator, editCollectionValidator, formatMessages } from '@Pass-It-Down/exhibition-admin-client'
import { startUpload, finishUpload, resetUpload } from '../../store/assetsSlice'
import lodash from 'lodash'
import SubCollectionClient from '@Pass-It-Down/exhibition-admin-client/src/subCollectionClient'
import CollectionClient from '@Pass-It-Down/exhibition-admin-client/src/collectionClient'


const mapDispatchToProps = { startUpload, finishUpload, resetUpload }
const mapStateToProps = (state) => ({ uploadCount: state.assets.uploadCount })


const styles = theme =>
  createStyles({
    title: {
      color: theme.palette.primary.main,
    },
    deleteButton: {
      marginTop: 15,
      float: 'left',
      color: theme.palette.secondary.main,
      backgroundColor: 'unset',
      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    cancelButton: {
      marginTop: 15,
      float: 'left',
      backgroundColor: 'unset',
      color: theme.palette.secondary.main,
      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    saveButton: {
      float: 'right',
      marginTop: 15,
      backgroundColor: theme.palette.secondary.main,
    },
  })

const SubCollectionChip = (props) => {
  const { subCollection, handleDelete, click } = props
  return (
    <Chip
      label={subCollection.title}
      onDelete={() => handleDelete(subCollection._id)}
      style={{ margin: 5 }}
      clickable
      onClick={() => click(subCollection._id)}
    />
  )
}


class CollectionDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      collection: CollectionClient.new(),
      subCollection: '',
      openEditSubCollectionDialog: false,
      subCollectionToEdit: undefined,
      showDeleteSubCollectionConfirmationDialog: false,
      subCollectionToDeleteId: '',
    }
  }

  async fetchCollectionData(id) {
    let collection = await adminApi.collection().getById(id)
    collection = CollectionClient.new(collection)
    this.setState({ collection })
  }

  createCollection = async () => {
    const tempCollection = { title: 'temp' }
    const collection = await adminApi
      .collection()
      .create({ exhibitId: this.props.app._id, collection: tempCollection })
    collection.title = ''

    this.setState({ collection })
  }

  isNewCollection = () => {
    return this.props.collection.title === 'All Collections'
  }

  handleOnEnter = () => {
    if(this.isNewCollection()) {
      this.createCollection()
    } else {
      this.fetchCollectionData(this.props.collection.id)
    }
  }

  handleChange = (e) => {
    const id = e.target.id
    const value = e.target.value
    const collection = lodash.cloneDeep(this.state.collection)

    switch(id) {
      case 'title':
        collection.title = value
        break
      case 'description':
        collection.description = value
        break
      case 'catsubs':
        this.setState({
          subCollection: value,
        })
        break
      case 'featured':
        collection.featured = e.target.checked
        break
      default:
        console.error('should not be here')
    }

    this.setState({ collection })
  }

  collectionExists = async () => {
    return await adminApi.collection()
      .exists({ title: this.state.collection.title.trim(), appId: this.props.app._id })
  }

  handleSave = async () => {
    const { valid, message } = this.validateData(this.state.collection)
    if(!valid) {
      this.props.showMessage(message)
      return
    }

    // Only check if collection exists when creating new collection
    if(this.isNewCollection()) {
      const exists = await this.collectionExists()
      if(exists) {
        this.props.showMessage('Collection already exists')
        return
      }
    }

    this.props.toggleProgress()
    this.props.saveCollection(this.state.collection)

    this.handleClose()
  }

  validateData = (collection) => {
    const copy = { ...collection }

    copy._id = copy.id
    const validator = editCollectionValidator(copy)
    if(!validator.isValid) {
      return {
        valid: validator.isValid,
        message: validator.messages[0].message
      }
    }

    return {
      valid: true,
      message: '',
    }
  }

  handleEnter = e => {
    // Enter key
    if(e.keyCode !== 13) return

    const value = e.target.value.trim()

    const params = {
      parentCollection: this.state.collection.id,
      title: value
    }

    const newSubCollection = SubCollectionClient.new(params)

    const validator = createSubCollectionValidator(newSubCollection)
    if(!validator.isValid) {
      this.props.showMessage(formatMessages(validator))
      return
    }

    newSubCollection.new = true

    const duplicateTitle = this.state.collection
      .subCollections
      .find(s => s.title === newSubCollection.title)

    if(duplicateTitle) {
      this.props.showMessage('Subcollection already exists')
      return
    }

    const collection = lodash.cloneDeep(this.state.collection)
    collection.subCollections.push(newSubCollection)

    this.setState({ collection, subCollection: '' })
  }

  handleDeleteSubCollection = () => {
    this.setState({
      showDeleteSubCollectionConfirmationDialog: false,
    })

    const collection = lodash.cloneDeep(this.state.collection)
    const index = collection.subCollections.findIndex(c => c._id === this.state.subCollectionToDeleteId)
    collection.subCollections.splice(index, 1)
    this.setState({ collection })
    adminApi.collection().save([collection])

    this.setState({ subCollectionToDeleteId: '' })
  }

  handleReceiveImage = async (e) => {
    const orgId = this.props.org._id
    const text = ''

    let newAssets =
      Array.from(e.target.files)
        .map(file => adminApi.asset().newAsset({ orgId, text, file, _window: window }))


    const invalidMessages = newAssets
      .map(asset => assetValidator(asset))
      .filter(v => !v.isValid)
      .map(v => v.messages)
      .flat()

    if(invalidMessages.length > 0) {
      this.props.showMessage(invalidMessages[0].message)
      return
    }

    if(newAssets.length == 0) {
      console.error('no new assets to upload')
      return
    }

    this.props.startUpload()

    const newAssetPromises = newAssets.map((newAsset, index) => {
      const file = e.target.files[index]
      return adminApi.asset().save(newAsset, file)
    })

    const results = await Promise.all(newAssetPromises)
    const errors = results.filter(res => res.error !== '')

    if(errors.length > 0) {
      this.props.showMessage(errors[0])
    }

    newAssets = results
      .filter(res => !res.error)
      .map(({ asset }) => asset)

    const newAsset = newAssets[0]
    const collection = lodash.cloneDeep(this.state.collection)
    collection.coverPhoto = newAsset.link

    this.setState({ collection })
  }

  resetState = () => {
    this.setState({
      collection: CollectionClient.new(),
      subCollection: '',
      deletedSubCollections: [],
      openEditSubCollectionDialog: false,
      subCollectionToEdit: undefined,
      showDeleteSubCollectionConfirmationDialog: false,
      subCollectionToDeleteId: '',
    })
  }

  deleteNewSubCollections = () => {
    const deleteOperations = this.state.collection
      .subCollections
      .filter(s => s.new)
      .map(s => adminApi.subCollection().delete(s._id))
    Promise.all(deleteOperations)
  }

  handleClose = () => {
    this.resetState()
    this.props.close()
  }

  deleteNewCollection = () => {
    adminApi.collection().delete({ collectionId: this.state.collection.id, exhibitId: this.props.app._id })
  }

  handleCancel = () => {
    if(this.isNewCollection()) this.deleteNewCollection()
    this.deleteNewSubCollections()
    this.handleClose()
  }

  handleSubCollectionClick = subCollectionId => {
    const subCollection = this.state.collection.subCollections.find(s => s._id === subCollectionId)

    const copy = { ...subCollection }
    this.setState({
      openEditSubCollectionDialog: true,
      subCollectionToEdit: copy,
    })
  }

  handleCloseEditSubCollectionDialog = () => {
    this.setState({
      openEditSubCollectionDialog: false,
      subCollectionToEdit: undefined,
    })
  };

  handleChangeSubCollection = (e) => {
    const value = e.target.value
    const copy = { ...this.state.subCollectionToEdit }
    copy.title = value
    this.setState({
      subCollectionToEdit: copy,
    })
  }

  handleSaveSubCollection = () => {
    const collection = this.state.collection
    const subCollection = collection.subCollections
      .find(s => s._id === this.state.subCollectionToEdit._id)

    if(!this.state.subCollectionToEdit.title.trim()) {
      this.props.showMessage('Title cannot be empty')
      return
    }

    // Find subCollection with duplicate title
    const findSubcollectionsWithSameTitle = this.state.collection
      .subCollections.filter(s => {
        return s.title === this.state.subCollectionToEdit.title.trim() &&
          s._id !== this.state.subCollectionToEdit._id
      })

    if(findSubcollectionsWithSameTitle.length > 0) {
      this.props.showMessage('Subcollection already exists')
      return
    }

    const index = collection.subCollections
      .findIndex(s => s._id === this.state.subCollectionToEdit._id)

    // Save new title
    subCollection.title = this.state.subCollectionToEdit.title

    // Save copy of new subCollection
    collection.subCollections[index] = { ...subCollection }
    this.setState({ collection })

    this.handleCloseEditSubCollectionDialog()
  }

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({
      showDeleteSubCollectionConfirmationDialog: false,
      subCollectionToEdit: undefined
    })
  }

  askUserToConfirm = subCollectionId => {
    this.setState({
      showDeleteSubCollectionConfirmationDialog: true,
      subCollectionToDeleteId: subCollectionId,
    })
  }

  render() {
    const { classes, open, app, deleteCollection } = this.props

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth={'sm'}
        onClose={this.handleCancel}
        TransitionProps={{
          onEnter: () => this.handleOnEnter()
        }}>
        <div style={{ padding: 20 }}>
          <SubTitle className={classes.title}>
            {this.isNewCollection() ?
              `Add a collection to "${app?.name}"` :
              `Edit "${this.props.collection.title}"`}:
          </SubTitle>
          <div className='formrow'>
            <FormTitle text={'Collection Title (50 char limit):'} />
            <input
              type='text'
              id='title'
              value={this.state.collection.title}
              placeholder='Title of your collection.'
              maxLength={50}
              autoFocus
              onChange={this.handleChange}
            />
          </div>
          <div className='formrow'>
            <FormTitle text={'Short Description (100 char limit):'} />
            <input
              type='text'
              id='description'
              value={this.state.collection.description}
              placeholder='Short description of your collection.'
              maxLength={100}
              onChange={this.handleChange}
            />
          </div>
          <div className='formrow'>
            <FormTitle text={'Sub Collections:'} />
            <input
              type='text'
              id='catsubs'
              value={this.state.subCollection}
              placeholder='Add Sub Collections'
              onChange={this.handleChange}
              onKeyDown={this.handleEnter} />
            <SmallText
              className={'m5'}
              text={'Press Enter to add Sub Collection'} />
            <div>
              {this.state.collection.subCollections.map(subCollection => {
                return (
                  <SubCollectionChip
                    key={subCollection._id}
                    subCollection={subCollection}
                    handleDelete={this.askUserToConfirm}
                    click={this.handleSubCollectionClick}
                  />
                )
              })}
            </div>
          </div>
          <UploadImage
            id={'edit-collection'}
            width={'100%'}
            image={this.state.collection.coverPhoto}
            handleReceiveImage={this.handleReceiveImage}
          />
          <SmallText
            className={'m5'}
            text={'Ideal cover photo should be landscape with a height of 300px'}
          />

          <div>
            <Checkbox
              id={'featured'}
              checked={this.state.collection.featured}
              change={this.handleChange}
              label={'Mark As Featured'} />
          </div>

          <CategoryButton
            className={clsx('bold', classes.cancelButton)}
            click={this.handleCancel}>
            Cancel
          </CategoryButton>

          {!this.isNewCollection() && <CategoryButton
            className={clsx('bold', classes.deleteButton)}
            click={deleteCollection}>
            Delete
          </CategoryButton>}

          <CategoryButton
            className={clsx('white', 'bold', classes.saveButton)}
            click={this.handleSave}>
            Save Collection
          </CategoryButton>
        </div>

        <ChangeSubCollectionNameDialog
          open={this.state.openEditSubCollectionDialog}
          close={this.handleCloseEditSubCollectionDialog}
          change={this.handleChangeSubCollection}
          subCollection={this.state.subCollectionToEdit}
          save={this.handleSaveSubCollection}
        />

        <DeleteConfirmationDialog
          open={this.state.showDeleteSubCollectionConfirmationDialog}
          close={this.handleCloseDeleteConfirmationDialog}
          name={this.state.subCollectionToDelete?.title}
          deleteConfirmed={this.handleDeleteSubCollection} />
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CollectionDialog))
