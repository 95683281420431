import firebase from 'firebase/compat/app'
import AudioIcon from './images/audio_icon.png'
import PDFIcon from './images/pdf_icon.jpg'
import NoMediaImage from './images/no_media.png'
import adminApi from './store/exhibitAdminApi'


// Initialize Firebase
const data = process.env.REACT_APP_FIREBASE_CONFIG
const config = JSON.parse(data)
firebase.initializeApp(config)

// for creating other users without signing in the newly created user
const secondaryApp = firebase.initializeApp(config, 'Secondary')

const snackBarVariants = { ERROR: 'error', SUCCESS: 'success' }

export const IMAGE_TYPES = {
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  TIF: 'image/tif',
  TIFF: 'image/tiff',
}
Object.freeze(IMAGE_TYPES)

export const VIDEO_TYPES = {
  MP4: 'video/mp4',
  QUICKTIME: 'video/quicktime',
}
Object.freeze(VIDEO_TYPES)

export const AUDIO_TYPES = {
  MP3: 'audio/mp3',
  MPEG: 'audio/mpeg',
  WAVE: 'audio/wave',
  WAV: 'audio/wav',
}

Object.freeze(AUDIO_TYPES)


export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class Util {
  static allowable_image_types() {
    return `${IMAGE_TYPES.JPG}, ${IMAGE_TYPES.JPEG}, ${IMAGE_TYPES.PNG}, ${IMAGE_TYPES.TIF}, ${IMAGE_TYPES.TIFF}`
  }

  static allowable_audio_types() {
    return `${AUDIO_TYPES.MP3}, ${AUDIO_TYPES.MPEG}, ${AUDIO_TYPES.WAVE}, ${AUDIO_TYPES.WAV}`
  }

  static allowable_video_types() {
    return `${VIDEO_TYPES.MP4}, ${VIDEO_TYPES.QUICKTIME}`
  }

  static fileIsOfTheWrongFormat(type, file) {
    if (type === 'IMAGE') {
      return (
        file.type !== IMAGE_TYPES.JPG &&
        file.type !== IMAGE_TYPES.JPEG &&
        file.type !== IMAGE_TYPES.PNG &&
        file.type !== IMAGE_TYPES.TIF &&
        file.type !== IMAGE_TYPES.TIFF
      )
    }

    if (type === 'VIDEO') {
      return file.type !== VIDEO_TYPES.MP4 && file.type !== VIDEO_TYPES.QUICKTIME
    }

    if (type === 'AUDIO') {
      return (
        file.type !== AUDIO_TYPES.MP3 &&
        file.type !== AUDIO_TYPES.MPEG &&
        file.type !== AUDIO_TYPES.WAVE &&
        file.type !== AUDIO_TYPES.WAV
      )
    }

    if (type === 'PDF') {
      return file.type !== 'application/pdf'
    }
  }

  static portlandLflbTemplateFilter(isPortland, isLFLB, templates) {
    if (isPortland) {
      const filteredTemplates = templates.filter(t => t.name === 'portland')
      return { templates: filteredTemplates, isPortland: false}
    }

    if (isLFLB) {
      const filteredTemplates = templates.filter(t => t.name === 'custom-lflb')
      return { templates: filteredTemplates, isLFLB: false }
    }
  }

  static getAppId(props) {
    return props.initializedFromAssetsPage ? props.selectedApp._id : props.match.params.appId
  }

  static specialTemplateSuperWow = (templates, orgId, portResponse, lflbResponse) => {
    let isLFLB = false
    let isPortland = false

    if (portResponse.error === undefined) {
      const portlandOrgId = portResponse.response?._id
      if (portlandOrgId === orgId) {
        templates.push({ name: 'portland', label: 'Portland' })
        isPortland = true

      }
    }

    if (lflbResponse.error === undefined) {
      const lflbOrgid = lflbResponse.response?._id
      if (lflbOrgid === orgId) {
        templates.push({ name: 'custom-lflb', label: 'Custom LFLB' })
        isLFLB = true
      }
    }

    return [ templates, isPortland, isLFLB ]
  }

  static getSecondaryApp = () => {
    return secondaryApp
  }

  static getSnackbarVariants = () => {
    return snackBarVariants
  }

  static getErrorMessage = function (error) {
    return error.response.data
  }

  // TODO: change name to something random or pass name as parameter
  static getFileFromObjectURL = async (objectURL, name, type) => {
    return await fetch(objectURL)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], name, { type: type }))
  };

  static getFileType = (link) => {
    let type

    if (link.includes('.mp4')) {
      type = 'VIDEO'
    } else if (this.isImage(link)) {
      type = 'IMAGE'
    } else if (link === 'audio/mp3') {
      type = 'AUDIO'
    } else {
      console.error('unknown file type')
    }

    return type
  }

  static isImage = (link) => {
    link = link.toLowerCase()
    return (
      link.includes('.jpg') || link.includes('.jpeg') || link.includes('.png')
    )
  };

  static openInNewTab = (url) => {
    const win = window.open(url, '_blank')
    win.focus()
  };

  static getYouTubeIdFromUrl(youtubeUrl) {
    let video_id = youtubeUrl.split('embed/')[1]
    const ampersandPosition = video_id.indexOf('&')
    if (ampersandPosition !== -1) {
      video_id = video_id.substring(0, ampersandPosition)
    }
    return video_id
  }

  static NotEmpty(value) {
    return value !== null && value !== '' && value !== undefined
  }

  static isAudioFile = (fileName) => {
    return (
      fileName.includes('.mp3') ||
      fileName.includes('.wav') ||
      fileName.includes('.wave') ||
      fileName.includes('.mpeg')
    )
  };

  static getDisplayName = () => {
    if (firebase.auth() === null || firebase.auth().currentUser === null)
      return undefined
    return firebase.auth().currentUser.displayName
      ? firebase.auth().currentUser.displayName
      : firebase.auth().currentUser.email
  };

  static getUserEmail = function () {
    const user  = firebase.auth().currentUser
    const email = user ? user.email : ''
    return email
  }

  static isGameDay = (exhibit) => {
    return exhibit?.template?.name === 'gameday'
  }

  static capitalizeFirstLetter = str => {
    return str[0].toUpperCase() + str.slice(1)
  }
}

export const storiesOrder = [
  {
    value: 'recently-added',
    label: 'Recently Added',
  },
  {
    value: 'oldest-first',
    label: 'Oldest First',
  },
  {
    value: 'random',
    label: 'Random',
  }
]

export const mapZooms = [
  {
    label: 'Global',
    value: 2,
  },
  {
    label: 'Country',
    value: 4,
  },
  {
    label: 'Region',
    value: 10,
  },
  {
    label: 'City',
    value: 12,
  },
  {
    label: 'Neighborhood',
    value: 14,
  }
]

function isImage(fileType) {
  return (
    fileType === IMAGE_TYPES.JPEG ||
    fileType === IMAGE_TYPES.JPG ||
    fileType === IMAGE_TYPES.PNG ||
    fileType === IMAGE_TYPES.TIF ||
    fileType === IMAGE_TYPES.TIFF
  )
}

function isVideo(fileType) {
  return fileType === VIDEO_TYPES.MP4 || fileType === VIDEO_TYPES.QUICKTIME
}

function isAudio(fileType) {
  return (
    fileType === AUDIO_TYPES.MP3 ||
    fileType === AUDIO_TYPES.MPEG ||
    fileType === AUDIO_TYPES.WAV ||
    fileType === AUDIO_TYPES.WAVE
  )
}

function isPDF(fileType) {
  return fileType === 'application/pdf'
}

export const getFileType = (fileType) => {
  let type
  if (isVideo(fileType)) {
    type = 'VIDEO'
  } else if (isImage(fileType)) {
    type = 'IMAGE'
  } else if (isAudio(fileType)) {
    type = 'AUDIO'
  } else if (isPDF(fileType)) {
    type = 'PDF'
  } else {
    console.error('should not be here')
  }

  return type
}

export const getImage = asset => {
  let image
  switch (asset.type) {
    case 'VIDEO':
      image = asset.thumbnail
      break
    case 'AUDIO':
      image = AudioIcon
      break
    case 'PDF':
      image = PDFIcon
      break
    case 'IMAGE':
      image = adminApi.image().imageUrl(asset.link, 500, 500)
      break
    default:
      image = NoMediaImage
  }
  return image
}

export default Util
