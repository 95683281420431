import React from 'react'
import { Route } from 'react-router-dom'
import AllApps from './AllApps'

function Apps(props){
  const { org } = props

  return (
      <div>
        <Route path={props.match.path} render={(props) =>
          <AllApps
            {...props}
            org={org}
            drawerOpen={props.drawerOpen}
            toggleDrawer={props.toggleDrawer}/>}/>
      </div>

  )
}

export default Apps
