import ExhibitionAdminClient from '@Pass-It-Down/exhibition-admin-client'
import Sentry from '../Sentry'

const _log = Sentry.captureException
const url = process.env.REACT_APP_SERVER_URL
const proxyUrl = process.env.REACT_APP_IMG_PROXY_URL
const adminApi = new ExhibitionAdminClient({ url, proxyUrl, _log })


export default adminApi
