import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import AudiotrackIcon from '../../images/headphones-monochrome.png'
import { Document, Page, pdfjs } from 'react-pdf'
import TiffImagePlaceholder from '../../images/tiff_placeholder.png'
import ExifOrientationImg from 'react-exif-orientation-img'

import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`


const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: 500,
    display: 'flex',
    justifyContent: 'center'
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  audio: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: `url(${AudiotrackIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  youtube: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  pdfCanvas: {
    '& .react-pdf__Page__canvas': {
      height: 'unset !important',
      width: '80% !important',
      margin: '0 auto'
    }
  }
})


function AssetPreviewDialog(props) {

  const { open, close, asset } = props

  const [numPages, setNumPages] = useState(null)

  const classes = useStyles()

  const handlePDFSuccess = (document) => {
    const { numPages } = document
    setNumPages(numPages)
  }
  const handlePDFError = (e) => {
    console.log('error loading pdf')
    console.error(e)
  }


  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth={'md'}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className={classes.root}>
        <DialogContentText id='alert-dialog-description'>
          {asset.type === 'YOUTUBE' &&
            <ReactPlayer
              className={classes.youtube}
              controls
              youtube={asset.type === 'YOUTUBE'}
              url={asset.link}
              width='100%'
              height='100%' />
          }

          {asset.type === 'VIDEO' &&
            <ReactPlayer
              className={classes.video}
              controls
              url={asset.link}
              width='100%'
              height='100%' />
          }

          {asset.type === 'IMAGE' &&
            <ExifOrientationImg src={asset.isTiffImage ? TiffImagePlaceholder : asset.link}
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                height: 500
              }} />
          }

          {asset.type === 'AUDIO' &&
            <ReactPlayer
              className={classes.audio}
              controls
              url={asset.link}
              width='100%'
              height='100%' />
          }

          {asset.type === 'PDF' &&
            <Document
              file={asset.file ? asset.file : asset.link}
              onLoadSuccess={handlePDFSuccess}
              onLoadError={handlePDFError}>
              {Array.from(
                new Array(numPages),
                (_, index) => (
                  <Page
                    className={classes.pdfCanvas}
                    object-fit='fill'
                    key={`page_${index + 1}`}
                    pageNumber={index + 1} />
                ),
              )}
            </Document>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='primary' autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssetPreviewDialog
