import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CommentIcon from '../../images/comment_icon.png'
import { makeStyles } from '@material-ui/core'
import { cloneDeep } from 'lodash'

import './EnhancePicDialog.css'


const useStylesAnnotation = makeStyles({
  root: {
    position: 'absolute',
    minHeight: 75,
    boxSizing: 'border-box'
  },
  commentIcon: {
    backgroundImage: `url(${CommentIcon})`,
    backgroundColor: 'rgba(0,0,0,.9)',
    backgroundPosition: 'center',
    backgroundSize: 26,
    backgroundRepeat: 'no-repeat',
    width: 50,
    height: 50,
    position: 'relative',
    borderRadius: '100%',
    border: '2px solid #fff'
  },
  text: {
    padding: '5px 5px 5px 16px',
    left: 50,
    textAlign: 'left',
    top: 0,
    maxWidth: 300,
    minWidth: 250,
    borderRadius: 2,
    background: 'rgba(0,0,0,0.6)',
    color: '#fff',
    position: 'absolute',
    zIndex: 0,
    overflowWrap: 'break-word' // https://css-tricks.com/almanac/properties/o/overflow-wrap/#targetText=The%20overflow%2Dwrap%20property%20in,layout%20problems%20due%20to%20overflow.
  }
})

const useStylesAddAnnotation = makeStyles({
  root: {
    position: 'absolute',
    width: 300,
    padding: 10,
    borderRadius: 5,
    background: 'rgba(0,0,0,0.5)',
  },
  textarea: {
    height: 80,
    width: '100%',
    marginBottom: 8
  },
  cancel: {
    color: 'white', background: 'none', border: 'none'
  },
  delete: {
    color: 'white', background: 'none', border: 'none', marginRight: 16
  },
  save: {
    fontFamily: '\'Roboto\', sans-serif',
    letterSpacing: '-1px',
    fontWeight: 700,
    lineHeight: 1.3,
    fontSize: 14,
    padding: '6px 14px 4px',
    background: '#06c',
    color: '#FFF',
    fontSmoothing: 'antialiased',
    border: 'none',
    textTransform: 'uppercase',
    margin: '5px 5px 0 0',
    borderRadius: 30
  },
})


const annotationHasText = annotation => {
  return annotation.text?.trim() !== ''
}

const Annotation = (props) => {

  const { annot, edit } = props

  const classes = useStylesAnnotation()

  const [showText, setShowText] = useState(false)

  return (
    <div className={classes.root}
      style={{ left: annot.x, top: annot.y }}
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}>
      <div onClick={edit} className={classes.commentIcon} />
      <div className={[classes.text, showText ? 'fadeIn' : 'fadeOut'].join(' ')}>
        {annot.text}
      </div>
    </div>
  )
}


const AddAnnotation = (props) => {

  const { originalAnnotation, annotationBeingEdited, annotChange, cancel, save, deleteAnnotation } = props

  const classes = useStylesAddAnnotation()

  return (
    <div className={classes.root} style={{
      top: originalAnnotation.y,
      left: originalAnnotation.x
    }}>
      <textarea
        maxLength={100}
        value={annotationBeingEdited.text}
        onChange={(e) => annotChange(e.target.value)}
        className={classes.textarea} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button onClick={() => cancel(annotationBeingEdited)} className={classes.cancel}>Cancel</button>
        <div>
          {originalAnnotation.text && <button onClick={() => deleteAnnotation(annotationBeingEdited)} className={classes.delete}>Delete</button>}
          <button
            onClick={() => save(annotationBeingEdited)}
            className={classes.save}>Save</button>
        </div>
      </div>
    </div>
  )
}


export default function EnhancePicDialog(props) {

  const { open, close, asset, save } = props

  const [image, setImage] = useState('')
  const [originalAnnotation, setOriginalAnnotation] = useState()
  const [annotations, setAnnotations] = useState([])
  const [originalAnnotations, setOriginalAnnotations] = useState([])
  const [annotationBeingEdited, setAnnotationBeingEdited] = useState()

  const findAnnotationIndex = (annot) => {
    const index = annotations.findIndex(a => {
      return a.x === annot.x &&
        a.y === annot.y
    })

    return index !== -1 ? index : undefined
  }

  useEffect(() => {
    if(!asset) return
    setImage(asset.link)
    setAnnotations(asset.annotations)
    setOriginalAnnotations(asset.annotations)
  }, [asset])

  // Get x and y  coords relative to parent element which in this
  // case is the img element
  const handleImageClick = (e) => {
    const bounds = e.target.getBoundingClientRect()
    const x = e.clientX - bounds.left
    const y = e.clientY - bounds.top

    const xPercent = (x / bounds.width) * 100
    const yPercent = (y / bounds.height) * 100

    const annot = {
      x: `${xPercent}%`,
      y: `${yPercent}%`,
      text: '',
      editing: true
    }

    setOriginalAnnotation(annot)
    setAnnotationBeingEdited(annot)
  }

  const handleChangeAnnotation = (value) => {
    const annotCopy = { ...annotationBeingEdited }
    annotCopy.text = value
    setAnnotationBeingEdited(annotCopy)
  }

  const handleAddAnnotationCanceled = (annot) => {
    const index = findAnnotationIndex(annot)

    // found in original list
    if(index || index === 0) {
      const annotationsCopy = cloneDeep(annotations)
      annotationsCopy[index].editing = false
      setAnnotations(annotationsCopy)
    }

    setOriginalAnnotation()
    setAnnotationBeingEdited()
  }

  const handleSaveAnnotation = (annot) => {

    if(!annotationHasText(annot)) return

    const annotationsCopy = cloneDeep(annotations)
    const annotCopy = { ...annotationBeingEdited }
    delete annotCopy.editing

    const index = findAnnotationIndex(annot)

    if(index || index === 0) {
      annotationsCopy[index] = annotCopy
    } else {
      annotationsCopy.push(annotCopy)
    }

    setAnnotations(annotationsCopy)
    setOriginalAnnotation()
    setAnnotationBeingEdited()
  }

  const handleEditAnnotation = annot => {
    const index = findAnnotationIndex(annot)

    if(index || index === 0) {
      const annotationsCopy = cloneDeep(annotations)
      annotationsCopy[index].editing = true
      setAnnotations(annotationsCopy)
    }

    setOriginalAnnotation({ ...annot })
    setAnnotationBeingEdited({ ...annot })
  }

  const handleDeleteAnnotation = (annot) => {
    const index = findAnnotationIndex(annot)

    if(index || index === 0) {
      const copy = cloneDeep(annotations)
      copy.splice(index, 1)
      setAnnotations(copy)
    }

    setOriginalAnnotation()
    setAnnotationBeingEdited()
  }

  /**
   * Ignore all changes and pass back original list
   * Reset state
   */
  const handleClose = () => {
    close(asset.id, cloneDeep(originalAnnotations))
    resetState()
  }

  /**
   * Pass back the new changes
   * Reset state
   */
  const handleSave = () => {
    save(asset.id, cloneDeep(annotations))
    resetState()
  }

  const resetState = () => {
    setImage('')
    setOriginalAnnotation()
    setAnnotations([])
    setOriginalAnnotations([])
    setAnnotationBeingEdited()
  }

  const displayedAnnotations = annotations.filter(annot => !annot.editing)

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent style={{ position: 'relative' }}>
        <img alt={''} height={'100%'} width={'100%'} src={image} onClick={handleImageClick} />

        {originalAnnotation &&
          <AddAnnotation
          originalAnnotation={originalAnnotation}
          annotationBeingEdited={annotationBeingEdited}
          save={handleSaveAnnotation}
          annotChange={handleChangeAnnotation}
          cancel={handleAddAnnotationCanceled}
          deleteAnnotation={handleDeleteAnnotation} />
        }

        {displayedAnnotations.map((annot, index) => {
          return <Annotation key={index} annot={annot} edit={() => handleEditAnnotation(annot)} />
        })}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
