// matches css platform_tile
import React from 'react'
import {
  createStyles,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = makeStyles((theme) =>
  createStyles({
    root: {
      fontFamily: 'Roboto',
      lineHeight: 1.2,
      webkitFontSmoothing: 'antialiased',
      fontSmoothing: 'antialiased',
      fontWeight: 700,
      color: '#06c',
      [theme.breakpoints.up(1)]: {
        fontSize: 18,
      },
      [theme.breakpoints.up(860)]: {
        fontSize: 18,
      },
      [theme.breakpoints.up(1025)]: {
        fontSize: 22,
      },
    },
  })
)


function SubTitle(props) {
  const { className } = props
  const classes = styles()

  return (
    <div className={clsx(classes.root, className && className)}>
      {props.children}
    </div>
  )
}

SubTitle.propTypes = {
  className: PropTypes.string,
}

export default SubTitle
