import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { DragHandle } from './DragHandle'
import UploadItem from '../UploadItem'


export const SortableItem = SortableElement(
  ({
    assetIndex,
    asset,
    handleChange,
    handleOnBlur,
    handleTextChange,
    handleDelete,
    enhancePic,
    click,
    download,
  }) => {
    return (
      <li
        className="sortableHelper"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10000,
        }}
      >
        <DragHandle />
        <UploadItem
          index={assetIndex}
          enhancePic={enhancePic}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
          asset={asset}
          handleDelete={handleDelete}
          handleTextChange={handleTextChange}
          click={click}
          download={download} />
      </li>
    )
  }
)