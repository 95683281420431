import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import FormTitle from '../../UI/form/FormTitle'
import Button from '../../UI/Button'
import Input from '../../UI/Input'
import clsx from 'clsx'

const styles = theme => ({
  formTitle: {
    margin: '15px 0'
  },
  subCollectionClicked: {
    background: `${theme.palette.primary.main} !important`,
    color: 'white !important'
  },
  collection: {
    float: 'left',
    margin: '0 10px 10px 0',
    borderRadius: 50,
    fontSize: 15,
    padding: '7px 16px',
    background: '#666'
  },
  subCollection: {
    fontSize: 14,
    fontWeight: 600,
    padding: '4px 13px',
    background: '#f1f1f1',
    color: '#666',
    '&:hover': {
      background: '#444',
      cursor: 'pointer',
      color: 'white'
    }
  },
  addNew: {
    background: '#06c'
  },
  cancel: {
    background: '#666'
  },
  save: {
    background: '#0C3'
  },
  addNewCollectionStyles: {
    width: 250,
    borderRadius: 50
  },
  marginOffset: {
    margin: '10px 0 0 10px'
  }
})


function AddSubCollectionSection(props) {

  const { classes, open, collections, addSubCollection, clickedCollectionId, save, cancel, showButtons, hideButtons } = props

  const [clickedSubId, setClickedSubId] = useState('')
  const [showAddNewSubCollection, setShowAddNewSubCollection] = useState(false)
  const [value, setValue] = useState('')


  const handleSubCollectionClick = (subCollectionId) => {
    setClickedSubId(subCollectionId)
  }

  const handleShowAddNewSubCollection = () => {
    setShowAddNewSubCollection(true)
    hideButtons()
  }

  const handleSaveSubCollection = () => {
    save(clickedSubId)
    handleCancel()
  }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleAddNewSubCollection = () => {
    addSubCollection(value)
    setValue('')
  }

  const handleCancel = () => {
    setShowAddNewSubCollection(false)
    setClickedSubId('')
    setValue('')
    cancel()
  }


  return (
    <div style={{ display: open ? 'block' : 'none' }}>
      <FormTitle text={'Sub Collections:'} className={classes.formTitle} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {clickedCollectionId &&
          collections.find(c => c.id === clickedCollectionId)?.subCollections.map((sub, i) => (
            <Button
              key={i}
              text={sub.title}
              click={() => handleSubCollectionClick(sub._id)}
              className={clsx(clickedSubId === sub._id && classes.subCollectionClicked, classes.collection, classes.subCollection)} />
          ))}
        <Button
          text={'+ New'}
          className={clsx(classes.collection, classes.addNew)}
          click={handleShowAddNewSubCollection} />

      </div>

      {showAddNewSubCollection &&
        (
        <div style={{ display: 'flex' }}>
          <div style={{ width: 250 }}>
            <Input
              className={classes.addNewCollectionStyles}
              text={'Add a new sub collection'}
              placeholder={'Title of your new sub collection'}
              value={value}
              change={handleChange} />
            </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Button
              text={'Add'}
              click={handleAddNewSubCollection}
              className={clsx(classes.collection, classes.save, classes.marginOffset)} />

            <Button
              text={'Cancel'}
              className={clsx(classes.collection, classes.cancel, classes.marginOffset)}
              click={handleCancel} />
          </div>
          </div>
        )}

      <div style={{ display: showButtons ? 'flex' : 'none', marginTop: 16 }}>
        <Button
          text='Save'
          click={handleSaveSubCollection}
          className={clsx(classes.collection, classes.save)} />

        <Button
          text={'Cancel'}
          className={clsx(classes.collection, classes.cancel)}
          click={handleCancel} />
      </div>
    </div>
  )
}

export default withStyles(styles)(AddSubCollectionSection)