import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import GrayBlock from '../UI/GrayBlock'
import Input from '../UI/Input'
import { useTheme } from '@material-ui/core'


function isValidYouTubeLink(link){
    return link.includes('https://youtube.com') || link.includes('https://www.youtube.com')
}

export default function AddYoutubeLink(props){

    const { open, close, save, showMessage } = props

    const [value, setValue] = useState('')
    
    const theme = useTheme()

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleSave = () => {

        let youtubeLink = value.trim()

        if(!youtubeLink){
            showMessage('Please provide a valid link')
        }else{
            youtubeLink = youtubeLink
            .replace('watch?v=','embed/')
            .replace('https://youtu.be','https://youtube.com')

            if(isValidYouTubeLink(youtubeLink)){
                save(youtubeLink)
            }else{
                showMessage('Please provide a valid YouTube link')
            }
        }
    }


    return (
        <Dialog
            open={open}
            onClose={close}
            fullWidth
            maxWidth={'md'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <GrayBlock>
                    <Input 
                        text={'Add a YouTube video'}
                        placeholder={'Add a link to a YouTube video e.g. https://www.youtube.com/watch?v=F6-7gYgVC7w'} 
                        value={value}
                        change={handleChange}/>
                </GrayBlock>
            </DialogContent>
            <DialogActions>
                <Button style={{backgroundColor: '#333', color: 'white'}} onClick={close} autoFocus>
                    Cancel
                </Button>
                <Button style={{backgroundColor: theme.palette.primary.main, color: 'white'}} onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}