import React, { useState, useEffect } from 'react'
import { Chip, withStyles } from '@material-ui/core'
import AddCollectionSection from './add-to-collection/AddCollectionSection'
import GrayBlock from '../UI/GrayBlock'
import FormTitle from '../UI/form/FormTitle'
import Button from '../UI/Button'
import AddSubCollectionSection from './add-to-collection/AddSubCollectionSection'

const styles = theme => ({
  subTitle: {
    fontSize: 18,
    color: '#666',
    fontWeight: 700,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    letterSpacing: -1,
  },
  oldFormTitle: {
    fontSize: 20,
    width: '100%',
    margin: '10px 0 2px',
    fontWeight: 600,
    background: 'unset',
  },
  buttonDelete: {
    background: '#C00',
    borderRadius: 50,
    float: 'left',
    margin: '0 10px 10px 0',
    fontSize: 15,
    padding: '7px 16px',
  },
  subCollection: {
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: 50,
    float: 'left',
    margin: '0 10px 10px 0',
    fontSize: 15,
    padding: '7px 10px',

    // If user deletes the first subCollection from a list,
    // the previous one changes its background color to gray;
    // Material-UI does this automatically
    '&:focus': {
      background: theme.palette.primary.main,
    }
  },
})

function CollectionsSection(props) {
  const {
    classes,
    app,
    storyId,
    collections,
    addCollection,
    addSubCollection,
    addCollectionWithCollectionId,
    addCollectionWithCollectionIdAndSubCollectionId,
    deleteCollection,
    removeStoryFromSubCollection
  } = props

  const [appName, setAppName] = useState('')
  const [clickedCollectionId, setClickedCollectionId] = useState('')
  const [showSubCollections, setShowSubCollections] = useState(false)
  const [showButtons, setShowButtons] = useState(false)

  useEffect(() => {
    if(!app) return

    setAppName(app.name)
  }, [app])

  const collectionHasStory = (collection) => {
    return collection.stories?.includes(storyId)
  }

  const subCollectionHasStory = (subCollection) => {
    return subCollection.stories?.includes(storyId)
  }

  const handleClickedCollection = (collectionId) => {
    setClickedCollectionId(collectionId)
    if (collectionId) {
      setShowSubCollections(true)
      setShowButtons(true)
    } else {
      setShowSubCollections(false)
    }
  }

  const handleCancel = () => {
    setShowSubCollections(false)
    setClickedCollectionId('')
  }

  const handleSave = (subCollectionId) => {
    if (subCollectionId) {
      addCollectionWithCollectionIdAndSubCollectionId(
        clickedCollectionId,
        subCollectionId
      )
    } else {
      addCollectionWithCollectionId(clickedCollectionId)
    }
  }

  const addNewSubCollectionToExistingCollection = (newSubCollectionTitle) => {
    const collection = collections.find(c => c.id === clickedCollectionId)
    addSubCollection(collection.title, newSubCollectionTitle)
  }

  return (
    <>
      <div className={classes.subTitle}>
        <div>Collections:</div>
        <div style={{ margin: '20px 0' }}>
          {/* Show only new collections that are being added */}
          {collections
            .filter(collectionHasStory)
            .map((c, i) => (
            <GrayBlock key={i}>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <FormTitle text={c.title} className={classes.oldFormTitle} />
                  <div
                      style={{ display: 'flex', flexWrap: 'wrap', marginTop: 16 }}>
                    {c.subCollections?.filter(subCollectionHasStory)
                        .map((sub, i) => (
                          <Chip
                            key={i}
                            label={sub.title}
                            onDelete={() => removeStoryFromSubCollection(c.id, sub._id)}
                            className={classes.subCollection} />
                      ))}
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      text={'Delete'}
                      className={classes.buttonDelete}
                      click={() => deleteCollection(c.id)} />
                </div>
              </div>
            </GrayBlock>
          ))}
        </div>
      </div>

      <AddCollectionSection
        appName={appName}
        storyId={storyId}
        collections={collections}
        addCollection={addCollection}
        addSubCollection={addSubCollection}
        clickedCollection={handleClickedCollection}
        clickedCollectionId={clickedCollectionId}
      />

      <AddSubCollectionSection
        open={showSubCollections}
        showButtons={showButtons}
        collections={collections}
        hideButtons={() => setShowButtons(false)}
        addSubCollection={addNewSubCollectionToExistingCollection}
        clickedCollectionId={clickedCollectionId}
        save={handleSave}
        cancel={handleCancel}
      />
    </>
  )
}

export default withStyles(styles)(CollectionsSection)
