import React, { Component } from 'react'
import { connect } from 'react-redux'
import Snackbar from './components/util/Snackbar'
import 'firebase/compat/auth'
import Util from './Util'
import DarkLogo from './images/logo-dark.png'

import './LoginAndForgotPasswordPage.css'
import { showMessage } from './store/snackBarSlice'

const mapDispatchToProps = { showMessage }

class ForgotPasswordPage extends Component {
  state = { emailAddress: '' }

  handleChange = (e) => {
    const target = e.target
    const value = target.value
    this.setState({ emailAddress: value })
  }

  handleClick = (e) => {
    e.preventDefault()
  }

  handleSendPasswordReset = async () => {
    if (this.state.emailAddress === '') {
      this.props.showMessage('Please enter a valid email address')
      return
    }

    try {
      await Util.getSecondaryApp()
        .auth()
        .sendPasswordResetEmail(this.state.emailAddress)

      this.props.showMessage('Password reset email sent')
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <div>
        <div className="adminbar">
          <div
            className="logo"
            style={{
              background: `url(${DarkLogo})center center/contain no-repeat`,
            }}
          />
        </div>

        <div className="subtitle blue tc">Request a password reset email</div>
        <div
          className="loginForgotPasswordPageForm"
          style={{
            width: '50%',
            margin: '20px 25%',
            position: 'relative',
            zIndex: 1,
            background: 'none',
            boxShadow: 'none',
          }}
        >
          <div className="smtitle blue tl">Your Email Address:</div>
          <input
            id="loginemail"
            type="input"
            className="roundform"
            style={{
              width: '100%',
              background: '#fff',
              paddingLeft: '15px',
              height: '45px',
              border: '1px solid #ccc',
              fontSize: '18px',
            }}
            value={this.state.emailAddress}
            onChange={this.handleChange}
            placeholder="Enter your email"
            autoFocus
          />
          <div
            className="category_button bluebg white m10"
            style={{
              float: 'left',
              marginTop: '10px',
            }}
            onClick={this.handleSendPasswordReset}
          >
            Send Reset Link
          </div>
        </div>
        <Snackbar />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ForgotPasswordPage)
