import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

class LogoutDialog extends React.Component {
  render() {
    return (
        <Dialog
          open={this.props.showDialog}
          onClose={this.props.handleCloseDialog}>
          <DialogTitle id="alert-dialog-title">{'Are you sure you want to log out?'}</DialogTitle>
          <DialogActions>
            <Button onClick={this.props.handleCloseDialog} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.props.handleLogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
    )
  }
}

export default LogoutDialog
