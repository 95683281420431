import { createSlice } from '@reduxjs/toolkit'
import adminApi from './exhibitAdminApi'

export const allExhibits = state => state.exhibit.all

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    current: null,
    all: []
  },
  reducers: {
    setCurrentOrganization: (state, action) => {
      state.current = action.payload
    },
    setOrganizations: (state, action) => {
      state.all = action.payload
    }
  }
})

export const { setCurrentOrganization, setOrganizations } = organizationSlice.actions

export const loadByUserId = (userId) => async (dispatch) => {
  const orgs = await adminApi.organization().getByUserId(userId)

  dispatch(setOrganizations(orgs))
}

export const loadCurrentOrganization = (orgId) => async (dispatch) => {
  const org = await adminApi.organization().getById(orgId)

  dispatch(setCurrentOrganization(org))
}

export const addOrganization = (userId, newOrg) => async (dispatch, getState) => {
  const { response  } = await adminApi.organization().create(userId, newOrg)
  const savedOrg = response
  if(!savedOrg) return null

  const orgs = [ ...getState().organization.all, savedOrg]
  dispatch(setOrganizations(orgs))
  return savedOrg
}

export const updateOrganization = (orgEdit) => async (dispatch, getState) => {
  const { response } = await adminApi.organization().update(orgEdit)
  const updatedOrg = response
  if(!updatedOrg) return null

  const orgs = getState().organization.all.map((org) => {
    return org._id === updatedOrg._id ?
      updatedOrg :
      org
  })

  dispatch(setOrganizations(orgs))
  return updatedOrg
}

export const deleteOrganization = (orgId) => async (dispatch, getState) => {
  const { success } = await adminApi.organization().delete(orgId)
  if(!success) return false

  const orgs = getState().organization.all.filter((org) => org._id !== orgId)
  dispatch(setOrganizations(orgs))
  return true
}

export const currentOrg = state => state.organization.current

export default organizationSlice.reducer
