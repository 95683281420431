import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { Redirect } from 'react-router-dom'
import AccountDetailsDialog from '../AccountDetailsDialog'
import CreateOrganizationDialog from './organization/CreateOrganizationDialog'
import Organization from './organization/Organization'
import EditOrganizationDialog from './organization/EditOrganizationDialog'
import DeleteOrganizationDialog from './organization/DeleteOrganizationDialog'
import ProgressDialog from './util/ProgressDialog'
import Snackbar from './util/Snackbar'
import UserContext from '../context/UserContext'
import PlatformTop from './UI/PlatformTop'
import { loadByUserId, addOrganization, updateOrganization, deleteOrganization } from '../store/organizationSlice'
import { showMessage } from '../store/snackBarSlice'
import adminApi from '../store/exhibitAdminApi'
import { assetValidator, formatMessages } from '@Pass-It-Down/exhibition-admin-client'


const mapStateToProps = (state) => ({
  organizations: state.organization.all
})

const mapDispatchToProps = { showMessage, loadByUserId, addOrganization, updateOrganization, deleteOrganization }

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  drawerHeader: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  organizations: {
    padding: '15px 15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  organization: {
    margin: 0,
    padding: '15px 2%',
    background: '#f1f1f1',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginTop: 20,
  },
  categoryButton: {
    color: '#06c',
    padding: '6px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    background: '#06c',
    fontWeight: '700',
  },
  cssOverride: {
    float: 'unset',
  },
  platformTop: {
    float: 'unset',
  }
})

const NEW_ORG_DEFAULT_INFO = {
  name: '',
  plan: '1',
  logo: '',
  usingOrgLevelTemplate: false,
  coverPhoto: '',
}

const ORG_TO_EDIT_DEFAULT_INFO = {
  name: '',
  plan: '1',
  status: 'ACTIVE',
  logo: '',
  usingOrgLevelTemplate: false,
  coverPhoto: '',
}

function userIsAllowedToCreateOrganization(user) {
  return user && user.isSuperAdmin
}


class Dashboard extends Component {
  static contextType = UserContext

  state = {
    drawerOpen: false,
    loggedIn: false,
    showDialog: false,
    redirecToLoginPage: false,
    openAccountDetailsDialog: false,
    message: '',
    openCreateOrganizationDialog: false,
    openEditOrganizationDialog: false,
    newOrg: NEW_ORG_DEFAULT_INFO,
    orgToEdit: ORG_TO_EDIT_DEFAULT_INFO,
    status: 'ACTIVE',
    orgToDelete: null,
    openDeleteOrganizationDialog: false,
    showProgressDialog: false,
    allowedToCreateOrganization: false,
  }

  async componentDidMount() {
    const user = this.context
    if (!(user && user.email)) return
    this.props.loadByUserId(user._id)

    this.setState({
      allowedToCreateOrganization: userIsAllowedToCreateOrganization(user),
    })
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  handleDrawerItemClick = (index) => () => {
    this.toggleDrawer()
    switch (index) {
      case 0:
        this.props.history.push('/dashboard')
        break
      default:
        console.error('should not be here')
    }
  }

  handleCloseLogoutDialog = () => {
    this.setState({ showDialog: false })
  }

  handleOpenLogoutDialog = () => {
    this.setState({ showDialog: true })
  }

  handleLogout = () => {
    this.setState({ redirecToLoginPage: true })
    firebase.auth().signOut()
  }

  handleClick = () => {
    this.setState({ openCreateOrganizationDialog: true })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpenAccountDialog = () => {
    this.setState({ openAccountDetailsDialog: true })
  }

  handleCloseAccountDetailsDialog = () => {
    this.setState({ openAccountDetailsDialog: false })
  }

  handleCloseCreateOrganizationDialog = () => {
    this.setState({ openCreateOrganizationDialog: false, newOrg: NEW_ORG_DEFAULT_INFO })
  }

  handleOrgClick = (orgId) => {
    this.props.history.push(`/organization/${orgId}/apps`)
  }

  handleClickEditOrg = (orgId) => {
    const org = this.props.organizations.find(org => org._id === orgId)
    const orgCopy = { ...org }
    this.setState({ orgToEdit: orgCopy, openEditOrganizationDialog: true })
  }

  handleEditOrgChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    const orgToEditCopy = { ...this.state.orgToEdit }

    switch (id) {
      case 'orgname':
        orgToEditCopy.name = value
        break
      case 'usingOrgLevelTemplateSwitch':
        orgToEditCopy.usingOrgLevelTemplate = e.target.checked
        break
      default:
        console.log('should not be here')
    }

    this.setState({ orgToEdit: orgToEditCopy })
  }

  handleChangePlanForEditedOrg = (e) => {
    const value = e.target.value
    const orgToEditCopy = { ...this.state.orgToEdit }
    orgToEditCopy.plan = value
    this.setState({ orgToEdit: orgToEditCopy })
  }

  handleCloseEditOrgDialog = () => {
    this.setState({ openEditOrganizationDialog: false })
  }

  resetOrgToEdit = () => {
    this.setState({ orgToEdit: { ...ORG_TO_EDIT_DEFAULT_INFO } })
  }

  resetNewImage = () => {
    this.setState({ newImage: '' })
  }

  handleSaveEditedOrg = async () => {
    if (!this.state.orgToEdit || this.state.orgToEdit.name === '') {
      this.props.showMessage('Please provide a name')
      return
    }

    this.handleCloseEditOrgDialog()
    this.showProgressDialog()

    const orgToEditCopy = { ...this.state.orgToEdit }

    try {
      await this.props.updateOrganization(orgToEditCopy)

      this.props.showMessage('Saved organization')
    } catch (error) {
      console.error(error)
      this.props.showMessage('Failed to save organization')
    }

    this.hideProgressDialog()
  }

  handleNewOrgChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    const newOrgCopy = { ...this.state.newOrg }

    switch (id) {
      case 'orgname':
        newOrgCopy.name = value
        break
      case 'plan':
        newOrgCopy.plan = value
        break
      case 'usingOrgLevelTemplateSwitch':
        newOrgCopy.usingOrgLevelTemplate = e.target.checked
        if (!e.target.checked) newOrgCopy.coverPhoto = ''
        break
      default:
        console.log('unknown option')
    }

    this.setState({ newOrg: newOrgCopy })
  }

  showProgressDialog = () => {
    this.setState({ showProgressDialog: true })
  }

  hideProgressDialog = () => {
    this.setState({ showProgressDialog: false })
  }

  hasCoverPhoto = () => {
    return this.state.newOrg.coverPhoto !== ''
  }

  handleSaveNewOrg = async () => {
    if (!this.state.newOrg || this.state.newOrg.name.trim() === '') {
      this.props.showMessage('Please provide a name')
      return
    }

    this.showProgressDialog()
    const userId = firebase.auth().currentUser.uid
    const newOrg = await this.props.addOrganization(userId, this.state.newOrg)

    if(newOrg) {
      this.handleCloseCreateOrganizationDialog()
      this.props.showMessage('Successfully created new organization')
    } else {
      this.props.showMessage('Error creating organization. Try again later')
    }

    this.handleCloseCreateOrganizationDialog()
    this.hideProgressDialog()
   }

  handleReceiveImage = async e => {

    const file = e.target.files[0]
    const type = 'IMAGE'

    const newAsset = adminApi.asset().newAsset({ orgId: this.state.orgToEdit._id, file, type, _window: window })
    const validator = assetValidator(newAsset)
    if(!validator.isValid) {
      showMessage(formatMessages(validator))
      return
    }

    const { error, asset } = await adminApi.asset().save(newAsset, file)
    if(error) {
      showMessage(error)
      return
    }

    const orgToEditCopy = { ...this.state.orgToEdit }
    orgToEditCopy.logo = asset.link
    this.setState({
      orgToEdit: orgToEditCopy,
    })
  }

  openDeleteOrganizationDialog = () => {
    this.setState({ openDeleteOrganizationDialog: true })
  }

  handleCloseDeleteOrganizationDialog = () => {
    this.setState({ openDeleteOrganizationDialog: false })
  }

  handleOpenDeleteOrgDialog = (orgId) => () => {
    const org = this.props.organizations.find(org => org._id === orgId)
    const orgCopy = { ...org }
    this.setState({ orgToDelete: orgCopy })
    this.openDeleteOrganizationDialog()
  }

  handleDeleteOrg = (orgId) => async () => {
    this.handleCloseDeleteOrganizationDialog()
    this.handleCloseEditOrgDialog()
    this.showProgressDialog()
    const deleted = await this.props.deleteOrganization(orgId)
    this.hideProgressDialog()

    if (deleted) {
      this.setState({
        orgToEdit: {
          name: '',
          plan: '1',
          status: 'ACTIVE',
        },
      })
      this.props.showMessage('Successfully deleted organization')
    } else {
      this.props.showMessage('Failed to delete organization')
    }
  }

  render() {
    const { classes } = this.props

    if (this.state.redirecToLoginPage) {
      return <Redirect to="/login" />
    }

    return (
        <div className={classes.root}>
        <div className={classes.organization}>
          {/* TODO : css override */}
          <PlatformTop className={classes.platformTop}>
            <div className={'title secondcolor'} style={{ fontSize: 32 }}>
              Organizations
            </div>
            <div
              className="category_button bluebg white bold"
              style={{
                display: this.state.allowedToCreateOrganization
                  ? 'block'
                  : 'none',
                width: 'fit-content',
                margin: '10px 0 0 5px',
              }}
              onClick={this.handleClick}
            >
              {' '}
              + New Organization
            </div>
          </PlatformTop>
        </div>

        <div className={classes.organizations}>
          {this.props.organizations.map((org, index) => (
            <Organization
              key={index}
              handleClickEdit={this.handleClickEditOrg}
              handleClick={this.handleOrgClick}
              org={org}
            />
          ))}
        </div>

        {/* Need to wrap in && condition since an error will occur due to lack of default props for the orgToEdit */}
        {/* TODO: find a way to add defaultProps for Material-UI + Typescript */}
        {this.state.openEditOrganizationDialog && (
          <EditOrganizationDialog
            open={this.state.openEditOrganizationDialog}
            orgToEdit={this.state.orgToEdit}
            deleteOrg={this.handleOpenDeleteOrgDialog}
            receiveImage={this.handleReceiveImage}
            handleChange={this.handleEditOrgChange}
            handleClose={this.handleCloseEditOrgDialog}
            handleSave={this.handleSaveEditedOrg}
            handleChangePlan={this.handleChangePlanForEditedOrg}
          />
        )}

        {/* Need to wrap in && condition since an error will occur due to lack of default props for the orgToEdit */}
        {/* TODO: find a way to add defaultProps for Material-UI + Typescript */}
        {this.state.openCreateOrganizationDialog && (
          <CreateOrganizationDialog
            open={this.state.openCreateOrganizationDialog}
            newOrg={this.state.newOrg}
            handleChange={this.handleNewOrgChange}
            handleSave={this.handleSaveNewOrg}
            handleClose={this.handleCloseCreateOrganizationDialog}
          />
        )}

        {this.state.orgToDelete && (
          <DeleteOrganizationDialog
            org={this.state.orgToDelete}
            open={this.state.openDeleteOrganizationDialog}
            close={this.handleCloseDeleteOrganizationDialog}
            deleteOrg={this.handleDeleteOrg}
          />
        )}

        <AccountDetailsDialog
          open={this.state.openAccountDetailsDialog}
          handleClose={this.handleCloseAccountDetailsDialog}
        />

        <Snackbar />

        <ProgressDialog show={this.state.showProgressDialog} />

      <button
        onClick={this.props.intercomShow}
        style={{position: 'fixed', bottom: '30px', right: '30px'}}
      />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))
