import React, { useEffect, useState } from 'react'
import UploadLogo from '../../images/upload_logo.png'
import { makeStyles } from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import StoryTile from '../UI/StoryTile'
import AudiotrackIcon from '../../images/headphones-monochrome.png'
import PDFIcon from '../../images/pdf_icon.jpg'
import TiffImagePlaceholder from '../../images/tiff_placeholder.png'
import clsx from 'clsx'

const useStyles = makeStyles({
  assetRoot: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  videoPlayIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    width: 80,
    height: 80,
  },
  assetStory: {
    background: '#3C0',
    color: '#fff',
    cursor: 'pointer',
  },
})

const AssetType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  YOUTUBE: 'YOUTUBE',
  AUDIO: 'AUDIO',
  PDF: 'PDF',
  TEXT: 'TEXT',
}

function Asset(props) {
  const { className, asset, creatingStory, assetClicked, addToStory } = props

  const classes = useStyles()

  const [image, setImage] = useState('')

  useEffect(() => {
    if(asset) {
      switch(asset.type) {
        case AssetType.IMAGE:
          if(asset.isTiffImage) {
            setImage(TiffImagePlaceholder)
          } else {
            setImage(asset.link)
          }
          break
        case AssetType.VIDEO:
        case AssetType.YOUTUBE:
          setImage(asset.thumbnail)
          break
        case AssetType.AUDIO:
          setImage(AudiotrackIcon)
          break
        case AssetType.PDF:
          setImage(PDFIcon)
          break
        case AssetType.TEXT:
          setImage(UploadLogo)
          break
        default:
          console.error('should not be here', asset.type)
          setImage(UploadLogo)
      }
    }
  }, [asset])

  const handleAddToStory = (e) => {
    e.stopPropagation() // to prevent onClick for showing edit asset dialog
    addToStory(asset._id)
  }

  return (
    <StoryTile
      image={image}
      className={clsx(classes.assetRoot, !!className && className)}
      click={() => assetClicked(asset)}>
      <div
        onClick={handleAddToStory}
        className={clsx('asset_story', asset.added && classes.assetStory)}
        style={{ display: creatingStory ? 'block' : 'none' }}>
        {asset.added ? 'Selected' : 'Add to Story'}
      </div>
      {asset.type === AssetType.VIDEO && (
        <div>
          <PlayCircleFilledIcon className={classes.videoPlayIcon} />
        </div>
      )}
    </StoryTile>
  )
}

export default Asset
