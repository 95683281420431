import React from 'react'
import EiffelTower from '../../images/eiffel-tower.jpeg'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'


export default function SquaresTemplateDisplayChooser(props){

    const handleChange = (e) => {
        props.handleChange(e.target.value)
    }


    const { displayOption } = props


    return (
        <div style={{display: 'flex', width: '100%', minHeight: 300}}>
            <div style={{flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{
                    backgroundImage: `url(${EiffelTower})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%', height: '80%'}}/>
                <div style={{height: '20%', width: '100%', textAlign: 'center'}}>
                    Original
                </div>
            </div>
            <div style={{ flexGrow: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <RadioGroup aria-label="display-options" name="display-options" value={displayOption} onChange={handleChange}>
                    <FormControlLabel value="cropped" control={<Radio />} label="Cropped" />
                    <FormControlLabel value="contained" control={<Radio />} label="Full Image" />
                </RadioGroup>
            </div>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{
                    backgroundImage: `url(${EiffelTower})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: 'black',
                    backgroundSize: displayOption === 'cropped' ? 'cover' : 'contain',
                    width: 200,
                    height: 200}} />
                <div style={{height: '20%', width: '100%', textAlign: 'center'}}>
                    Result
                </div>
            </div>
        </div>
    )

}
