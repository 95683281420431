// matches css platform_tile
import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = {
    root: {
        float: 'left',
        width: 200,
        maxHeight: 400,
        minHeight: 300,
        margin: '0 2% 50px 0',
        overflow: 'hidden',
        '&:hover': {
            opacity: .9
        }
    }
}

function PlatformTile(props){

    const { classes, className, click } = props


    return (
        <div data-testid='platform-tile' className={clsx(classes.root, {
            [className] : className
        })} onClick={click ? click : undefined}>
            {props.children}
        </div>
    )
}

PlatformTile.propTypes = {
    className: PropTypes.string,
    click: PropTypes.func,
}

export default withStyles(styles)(PlatformTile)