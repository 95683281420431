import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Magnify2 from '../../images/magnify2.png'
import Input from '../UI/Input'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  demoSearch: {
    marginRight: '1%',
    marginLeft: '2%',
    width: 300,
    top: 'unset',
    left: 'unset',
  },
  input: {
    paddingLeft: '37px !important',
    background: `url(${Magnify2})9px center/25px no-repeat #fff`,
    paddingTop: '4px',
  },
})

function Search(props) {
  const { placeholder, search } = props

  const classes = useStyles()

  const [value, setValue] = useState('')

  const handleChange = (e) => {
    setValue(e.target.value)
    if (e.target.value === '') search('') // search and return all stories so that user doesn't have to press Enter
  }

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return
    search(value)
  }

  return (
    <div className={classes.demoSearch}>
      <Input
        text={'Search'}
        className={classes.input}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        keyDown={handleKeyDown}
      />
    </div>
  )
}

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
}

export default Search
