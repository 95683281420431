import { createSlice } from '@reduxjs/toolkit'

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    uploadCount: 0
  },
  reducers: {
    startUpload: (state, _action) => {
      state.uploadCount++
    },
    finishUpload: (state, _action) => {
      state.uploadCount--
    },
    resetUpload: (state, _action) => {
      state.uploadCount = 0
    }
  }
})

export const { startUpload, finishUpload, resetUpload } = assetsSlice.actions
export const uploadCount = state => state.assets.uploadCount

export default assetsSlice.reducer
