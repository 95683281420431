import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import MapsPin from '../../images/maps_pin.png'
import adminApi from '../../store/exhibitAdminApi'

import './Map.css'

const Marker = () => (
  <img
    alt={'Map Pin'}
    src={MapsPin}
    style={{ width: 25, transform: 'translate(-50%, -100%)' }}
  />
)

export default function MapContainer(props) {
  const [mapStyle, setMapStyle] = useState([])

  useEffect(() => {
    fetchMapStyle(props.app.mapStyle)
  }, [])

  const fetchMapStyle = async (mapStyleName) => {
    const mapStyle = await adminApi.mapStyle().getByName(mapStyleName)
    setMapStyle(mapStyle)
  }

  return (
    <div style={{ height: 300, width: '100%' }}>
      <GoogleMapReact
        center={props.mapCenter}
        zoom={props.zoom}
        options={{ styles: mapStyle }}
      >
        {props.center?.lat && (
          <Marker lat={props.center.lat} lng={props.center.lng} />
        )}
      </GoogleMapReact>
    </div>
  )
}
