import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const SecretRoute = ({ user, intercomShow, isSuperAdmin, component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.email ? (
          <Component {...props} user={user} intercomShow={intercomShow} isSuperAdmin={isSuperAdmin} />
        ) : (
          <Redirect
            user={user}
            to={{
              pathname: '/login',
              state: {
                from: props.location,
                user: user
              },
            }}
          />
        )
      }
    />
  )
}

export default SecretRoute
