import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { Input, InputLabel, MenuItem, FormHelperText, Checkbox, FormControlLabel } from '@material-ui/core'
import IconAdInlineDisplay from '../../images/icon-ad-inline-display.png'
import IconAdTopBanner from '../../images/icon-ad-top-banner.png'
import StoryUploadPhoto from '../../images/story_upload_photo.png'
import StoryUploadVideo from '../../images/story_upload_video.png'
import StoryUploadYoutubeVideo from '../../images/story_upload_youtube.png'
import StoryUploadDropbox from '../../images/story_upload_dropbox.png'
import StoryUploadText from '../../images/story_upload_text.png'
import StoryUploadAudio from '../../images/story_upload_audio.png'
import StoryUploadPDF from '../../images/story_upload_pdf.png'
import Vault from '../../images/vault.png'
import StoryTag from './StoryTag'
import arrayMove from 'array-move'
import Util, { sleep } from '../../Util'
import DropboxChooser from 'react-dropbox-chooser'
import MetaDataComponent from './MetaData'
import lodash from 'lodash'
import MapContainer from './Map'
import Geosuggest from 'react-geosuggest'
import DeleteStoryDialog from './DeleteStoryDialog'
import EnhancePicDialog from './EnhancePicDialog'
import GrayArrow from '../../images/gray_arrow.png'
import Geocode from 'react-geocode'
import CollectionsSection from '../collection/CollectionsSection'
import Switch from '../UI/form/Switch'
import OldFormTitle from '../UI/form/OldFormTitle'
import AddYoutubeLink from './AddYoutubeLink'
import GrayBlock from '../UI/GrayBlock'
import FormRow from '../UI/form/FormRow'
import UploadProgress from './UploadProgress'
import AssetPreviewDialog from './AssetPreviewDialog'
import SearchForAssetDialog from './SearchForAssetDialog'
import EditAssetDialog from '../asset/EditAssetDialog'
import { assetValidator, StoryValidator, formatMessages, updateStoryValidator, validateAnnotations, createCollectionValidator, createSubCollectionValidator, castDates } from '@Pass-It-Down/exhibition-admin-client'
import { startUpload, finishUpload, resetUpload } from '../../store/assetsSlice'
import { SortableContainer } from './sortable/SortableContainer'
import { SortableItem } from './sortable/SortableItem'
import { statuses } from '../util/story'
import { Transition } from './Transition'
import adminApi from '../../store/exhibitAdminApi'
import CollectionClient from '@Pass-It-Down/exhibition-admin-client/src/collectionClient'
import SubCollectionClient from '@Pass-It-Down/exhibition-admin-client/src/subCollectionClient'
import clsx from 'clsx'
import MetaData from '@Pass-It-Down/exhibition-admin-client/src/metadata'
import { logInfo } from '../../Sentry'
import { newValidator } from 'simple-valley'
import { monthValidator, yearValidator } from '@Pass-It-Down/passitdown-utils'


const yearValid = year => {
  const v = newValidator({ year })
  return year && yearValidator(v).isValid
}

const monthValid = month => {
  const v = newValidator({ month })
  return month && monthValidator(v).isValid
}

const mapDispatchToProps = { startUpload, finishUpload, resetUpload }
const mapStateToProps = (state) => ({ uploadCount: state.assets.uploadCount })


const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
    marginRight: 50,
  },
  chip: {
    margin: theme.spacing(1),
  },
  cssOverrides: {
    border: 'unset !important',
  },
  floatCssOverride: {
    float: 'unset',
  },
  uploadButton: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  searchItems: {
    width: '100%',
    paddingLeft: '50px !important',
    background: `url(${Vault})10px center/30px no-repeat #fff`,
  },
  addMetaDataBtn: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),
  },
  menu: {
    width: 200,
  },
})

const storyTemplates = ['Gallery', 'Stacked']

const DEFAULT_MAP_CENTER = { lat: 38.267153, lng: -97.7430608 }

const initialState = {
  id: '',
  title: '',
  description: '',
  image: '',
  startYear: '',
  startMonth: '',
  startDay: '',
  endYear: '',
  endMonth: '',
  endDay: '',
  tag: '',
  tags: [],
  assets: [],
  story: {},
  openMetaData: false,
  status: 'Draft',
  featured: false,
  metaData: MetaData.new(),
  location: DEFAULT_MAP_CENTER,
  mapCenter: DEFAULT_MAP_CENTER,
  locationName: '',
  zoom: 4,
  storyTemplate: 'Gallery',
  apps: [],
  openDeleteDialog: false,
  openEnhancePicDialog: false,
  picToEnhance: undefined,
  locationType: 'name',
  coords: '',
  addDate: false,
  openAddYoutubeLinkDialog: false,
  uploadPercent: 0,
  openAssetPreviewDialog: false,
  previewAsset: {},
  openSearchAssetDialog: false,
  assetToEdit: undefined,
  openEditAssetDialog: false,
  isPortland: false,
  saving: false
}

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY)


class AddOrEditStoryDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...initialState,
      collections: [],
      subCollections: []
    }

    this.pickPhotoRef = React.createRef()
    this.pickVideoRef = React.createRef()
    this.pickAudioRef = React.createRef()
    this.pickPDFRef = React.createRef()

    this.componentCleanup = this.componentCleanup.bind(this)
  }

  getMapCenter = (story) => {
    if(story.location?.lat) {
      return story.location
    }

    return this.props.app.mapCenterAddressCoords ?? DEFAULT_MAP_CENTER
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.app === undefined && this.props.app !== undefined) {
      this.setState({
        zoom: this.props.app.mapZoom,
        mapCenter: this.props.app.mapCenterAddressCoords ?? DEFAULT_MAP_CENTER,
        location: this.props.app.mapCenterAddressCoords ?? DEFAULT_MAP_CENTER,
      })
    }

    if (prevProps.story === undefined && this.props.story !== undefined) {
      const story = this.props.story
      this.setState({
        ...story,
        assets: [],
        image: '', // setting to null so that it can be replaced when story assets are saved
        tags: [],
        collections: [],
        zoom: this.props.app.mapZoom,
        mapCenter: this.getMapCenter(story),
        },
        async () => {
          if (this.state.startYear !== undefined && this.state.startYear !== null) {
            this.setState({ addDate: true })
          }
        }
      )
    }

    if (prevProps.initializedFromAssetsPage === false && this.props.initializedFromAssetsPage === true) {
      const assetsToAdd = this.props.newStoryWithAssets

      const firstAsset = assetsToAdd[0]

      const image = firstAsset.type === 'IMAGE' ? firstAsset.link : firstAsset.thumbnail

      this.setState({ assets: assetsToAdd, image })
    }
  }

  async componentCleanup() {
    this.cleanUp()
  }

  async componentDidMount() {
    // In case user refreshes the page
    window.addEventListener('beforeunload', this.componentCleanup)
  }

  async componentWillUnmount() {
    this.componentCleanup()
    window.removeEventListener('beforeunload', this.componentCleanup)

  }

  checkIfPortand = async () => {
    const { error, response } = await adminApi.organization().getPortland()
    if (error === undefined) { return response?._id === this.props.orgId }
    return false
  }

  createStory = async () => {
    if(!this.isNewStory()) return

    let story = {
      appId: this.props.app._id,
      title: '',
      storyType: 'story',
      tags: [],
      storyAssets: []
    }

    story = await adminApi.story().save(story, { simple: true })
    this.setState({ id: story.id })
  }

  onEntered = async () => {
    await this.createStory()
    await this.fetchCollections()
    const isPortland = await this.checkIfPortand()
    const metadData = isPortland ? MetaData.new({ isPortland: true }) : MetaData.new()
    this.setState({ isPortland, metaData: metadData }, () => this.fetchStoryInfo())
  }

  // #region Collections

  fetchCollections = async () => {
    const exhibitId = this.props.app._id
    let collections = await adminApi.collection().getAllForExhibit(exhibitId)
    collections = lodash.orderBy(collections, ['title'], ['asc'])
    this.setState({ collections })
  }

  handleAddCollection = async (newCollectionTitle) => {
    const validator = createCollectionValidator({ title: newCollectionTitle })
    if(!validator.isValid) {
      const message = formatMessages(validator)
      this.props.showMessage(message)
      return
    }

    const newCollection = CollectionClient.new({ title: newCollectionTitle, stories: [this.state.id] })

    const c = this.state.collections.find(c => c.title === newCollection.title)
    if(c) {
      this.props.showMessage('Collection already exists.')
      return
    }

    let collections = [...this.state.collections, { ...newCollection }]
    collections = lodash.orderBy(collections, ['title'], ['asc'])

    this.setState({ collections })

    adminApi.collection().create({ exhibitId: this.props.app._id, collection: newCollection })
      .then(res => {
        res.new = true // In case user deletes collection without saving story
        const collections = lodash.cloneDeep(this.state.collections)
        const index = this.state.collections.findIndex(c => c.title === res.title)
        collections[index] = res

        adminApi.story().addCollections({ storyId: this.state.id, collectionIds: [res.id] })

        this.setState({ collections })
      })

    return newCollection
  }

  handleAddSubCollection = async (collectionTitle, newSubCollectionTitle) => {
    let collections = lodash.cloneDeep(this.state.collections)
    const collection = collections.find(c => c.title === collectionTitle)
    const collectionId = collection.id
    if(!collectionId) { // Collection might not have saved in DB
      this.props.showMessage('Error adding sub collection. Try again later')
      return
    }

    const params = {
      parentCollection: collectionId,
      title: newSubCollectionTitle,
      stories: [this.state.id]
    }

    const newSubCollection = SubCollectionClient.new(params)
    const validator = createSubCollectionValidator(newSubCollection)
    if(!validator.isValid) {
      const message = formatMessages(validator)
      this.props.showMessage(message)
      return
    }

    const findSub = collection.subCollections.find(sub => sub.title === newSubCollection.title)
    if(findSub) {
      this.props.showMessage('Subcollection already exists.')
      return
    }

    // Allows user to enter multiple sub collections for a collection
    if(!collection.stories.includes(this.state.id)) collection.stories.push(this.state.id)

    newSubCollection.new = true
    collection.subCollections.push(newSubCollection)

    collections = lodash.orderBy(collections, ['title'], ['asc'])
    this.setState({ collections })

    adminApi.collection().save([collection])
  }

  addCollectionWithCollectionId = async (collectionId) => {
    let collections = lodash.cloneDeep(this.state.collections)
    const selectedCollection = collections.find(c => c.id === collectionId)
    selectedCollection.stories.push(this.state.id)

    await adminApi.collection().save(collections)
    adminApi.story().addCollections({ storyId: this.state.id, collectionIds: [collectionId] })

    collections = lodash.orderBy(collections, ['title'], ['asc'])

    this.setState({ collections })
  }

  addCollectionWithCollectionIdAndSubCollectionId = async (collectionId, subCollectionId) => {

    let collections = lodash.cloneDeep(this.state.collections)
    const collection = collections.find(c => c.id === collectionId)
    collection.stories.push(this.state.id)

    const sub = collection.subCollections.find(sub => sub._id === subCollectionId)
    sub.stories.push(this.state.id)

    adminApi.collection().save([collection])
    adminApi.story().addCollections({ storyId: this.state.id, collectionIds: [collectionId] })

    collections = lodash.orderBy(collections, ['title'], ['asc'])
    this.setState({ collections })
  }

  handleRemoveStoryFromSubCollection = async (collectionId, subCollectionId) => {
    const collections = lodash.cloneDeep(this.state.collections)
    const collection = collections.find(c => c.id === collectionId)
    const sub = collection.subCollections.find(sub => sub._id === subCollectionId)

    // Remove entire subCollection from list
    if(sub.new) {
      const subIndex = collection.subCollections.findIndex(sub => sub._id === subCollectionId)
      collection.subCollections.splice(subIndex, 1)

      // Remove from DB
      await adminApi.subCollection().delete(sub._id)
    } else { // Remove story from subCollections for already existing subCollection
      const index = sub.stories?.findIndex(s => s === this.state.id)
      sub.stories.splice(index, 1)
    }

    adminApi.collection().save([collection])

    this.setState({ collections })
  }

  /***
   * Removes story from collection
   */
  handleDeleteCollection = async (collectionId) => {
    const collections = lodash.cloneDeep(this.state.collections)
    const collection = collections.find(c => c.id === collectionId)

    if(collection.new) { // Delete "dangling" collection data
      await adminApi.collection().delete({ collectionId, exhibitId: this.props.app._id })
      const collectionIndex = collections.findIndex(c => c.id === collectionId)
      collections.splice(collectionIndex, 1)

      // Delete new subCollections since new collection is being deleted
      // All sub collections are presumed to be new
      const deleteOperations = collection.subCollections.map(sub => adminApi.subCollection().delete(sub._id))
      await Promise.all(deleteOperations)

    } else {

      const storyIndex = collection.stories.findIndex(storyId => storyId === this.state.id)

      // Remove story from collection
      collection.stories.splice(storyIndex, 1)

      // Remove new sub collections
      const newSubCollections = collection.subCollections.filter(sub => sub.new)
      const deleteOperations = newSubCollections.map(sub => adminApi.subCollection().delete(sub._id))


      // Remove story Id from existing subCollections
      const existingSubCollections = collection.subCollections.filter(sub => !sub.new)
      for(const subCollection of existingSubCollections) {
        const idx = subCollection.stories.findIndex(sId => sId === this.state.id)
        if (idx !== -1) subCollection.stories.splice(idx, 1)
      }

      // Update collections
      const updateCollectionsOperation = adminApi.collection().save(collections)
      await Promise.all([deleteOperations, updateCollectionsOperation].flat())
    }

    adminApi.story().removeCollections({ storyId: this.state.id, collectionIds: [collectionId] })

    this.setState({ collections })
  }

  saveCollectionsState = async (storyId) => {
    if (!storyId) {
      console.error('missing story id')
      return
    }

    // find only collections that include this story
    const collections = this.state.collections.filter(c => c.stories.includes(this.state.id))
    await adminApi.collection().save(collections)
  }

  // #endregion

  fetchStoryInfo = async () => {
    const story = await adminApi.story().getById(this.state.id)
    if(!story) {
      console.error('failed to fetch story info', story)
      return
    }

    const openMetaData = adminApi.metaData().hasMetaData(story.metaData)

    this.setState({ openMetaData, metaData: story.metaData || this.state.isPortland ? MetaData.new({ isPortland: true }) : MetaData.new() })

    const { assets } = story
    const collections = this.state.collections.length > 0 ? this.state.collections : []

    this.setState({
      ...story,
      assets,
      tags: [],
      collections
    })
  }

  resetState = async () => {
    await this.setState(lodash.cloneDeep(initialState))

    // TODO: move this to initialState
    await this.setState({ collections: [] })
  }

  /**
   * Perform cleanup
   * Delete "dangling" story in case user cancels story creation
   * Delete new collections since story has not been saved
   */
  cleanUp = () => {
    if(this.isNewStory()) adminApi.story().delete(this.state.id)
    this.deleteNewCollections()
  }

  deleteNewCollections = () => {
    const deleteOperations = this.state.collections
      .filter(c => c.new)
      .map(c => adminApi.collection().delete({ collectionId: c.id, exhibitId: this.props.app._id }))

    Promise.all(deleteOperations)
  }

  handleClose = async () => {
    this.cleanUp()
    this.resetState()
    this.props.handleClose()
  }

  handleChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    switch (id) {
      case 'story-title':
        this.setState({ title: value })
        break
      case 'story-short-desc':
        this.setState({ description: value })
        break
      case 'tags':
        this.setState({ tag: value })
        break
      case 'date':
        this.setState({ date: value })
        break
      case 'start-year':
        this.setState({ startYear: value },
          () => {
            if (!yearValid(this.state.startYear)) {
              this.setState({ endMonth: undefined, endYear: undefined })
            }
          }
        )

        break
      case 'start-month':
        this.setState({ startMonth: value })
        break
      case 'start-day':
        this.setState({ startDay: value })
        break
      case 'end-year':
        this.setState({ endYear: value })
        break
      case 'end-month':
        this.setState({ endMonth: value })
        break
      case 'end-day':
        this.setState({ endDay: value })
        break
      case 'featured':
        this.setState({ featured: e.target.checked })
        break
      case 'locationType':
        this.setState({ locationType: value })
        break
      case 'coords':
        this.setState({ coords: value })
        break
      default:
        console.error('should not be here')
    }
  }

  validateSaveStory = (story) => {
    story = castDates(story)
    const sv = this.isNewStory() ? StoryValidator(story) : updateStoryValidator(story)

    // let valid = true
    // let message = ''
    // if (invalidLocation(this.state.locationType, this.state.coords)) {
    //   valid = false
    //   message = 'Please provide a valid location'
    // } else if(!sv.isValid) {
    //   valid = false
    //   message = formatMessages(sv)
    // }

    let valid = true
    let message = ''
    if (!sv.isValid) {
      valid = false
      message = sv.messages[0].message
    }

    return { valid, message }
  }

  reverseGeoCode = async () => {
    const coords = this.state.coords.split(',')
    const [latString, lngString] = coords
    const res = await Geocode.fromLatLng(latString.trim(), lngString.trim())
    const results = res.results[0]
    const locationName = results.formatted_address
    const { lat, lng } = results.geometry.location

    return { locationName, lat, lng }
  }

  waitForAssetUploads = async () => {
    let uploadPercent = 0
    this.setState({ uploadPercent })

    while(this.props.uploadCount != 0) {
      uploadPercent = (uploadPercent + 5) % 100
      await this.setState({ uploadPercent })
      await sleep(500)
    }

    await this.props.resetUpload()
  }

  isNewStory = () => {
    return this.props.story === undefined
  }

  handleSave = async () => {
    const annotations = this.state.assets
      .filter(a => a.annotations)
      .map(a => a.annotations).flat()

    const annotationsValid = validateAnnotations(annotations)
    if(!annotationsValid) {
      this.props.showMessage('Error saving annotation(s). Try again later')
      return
    }

    const assets = this.state.assets

    for(const asset of assets) {
      const validator = assetValidator(asset)
      if(!validator.isValid) {
        this.props.showMessage(formatMessages(validator))
        return
      }
    }

    const startTime = performance.now()

    await Promise.all(assets.map(asset => adminApi.asset().save(asset)))


    const { id, title, status, featured, storyTemplate, description,
      startYear, startMonth, startDay, endYear, endMonth, endDay } = this.state

    let story = {
      id,
      title: title.trim(),
      status,
      storyType: 'story',
      featured,
      storyTemplate,
      description,
      assets,
      startYear, startMonth, startDay,
      endYear, endMonth, endDay
    }

    story.app = Util.getAppId(this.props)
    story.rank = this.isNewStory() ? adminApi.rank().generateAppend({ stories: this.props.stories, story }) : this.props.story.rank
    story.metaData = this.state.metaData

    const { valid, message } = this.validateSaveStory(story)
    if (!valid) {
      this.props.showMessage(message)
      return
    }

    // In case user enters coords as lat, lng
    if (this.state.locationType === 'coordinates' && this.state.coords) {
      const { locationName, lat, lng } = await this.reverseGeoCode()
      story.locationName = locationName
      story.location = {}
      story.location.lat = lat
      story.location.lng = lng
    } else if (this.state.locationType === 'name') {// If user selects place from dropdown
      story.locationName = this.state.locationName
      story.location = {}
      story.location.lat = this.state.location.lat
      story.location.lng = this.state.location.lng
    }

    this.setState({ saving: true })
    this.props.toggleProgress()
    await this.waitForAssetUploads()
    console.log('sleep edit', this.props.uploadCount)

    story = await adminApi.story().save(story)
    if(!story) {
      this.setState({ saving: false })
      this.props.showMessage('Error saving story. Try again later')
      return
    }

    if(this.isNewStory()) {
      if(!this.props.initializedFromAssetsPage) {
        this.props.newStory(story)
      }
    } else {
      this.props.editedStory(story)
    }

    const endTime = performance.now()

    logInfo(`Time elasped saving story in seconds: ${((endTime - startTime) / 1000).toFixed(2)} for story: ${this.state.id}`)

    this.props.handleClose()
    this.props.toggleProgress()
    this.props.showMessage('Story saved')
    await this.resetState()
  }

  handleKeyDown = (e) => {
    // Enter key
    if(e.keyCode !== 13) return

    const tagNotEmpty = this.tagInputValid()
    if(!tagNotEmpty) {
      console.error('tag empty')
      return
    }

    const newTag = { value: this.state.tag }

    this.setState({ tags: [...this.state.tags, newTag], tag: '' })
  }

  tagInputValid = () => {
    return this.state.tag !== ''
  }

  handleDeleteTag = (tag) => () => {
    const tags = [...this.state.tags]
    const chipToDelete = tags.indexOf(tag)
    tags.splice(chipToDelete, 1)

    this.setState({ tags })
  }

  handlePickPhoto = () => {
    this.pickPhotoRef.current.click()
  }

  handlePickVideo = () => {
    this.pickVideoRef.current.click()
  }

  handlePickYoutubeVideo = () => {
    this.setState({ openAddYoutubeLinkDialog: true })
  }

  handlePickAudio = () => {
    this.pickAudioRef.current.click()
  }

  handlePickPDF = () => {
    this.pickPDFRef.current.click()
  }

  getFileType = (id) => {
    let type
    switch (id) {
      case 'pickPhoto':
        type = 'IMAGE'
        break
      case 'pickVideo':
        type = 'VIDEO'
        break
      case 'pickAudio':
        type = 'AUDIO'
        break
      case 'pickPDF':
        type = 'PDF'
        break
      default:
        type = ''
        console.error('error chooser')
    }

    return type
  }

  uploadAssets = async (newAssets, files) => {

    this.props.startUpload()

    const newAssetPromises = newAssets.map((newAsset, index) => {
      const file = files ? files[index] : undefined
      return adminApi.asset().save(newAsset, file)
    })

    const results = await Promise.all(newAssetPromises)
    const errors = results.filter(res => res.error !== '')

    if(errors.length > 0) {
      const message = errors[0].error
      this.props.showMessage(message)
    }

    newAssets = results
      .filter(res => !res.error)
      .map(({ asset }) => asset)


    this.setState({ assets: [...this.state.assets, ...newAssets] })

    this.props.finishUpload()
  }

  handleReceiveFile = async (e) => {
    const orgId = this.props.orgId

    const text = ''
    const newAssets =
      Array.from(e.target.files)
        .map(file => adminApi.asset().newAsset({ orgId, text, file, _window: window }))

    const invalidMessages = newAssets
      .map(asset => assetValidator(asset))
      .filter(v => !v.isValid)
      .map(v => v.messages)
      .flat()

    if(invalidMessages.length > 0) {
      const message = invalidMessages[0].message
      this.props.showMessage(message)
      return
    }

    if(newAssets.length == 0) {
      console.log('no new assets to upload')
      return
    }

    await this.uploadAssets(newAssets, e.target.files)
  }

  handleDeleteFile = (obj) => () => {
    const assetsCopy = [...this.state.assets]
    const assetToDeleteIndex = assetsCopy.indexOf(obj)
    assetsCopy.splice(assetToDeleteIndex, 1)
    this.setState({ assets: assetsCopy })
  }

  handleUploadItemClick = (asset) => {
    this.setState({ previewAsset: asset, openAssetPreviewDialog: true })
  }

  handleUploadItemDownload = (asset) => {
    window.open(asset.link)
  }

  handleCloseAssetPreviewDialog = () => {
    this.setState({ openAssetPreviewDialog: false, previewAsset: {} })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const assetsCopy = lodash.cloneDeep(this.state.assets)
    const moved = arrayMove(assetsCopy, oldIndex, newIndex)
    this.setState({ assets: moved })
  }

  handleChangeAsset = (assetParam) => (e) => {
    const id = e.target.id
    const value = e.target.value

    const assetsCopy = [...this.state.assets]
    const asset = assetsCopy.find(a => a === assetParam)

    switch (id) {
      case 'caption':
        asset.caption = value
        break
      default:
        console.error('error')
    }

    this.setState({ assets: assetsCopy })
  }

  /**
  * Called when user clicks away from caption box
  * Saves the new edited or new caption
  * Returns if caption is empty
  * @param {String} assetId Asset ID for Story asset being edited
  * @returns
  */
  handleOnBlur = (assetId) => async (e) => {
    const value = e.target.value
    if(!value) return

    const assetsCopy = [...this.state.assets]
    const asset = assetsCopy.find(asset => asset.id === assetId)
    asset.caption = value

    this.setState({ assets: assetsCopy })
  }


  onReceivedDropboxFiles = (files) => {
    const arr = []

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      arr.push({
        orgId: this.props.orgId,
        file: file,
        type: Util.getFileType(file.link),
        link: file.link,
        thumbnail: file.thumbnailLink,
        status: 'UPLOAD',
        subType: 'DROPBOX',
        name: file.name,
        caption: ''
      })
    }

    this.setState({ assets: [...this.state.assets, ...arr] })
  }

  handleToggleMetaData = () => {
    this.setState({ openMetaData: !this.state.openMetaData })
  }

  handleStatusChange = (e) => {
    const value = e.target.value
    this.setState({ status: value })
  }

  handleMetaDataChange = (e) => {
    const id = e.target.id
    const value = e.target.value

    switch (id) {
      case 'contributor':
        this.setState({ metaData: { ...this.state.metaData, contributor: value } })
        break
      case 'creator':
        this.setState({ metaData: { ...this.state.metaData, creator: value } })
        break
      case 'description':
        this.setState({ metaData: { ...this.state.metaData, description: value } })
        break
      case 'format':
        this.setState({ metaData: { ...this.state.metaData, format: value } })
        break
      case 'identifier':
        this.setState({ metaData: { ...this.state.metaData, identifier: value } })
        break
      case 'language':
        this.setState({ metaData: { ...this.state.metaData, language: value } })
        break
      case 'publisher':
        this.setState({ metaData: { ...this.state.metaData, publisher: value } })
        break
      case 'relation':
        this.setState({ metaData: { ...this.state.metaData, relation: value } })
        break
      case 'rights':
        this.setState({ metaData: { ...this.state.metaData, rights: value } })
        break
      case 'source':
        this.setState({ metaData: { ...this.state.metaData, source: value } })
        break
      case 'subject':
        this.setState({ metaData: { ...this.state.metaData, subject: value } })
        break
      case 'type':
        this.setState({ metaData: { ...this.state.metaData, type: value } })
        break
      case 'raccNo':
        this.setState({ metaData: { ...this.state.metaData, raccNo: value } })
        break
      case 'artist':
        this.setState({ metaData: { ...this.state.metaData, artist: value } })
        break
      case 'medium':
        this.setState({ metaData: { ...this.state.metaData, medium: value } })
        break
      case 'dimensions':
        this.setState({ metaData: { ...this.state.metaData, dimensions: value } })
        break
      case 'discipline':
        this.setState({ metaData: { ...this.state.metaData, discipline: value } })
        break
      case 'conceptualInfo':
        this.setState({ metaData: { ...this.state.metaData, conceptualInfo: value } })
        break
      case 'displayLocation':
        this.setState({ metaData: { ...this.state.metaData, displayLocation: value } })
        break
      case 'buildingName':
        this.setState({ metaData: { ...this.state.metaData, buildingName: value } })
        break
      case 'bureau':
        this.setState({ metaData: { ...this.state.metaData, bureau: value } })
        break
      case 'room':
        this.setState({ metaData: { ...this.state.metaData, room: value } })
        break
      case 'street':
        this.setState({ metaData: { ...this.state.metaData, street: value } })
        break
      case 'city':
        this.setState({ metaData: { ...this.state.metaData, city: value } })
        break
      case 'state':
        this.setState({ metaData: { ...this.state.metaData, state: value } })
        break
      case 'zip':
        this.setState({ metaData: { ...this.state.metaData, zip: value } })
        break
      default:
        console.error('should not be here')
    }
  }

  handleLocationSelect = (suggest) => {
    if (suggest === undefined) {
      this.setState({
        mapCenter: DEFAULT_MAP_CENTER,
        locationName: '',
        location: {
          lat: null,
          lng: null,
        },
        zoom: 4,
      })
      return
    }

    this.setState({
      location: {
        lat: suggest.location.lat,
        lng: suggest.location.lng,
      },
      mapCenter: {
        lat: suggest.location.lat,
        lng: suggest.location.lng,
      },
      zoom: 10,
      locationName: suggest.label,
    })
  };

  handleStoryTemplateChange = (e) => {
    const value = e.target.value
    this.setState({ storyTemplate: value })
  }

  handleDelete = async () => {

    const storyId = this.state.id

    this.setState({ openDeleteDialog: false })
    this.props.toggleProgress()

    // Delete collections before resetting state
    this.deleteNewCollections()

    this.resetState()
    this.props.handleClose()

    const { error, deleted }  = await adminApi.story().delete(storyId)
    if(!deleted){
      console.error(error)
      return
    }

    this.props.storyDeleted(storyId)
  }


  handleEnterText = () => {
    const newAsset = adminApi.asset().newAsset({ orgId: this.props.orgId, text: '', type: 'TEXT' })
    this.setState({
      assets: [
        ...this.state.assets,
        newAsset,
      ],
    })
  }

  handleTextChange = async (index, newText) => {
    const assetsCopy = [...this.state.assets]
    const asset = assetsCopy[index]
    asset.text = newText

    const { asset: savedAsset } = await adminApi.asset().save(asset)
    assetsCopy[index] = savedAsset

    this.setState({ assets: assetsCopy })
  }

  handleEnhancePic = (passedAssetId) => () => {
    const asset = this.state.assets.find(a => a.id === passedAssetId)
    const copy = { ...asset }
    this.setState({ openEnhancePicDialog: true, picToEnhance: copy })
  }


  handleSaveAnnotations = (assetId, annotations) => {
    const copy = lodash.cloneDeep(this.state.assets)
    const asset = copy.find(a => a.id === assetId)
    asset.annotations = annotations

    this.setState({
      picToEnhance: undefined,
      openEnhancePicDialog: false,
      assets: copy
    })
  }

  handleCloseEnhanceDialog = (assetId, annotations) => {
    const copy = lodash.cloneDeep(this.state.assets)
    const asset = copy.find(a => a.id === assetId)
    asset.annotations = annotations

    this.setState({
      openEnhancePicDialog: false,
      picToEnhance: undefined,
      assets: copy
    })
  }

  handleGeoSuggestChange = (value) => {
    this.setState({ locationName: value })
  }

  handleChangeAddDate = () => {
    this.setState(
      (prevState) => ({ addDate: !prevState.addDate }),
      () => {
        // reset date state
        if (!this.state.addDate) {
          this.setState({
            startYear: '',
            startMonth: '',
            startDay: '',
            endYear: '',
            endMonth: '',
            endDay: '',
          })
        }
      }
    )
  }

  handleCloseAddYoutubeLinkDialog = () => {
    this.setState({ openAddYoutubeLinkDialog: false })
  }

  handleSaveYoutubeLink = async (youtubeLink) => {
    this.handleCloseAddYoutubeLinkDialog()

    const params = {
      orgId: this.props.orgId,
      link: youtubeLink,
      thumbnail: `https://img.youtube.com/vi/${Util.getYouTubeIdFromUrl(youtubeLink)}/hqdefault.jpg`,
      type: 'YOUTUBE',
      caption: '',
      _window: window,
    }

    let newYoutubeAsset = adminApi.asset().newAsset(params)
    const validator = assetValidator(newYoutubeAsset)
    if(!validator.isValid) {
      this.props.showMessage(formatMessages(validator))
      return
    }

    const res = await adminApi.asset().save(newYoutubeAsset)
    if(res.error) {
      this.props.showMessage(res.error)
      return
    }

    newYoutubeAsset = res.asset

    this.setState({
      assets: [
        ...this.state.assets,
        newYoutubeAsset
      ],
    })
  }

  handleCloseSearchAssetDialog = () => {
    this.setState({ openSearchAssetDialog: false })
  }

  handleOpenSearchAssetDialog = () => {
    this.setState({ openSearchAssetDialog: true })
  }

  handleViewAsset = (asset) => {
    this.setState({ assetToEdit: asset, openEditAssetDialog: true })
  }

  handleCloseEditAssetDialog = () => {
    this.setState({ assetToEdit: undefined, openEditAssetDialog: false })
  }

  handleAddAssets = async (assetsToAdd) => {

    const assetsNotAlreadyAdded = assetsToAdd
      .filter(asset => !this.state.assets.find(a => a.id === asset._id))
      .map(asset => {
        asset.id = asset._id
        delete asset._id

        return asset
      })

    if(assetsNotAlreadyAdded.length == 0) {
      this.props.showMessage('No new asset to add')
      return
    }

    await this.uploadAssets(assetsNotAlreadyAdded)

    if(assetsNotAlreadyAdded.length < assetsToAdd.length) {
      this.props.showMessage(`Added ${assetsNotAlreadyAdded.length} new asset(s).`)
    }
  }

  render() {
    const { classes, open, theme } = this.props

    return (
      <Dialog
        data-testid='AddOrEditStoryDialog'
        fullScreen
        open={open}
        TransitionComponent={Transition}
        TransitionProps={{
          onEntered: () => this.onEntered()
        }}
        onClose={this.handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color='inherit'
              onClick={this.handleClose}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' className={classes.flex}>
              {this.isNewStory() ? 'Create Story' : 'Edit Story'}
            </Typography>
          </Toolbar>
        </AppBar>

        <div
          className={clsx('buildstorytop', 'title', 'white', 'tc')}
          style={{ backgroundColor: theme.palette.primary.main }}>
          Tell your story
        </div>
        <div style={{ width: '80%', margin: 'auto' }}>
          <form style={{ margin: 16 }}>
            <FormControl margin='normal' fullWidth>
              <InputLabel htmlFor='story-title'>
                Give your story a title
              </InputLabel>
              <Input
                id='story-title'
                type='text'
                classes={{ input: classes.cssOverrides }}
                inputProps={{ maxLength: 150 }}
                multiline
                onChange={this.handleChange}
                value={this.state.title}
              />
            </FormControl>
          </form>
          <div
            title='Drag to sort your story items.'
            style={{
              display: this.state.assets.length === 0 ? 'none' : 'block',
            }}
          >
            <SortableContainer useDragHandle onSortEnd={this.onSortEnd}>
              {this.state.assets.map((asset, index) => {
                return (
                  <SortableItem
                    key={index}
                    index={index}
                    assetIndex={index}
                    asset={asset}
                    enhancePic={this.handleEnhancePic}
                    handleTextChange={this.handleTextChange}
                    handleChange={this.handleChangeAsset}
                    handleOnBlur={this.handleOnBlur}
                    handleDelete={this.handleDeleteFile}
                    click={this.handleUploadItemClick}
                    download={this.handleUploadItemDownload}
                  />
                )
              })}
            </SortableContainer>
          </div>

          <div className='grayblock'>
            <div>
              <div className={clsx('animated', 'fadeIn', classes.uploadButton)}>
                <input
                  ref={this.pickPhotoRef}
                  id='pickPhoto'
                  style={{ display: 'none' }}
                  type='file'
                  multiple
                  accept={Util.allowable_image_types()}
                  onChange={this.handleReceiveFile}
                  onClick={e => e.target.value = null}
                />

                <input
                  ref={this.pickVideoRef}
                  id='pickVideo'
                  style={{ display: 'none' }}
                  type='file'
                  multiple
                  accept={Util.allowable_video_types()}
                  onChange={this.handleReceiveFile}
                  onClick={e => e.target.value = null}
                />

                <input
                  ref={this.pickAudioRef}
                  id='pickAudio'
                  style={{ display: 'none' }}
                  type='file'
                  accept={Util.allowable_audio_types()}
                  multiple
                  onChange={this.handleReceiveFile}
                  onClick={e => e.target.value = null}
                />

                <input
                  ref={this.pickPDFRef}
                  id='pickPDF'
                  style={{ display: 'none' }}
                  type='file'
                  accept='application/pdf'
                  multiple
                  onChange={this.handleReceiveFile}
                  onClick={e => e.target.value = null}
                />

                <div
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${StoryUploadPhoto})center center/92% no-repeat` }}
                  onClick={this.handlePickPhoto}
                />

                <div
                  onClick={this.handlePickVideo}
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${StoryUploadVideo})center center/92% no-repeat` }}
                />

                <div
                  data-testid='youtube-upload-button'
                  onClick={this.handlePickYoutubeVideo}
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${StoryUploadYoutubeVideo})center center/92% no-repeat` }}
                />

                <DropboxChooser
                  appKey={'tzs7m4lywzjdnbu'}
                  linkType={'direct'}
                  success={(files) => this.onReceivedDropboxFiles(files)}
                  cancel={() => this.onCancel()}
                  multiselect={true}
                  extensions={['.jpg', '.jpeg', '.png', '.mp4']}
                >
                  <div
                    className={clsx('newuploadbutton', classes.floatCssOverride)}
                    style={{
                      background: `url(${StoryUploadDropbox})center center/92% no-repeat`,
                    }}
                  />
                </DropboxChooser>

                <div
                  data-testid='text-upload-icon'
                  onClick={this.handleEnterText}
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{
                    background: `url(${StoryUploadText})center center/92% no-repeat`,
                  }}
                />

                <div
                  onClick={this.handlePickAudio}
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${StoryUploadAudio})center center/92% no-repeat` }}
                />

                <div
                  onClick={this.handlePickPDF}
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${StoryUploadPDF})center center/92% no-repeat` }}
                />

                <div
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${IconAdInlineDisplay})center center/92% no-repeat` }}
                  onClick={this.handlePickPhoto}
                />

                <div
                  className={clsx('newuploadbutton', classes.floatCssOverride)}
                  style={{ background: `url(${IconAdTopBanner})center center/92% no-repeat` }}
                  onClick={this.handlePickPhoto}
                />
              </div>

              <div
                className={clsx('category_button', 'bluebg', 'white', 'bold', 'tc')}
                style={{ width: '40%', margin: '10px 30%' }}
                onClick={this.handleOpenSearchAssetDialog}
              >
                Search for an asset
              </div>
              <div id='searchresults' className={clsx('whitebg', 'assetsearch')}></div>
            </div>
          </div>

          <form style={{ margin: 16 }}>
            <FormControl
              margin='normal'
              style={{ display: 'flex', width: 200 }}
            >
              <TextField
                id='select-story-template'
                select
                label='Story Template'
                value={this.state.storyTemplate}
                onChange={this.handleStoryTemplateChange}
                margin='normal'
              >
                {storyTemplates.map((storyTemplate) => (
                  <MenuItem key={storyTemplate} value={storyTemplate}>
                    {storyTemplate}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl margin='normal' fullWidth>
              <InputLabel htmlFor='story-short-desc'>
                Story short description
              </InputLabel>
              <Input
                id='story-short-desc'
                type='text'
                classes={{ input: classes.cssOverrides }}
                onChange={this.handleChange}
                value={this.state.description}
              />
            </FormControl>
            <div>
              <Switch
                checked={this.state.addDate}
                label={'Add Date?'}
                change={this.handleChangeAddDate}
              />

              <div style={{ display: this.state.addDate ? 'block' : 'none' }}>
                <OldFormTitle text={'When did this story happen?'} />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ minWidth: 85 }}>Start Date:</div>
                  <TextField
                    style={{
                      margin: 16,
                      width: 'fit-content',
                    }}
                    id='start-year'
                    type='number'
                    placeholder={'year'}
                    value={this.state.startYear}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    style={{
                      margin: 16,
                      width: 'fit-content',
                      display: yearValid(this.state.startYear) ? 'block' : 'none',
                    }}
                    id='start-month'
                    type='number'
                    placeholder={'month'}
                    value={this.state.startMonth}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    style={{
                      margin: 16,
                      width: 'fit-content',
                      display: monthValid(this.state.startMonth) ? 'block' : 'none',
                    }}
                    id='start-day'
                    type='number'
                    placeholder={'day'}
                    value={this.state.startDay}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div
                  style={{
                    display: yearValid(this.state.startYear) ? 'flex' : 'none',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ minWidth: 85 }}>End Date:</div>
                  <TextField
                    style={{
                      margin: 16,
                      width: 'fit-content',
                    }}
                    id='end-year'
                    type='number'
                    placeholder={'year'}
                    value={this.state.endYear}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    style={{
                      margin: 16,
                      width: 'fit-content',
                      display: yearValid(this.state.endYear) ? 'block' : 'none',
                    }}
                    id='end-month'
                    type='number'
                    placeholder={'month'}
                    value={this.state.endMonth}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    style={{
                      margin: 16,
                      width: 'fit-content',
                      display: monthValid(this.state.endMonth) ? 'block' : 'none',
                    }}
                    id='end-day'
                    type='number'
                    placeholder={'day'}
                    value={this.state.endDay}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>

            <GrayBlock>
              <FormRow>
                <OldFormTitle text={'Enter Location:'} />
                <div style={{ display: 'flex', marginBottom: 10 }}>
                  <select
                    id='locationType'
                    value={this.state.locationType}
                    onChange={this.handleChange}
                    style={{
                      width: 100,
                      paddingRight: 0,
                      borderRadius: '30px 0 0 30px',
                      background: `url(${GrayArrow})center center/contain no-repeat`,
                      backgroundPosition: 'right',
                      backgroundSize: 35,
                    }}
                  >
                    <option value='name'>Name</option>
                    <option value='coordinates'>Coords</option>
                  </select>

                  {this.state.locationType === 'name' && (
                    <Geosuggest
                      onSuggestSelect={this.handleLocationSelect}
                      onChange={this.handleGeoSuggestChange}
                      initialValue={ this.state.locationName ?? undefined }
                      style={{
                        input: {
                          borderRadius: '0 30px 30px 0',
                          flexGrow: 1,
                        },
                      }}
                      placeholder={'Search for a location by name'}
                    />
                  )}

                  {this.state.locationType === 'coordinates' && (
                    <input
                      id='coords'
                      type='text'
                      style={{ borderRadius: '0 30px 30px 0', flexGrow: 1 }}
                      placeholder={'Lat, Lng'}
                      onChange={this.handleChange}
                      value={this.state.coords}
                    />
                  )}
                </div>
              </FormRow>

              <MapContainer
                mapCenter={this.state.mapCenter}
                center={this.state.location}
                zoom={this.state.zoom}
                app={this.props.app}
              />
            </GrayBlock>

            <FormControl margin='normal'>
              <InputLabel htmlFor='tags'>Story tags</InputLabel>
              <Input
                id='tags'
                type='text'
                classes={{ input: classes.cssOverrides }}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                value={this.state.tag}
              />
              <FormHelperText>Press Enter to select tag</FormHelperText>
            </FormControl>

            <div margin='normal' id='tags-array'>
              {this.state.tags.map((tag, index) => (
                <StoryTag
                  key={index}
                  data={tag}
                  handleDelete={this.handleDeleteTag}
                />
              ))}
            </div>

            {/* # region - collections section */}

            {/* Do not show collections if coming from assets page */}
            {!this.props.initializedFromAssetsPage && (
              <CollectionsSection
                app={this.props.app}
                storyId={this.state.id}
                story={this.state.story}
                collections={this.state.collections}
                addCollection={this.handleAddCollection}
                addSubCollection={this.handleAddSubCollection}
                deleteCollection={this.handleDeleteCollection}
                addCollectionWithCollectionId={this.addCollectionWithCollectionId}
                addCollectionWithCollectionIdAndSubCollectionId={
                  this.addCollectionWithCollectionIdAndSubCollectionId
                }
                removeStoryFromSubCollection={this.handleRemoveStoryFromSubCollection}
              />
            )}

            {/* # endregion */}

            <FormControl
              margin='normal'
              style={{ display: 'flex', width: 200 }}
            >
              <TextField
                fullWidth={false}
                id='select-status'
                select
                label='Status'
                value={this.state.status}
                onChange={this.handleStatusChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'>
                {Object.values(statuses).map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormControl>

            <FormControl margin='normal'>
              <Button
                color='primary'
                onClick={this.handleToggleMetaData}
                className={classes.addMetaDataBtn}
              >
                + Add Meta Data
              </Button>
            </FormControl>

            <MetaDataComponent
              isPortland={this.state.isPortland}
              metaData={this.state.metaData}
              open={this.state.openMetaData}
              handleChange={this.handleMetaDataChange}
            />

            <FormControlLabel
              control={
                <Checkbox
                  id='featured'
                  checked={this.state.featured}
                  onChange={this.handleChange}
                  value={'featured'}
                  color='primary'
                />
              }
              label='Mark As Featured'
            />

            <div style={{ display: 'flex' }}>
              <Button
                className={classes.button}
                style={{
                  display: this.props.story ? 'block' : 'none',
                }}
                variant='outlined'
                color='secondary'
                onClick={() => this.setState({ openDeleteDialog: true })}
                disabled={this.state.saving}
              >
                Delete
              </Button>
              <div style={{ marginLeft: 'auto' }}>
                <Button
                  data-testid='cancel'
                  className={classes.button}
                  variant='outlined'
                  color='secondary'
                  onClick={this.handleClose}
                  disabled={this.state.saving}
                >
                  Cancel
                </Button>
                <Button
                  data-testid='save'
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  disabled={this.state.saving}
                  onClick={this.handleSave}>
                  Save Story
                </Button>
              </div>
            </div>
          </form>
        </div>

        <DeleteStoryDialog
          open={this.state.openDeleteDialog}
          close={() => this.setState({ openDeleteDialog: false })}
          handleDelete={this.handleDelete}
        />

        <EnhancePicDialog
          open={this.state.openEnhancePicDialog}
          asset={this.state.picToEnhance}
          save={this.handleSaveAnnotations}
          close={this.handleCloseEnhanceDialog}
        />

        <AddYoutubeLink
          open={this.state.openAddYoutubeLinkDialog}
          close={this.handleCloseAddYoutubeLinkDialog}
          save={this.handleSaveYoutubeLink}
          showMessage={this.props.showMessage}
        />

        {this.state.uploadPercent > 0 && (
          <UploadProgress percentCompleted={this.state.uploadPercent} />
        )}

        <AssetPreviewDialog
          open={this.state.openAssetPreviewDialog}
          close={this.handleCloseAssetPreviewDialog}
          asset={this.state.previewAsset}
        />

        <SearchForAssetDialog
          open={this.state.openSearchAssetDialog}
          orgId={this.props.orgId}
          close={this.handleCloseSearchAssetDialog}
          viewAsset={this.handleViewAsset}
          addAssets={this.handleAddAssets}
        />

        <EditAssetDialog
          open={this.state.openEditAssetDialog}
          close={this.handleCloseEditAssetDialog}
          asset={this.state.assetToEdit}
          allowDelete={false}
          showProgress={this.props.toggleProgress}
          hideProgress={this.props.toggleProgress}
          showMessage={this.props.showMessage}
        />
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AddOrEditStoryDialog))
