import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormRow from '../UI/form/FormRow'
import VaultImage from '../../images/vault.png'
import adminApi from '../../store/exhibitAdminApi'
import Asset from '../asset/Asset'
import SmallTitle from '../UI/SmallTitle'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  searchFormRow: {
    margin: '10px 20%',
    width: '60%',
  },
  searchInput: {
    width: '100%',
    paddingLeft: '50px !important',
    background: `url(${VaultImage})10px center/30px no-repeat #fff`,
  },
  assetSearch: {
    width: '100%',
    position: 'absolute',
    top: '100%',
    padding: 10,
    borderRadius: 8,
    boxShadow: '0 0 10px rgba(0,0,0,.6)',
    zIndex: 2,
    left: 0,
  },
  noResults: {
    width: '100%',
    marginTop: 100,
  },
})

export default function SearchForAssetDialog(props) {
  const classes = useStyles()

  const { open, orgId, viewAsset, addAssets, close } = props

  const [value, setValue] = useState('')
  const [assets, setAssets] = useState([])
  const [userSearched, setUserSearched] = useState(false)
  const [assetsToAdd, setAssetsToAdd] = useState([])

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleKeyDown = (e) => {
    if(e.key !== 'Enter') return
    search()
  }

  const search = async () => {
    if(!value) return
    const assets = await adminApi.asset().search({ orgId, term: value })

    setAssets(assets)
    setUserSearched(true)
  }

  const handleAssetClicked = (asset) => {
    viewAsset(asset)
  }

  const handleClose = () => {
    resetState()
    close()
  }

  const resetState = () => {
    setAssets([])
    setAssetsToAdd([])
    setUserSearched(false)
    setValue('')
  }

  const handleAddToStory = (assetId) => {
    const assetsCopy = [...assets]
    const asset = assetsCopy.find((asset) => asset._id === assetId)

    if(!asset) {
      console.error('Could not add asset')
      return
    }

    const copy = [...assetsToAdd]
    const index = copy.findIndex((asset) => asset._id === assetId)

    if(index !== -1) {
      copy.splice(index, 1)
      setAssetsToAdd(copy)
      asset.added = false
    } else {
      setAssetsToAdd([...assetsToAdd, { ...asset }])
      asset.added = true
    }

    setAssets(assetsCopy)
  }

  const handleAdd = () => {
    addAssets(assetsToAdd)
    resetState()
    close()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
      <DialogContent>
        <FormRow fullWidth={false} className={classes.searchFormRow}>
          <input
            className={classes.searchInput}
            type="text"
            placeholder="Press Enter to search for an asset"
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown} />
        </FormRow>
        <div>
          {assets.map((asset) => (
            <Asset
              key={asset._id}
              asset={asset}
              creatingStory={true}
              assetClicked={handleAssetClicked}
              addToStory={handleAddToStory} />
          ))}
          {assets.length === 0 && userSearched && (
            <SmallTitle
              text={'No results found'}
              className={clsx([classes.noResults, 'tc'])} />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={assetsToAdd.length === 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
