import React, { useEffect, useState } from 'react'
import TiffImagePlaceholder from '../../images/tiff_placeholder.png'
import ExifOrientationImg from 'react-exif-orientation-img'
import adminApi from '../../store/exhibitAdminApi'


import Wand from '../../images/wand.png'

function ImageUpload(props) {

  const { asset, enhancePic } = props

  const [image, setImage] = useState('')

  useEffect(() => {
    if(asset.isTiffImage) {
      setImage(TiffImagePlaceholder)
    } else {
      setImage(adminApi.image().imageUrl(asset.link, 200, 200))
    }
  }, [asset.link])


  return (

    <div data-testid='imageUpload' style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className='smbutton storyenhance'
        style={{
          background: `url(${Wand})3px center/25px no-repeat #0C3`,
          position: 'unset',
          marginBottom: 10
        }}
        onClick={enhancePic(asset.id)}>Enhance</div>

      <ExifOrientationImg src={image} style={{ width: 100, height: 100, objectFit: 'cover' }} />

    </div>
  )
}

export default ImageUpload
