import React from 'react'
import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles(({
  root: {
    backgroundColor: '#0c3',
    display: props => props.type === 'imageUrl' ? 'block' : 'none',
    color: '#fff',
    textAlign: 'left',
    width: 'max-content',
    padding: '5px 16px 4px 16px',
    borderRadius: '20px',
    position: 'absolute',
    top: '8px',
    left: '8px',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Roboto',
  }
}))

export default function StoryType(props) {

  const { type } = props

  const classes = useStyles({type})

  return (
  <div className={classes.root}>{'Image URL'}</div>
  )
}