import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

export default function CustomSwitch(props){
  const { label, checked, change } = props

  return (
    <FormControlLabel
    control={
      <Switch
      checked={checked}
      onChange={change}
      color="primary" />
    }
    label={label} />
  )
}
