import React from 'react'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'



function PDFUpload(props){

    const { click } = props
    
    return (
        <div onClick={click} 
            style={{
                backgroundImage: `url(${props.thumbnail})`, 
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: 100, 
                height: 100, 
                marginRight: 10,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer'
                }}>
            <PictureAsPdfIcon  style={{width: 80, height: 80}}/>
        </div>
    )
}

export default PDFUpload