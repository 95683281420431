import React, { useState, useEffect } from 'react'
import UploadLogo from '../../images/upload_logo.png'
import AddNewPlatformImage from '../../images/add_new_platform.png'
import PlatformTile from '../UI/PlatformTile'
import { makeStyles } from '@material-ui/core/styles'
import adminApi from '../../store/exhibitAdminApi'


const useStyles = makeStyles({
  platformTileLogo: { // css - platform_tile_logo
    width: '100%',
    paddingTop: '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addNewPlatform: { // css - add_new_platform
    boxShadow: 'inset 0 0 100px #ccc',
    background: `url(${AddNewPlatformImage})center center/60% no-repeat`,
    '&:hover': {
      boxShadow: 'inset 0 0 100px #39f',
      cursor: 'pointer'
    }
  },
  appTile: {
    position: 'relative'
  },
  platformTileName: props => ({ //css  - platform_tile_name
    textAlign: 'center',
    marginTop: 10,
    color: props.app.mainColor
  }),
  smTitle: { // css - smtitle
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#222',
    fontSize: 18,
    lineHeight: 1.2,
    letterSpacing: -.5,
  },
})

function App(props) {
  const { app, addNewClicked } = props

  const classes = useStyles(props)
  const [image, setImage] = useState(UploadLogo)

  useEffect(() => {
    if(!app.image) return
    setImage(adminApi.image().imageUrl(app.image, 200, 200))
  }, [app.image])



  if(app.addNew) {
    return (
      <PlatformTile click={addNewClicked}>
        <div className={`${classes.platformTileLogo} ${classes.addNewPlatform}`} />
      </PlatformTile>
    )
  } else {

    let statusClasses

    if(app.status === 'HIDDEN') {
      statusClasses = ['story_active', 'redbg', 'white', 'normal']
    } else { // Published
      statusClasses = ['story_active', 'greenbg', 'white', 'normal']
    }

    return (
      <PlatformTile className={classes.appTile}>
        <div onClick={props.handleAppClick(app._id)} className={classes.platformTileLogo}
          style={{ background: `url(${image})center center/contain no-repeat #fff` }} />
        <div className={statusClasses.join(' ')}>{app.status}</div>
        <div className={`${classes.platformTileName} ${classes.smTitle}`}>{app.name}</div>
      </PlatformTile>
    )
  }

}

export default App