import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { Input, InputLabel } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import UploadImage from '../util/UploadImage'
import Util, { storiesOrder, mapZooms } from '../../Util'
import ColorPicker from './ColorPicker'
import Switch from '../UI/form/Switch'
import MultilineText from '../UI/form/MultilineText'
import Geosuggest from 'react-geosuggest'
import FormTitle from '../UI/form/FormTitle'
import SquaresTemplateDisplayChooser from './SquaresTemplateDisplayChooser'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'
import { assetValidator, editExhibitValidator, formatMessages } from '@Pass-It-Down/exhibition-admin-client'
import { showMessage } from '../../store/snackBarSlice'
import { updateExhibit } from '../../store/exhibitSlice'
import { loadByOrgId as loadTemplatesByOrg } from '../../store/templateSlice'
import { startUpload, finishUpload, resetUpload } from '../../store/assetsSlice'
import { connect } from 'react-redux'
import adminApi from '../../store/exhibitAdminApi'


const mapStateToProps = (state) => ({
  templates: state.template.all,
  uploadCount: state.assets.uploadCount
})

const mapDispatchToProps = { showMessage, loadTemplatesByOrg, updateExhibit, startUpload, finishUpload, resetUpload }

const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
    marginRight: 50,
  },
  chip: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  cssOverrides: {
    border: 'unset !important',
  },
  input: {
    padding: 10,
  },
  menu: {
    width: 200,
  },
  button: {
    float: 'left',
    marginTop: 15,
    backgroundColor: 'unset',
  },
  buttonSave: {
    float: 'right',
    marginTop: 15,
    backgroundColor: 'unset',
  },
})

const statuses = ['Active', 'Hidden']


function getStatus(status) {
  return status === 'ACTIVE' ? 'Active' : 'Hidden'
}

class EditAppDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      app: {},
      templateName: '',
      viewTagsPublicly: false,
      userGeneratedContentAllowed: false,
      termsOfService: '',
      userGeneratedContentRedirectionLink: '',
      mapCenterAddress: '',
      displayOption: 'cropped'
    }
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.app?._id !== this.props.app?._id) {
      this.setState({
        app: this.props.app,
        templateName: this.props.app.template.name,
        viewTagsPublicly: this.props.app.viewTagsPublicly,
        userGeneratedContentAllowed: this.props.app.userGeneratedContentAllowed,
        termsOfService: this.props.app.userGeneratedContentTermsOfService,
        userGeneratedContentRedirectionLink: this.props.app.userGeneratedContentRedirectionLink,
        mapCenterAddress: this.props.app.mapCenterAddress,
        displayOption: this.props.app.displayOption === 'CROPPED' ? 'cropped' : 'contained'
      })
    }
  }

  async componentDidMount() {
    this.props.loadTemplatesByOrg(this.props.org._id)
  }

  handleChange = (e) => {
    const id = e.target.id
    const value = e.target.value
    const app = { ...this.state.app }

    switch (id) {
      case 'name':
        app.name = value
        break
      case 'description':
        app.description = value
        break
      case 'googleAnalyticsToken':
        app.googleAnalyticsToken = value
        break
      case 'redirect-link':
        app.userGeneratedContentRedirectionLink = value
        break
      default:
        console.error('should not be here')
    }

    this.setState({ app })
  }

  handleMainColorPickerChange = (color) => {
    const app = { ...this.state.app }
    app.mainColor = color.hex
    this.setState({ app })
  }

  handleSecondaryColorPickerChange = (color) => {
    const app = { ...this.state.app }
    app.secondaryColor = color.hex
    this.setState({ app })
  }

  handleSave = async () => {
    const appCopy = { ...this.state.app }

    const data = {
      templateName: this.state.templateName,
      displayOption: this.state.displayOption,
      googleAnalyticsToken: this.state.googleAnalyticsToken,
      viewTagsPublicly: this.state.viewTagsPublicly,
      userGeneratedContentAllowed: this.state.userGeneratedContentAllowed,
      termsOfService: this.state.termsOfService,
    } = this.state

    data.app = appCopy
    data.newTemplate = this.state.templateName !== this.props.app.template.name
    data.userGeneratedContentRedirectionLink = this.state.app.userGeneratedContentRedirectionLink

    const v = editExhibitValidator(data.app)
    if(v.isValid) {
      const updatedApp = await this.props.updateExhibit(data)
      this.props.handleEditedApp(updatedApp)
      this.props.handleClose()
    }else{
      const message = formatMessages(v)
      this.props.showMessage(message)
    }
  }

  handleReceiveImage = async (e) => {

    const orgId = this.props.org._id
    const text = ''

    let newAssets =
      Array.from(e.target.files)
        .map(file => adminApi.asset().newAsset({ orgId, text, file, _window: window }))

    const invalidMessages = newAssets
      .map(asset => assetValidator(asset))
      .filter(v => !v.isValid)
      .map(v => v.messages)
      .flat()

    if(invalidMessages.length > 0) {
      this.props.showMessage(invalidMessages[0].message)
      return
    }

    if(newAssets.length == 0) {
      console.error('no new assets to upload')
      return
    }

    this.props.startUpload()

    const newAssetPromises = newAssets.map((newAsset, index) => {
      const file = e.target.files[index]
      return adminApi.asset().save(newAsset, file)
    })

    const results = await Promise.all(newAssetPromises)
    const errors = results.filter(res => res.error !== '')

    if(errors.length > 0) {
      this.props.showMessage(errors[0])
    }

    newAssets = results
      .filter(res => !res.error)
      .map(({ asset }) => asset)

    const newAsset = newAssets[0]

    const app = { ...this.state.app }
    app.image = newAsset.link

    this.setState({ app })
  }

  handleTemplateChange = (e) => {
    const value = e.target.value
    this.setState({ templateName: value })
  }

  handleStatusChange = (e) => {
    const value = e.target.value
    const appCopy = { ...this.state.app }
    appCopy.status = value.toUpperCase()
    this.setState({ app: appCopy })
  }

  handleStoriesOrderChange = (e) => {
    const appCopy = { ...this.state.app }
    appCopy.storiesOrder = e.target.value
    this.setState({ app: appCopy })
  }

  handleTagPublicityChange = (e) => {
    this.setState({ viewTagsPublicly: e.target.checked })
  }

  handleUserGeneratedContentChange = (e) => {
    this.setState({ userGeneratedContentAllowed: e.target.checked })
  }

  handleMapStyleChange = (e) => {
    const appCopy = { ...this.state.app }
    appCopy.mapStyle = e.target.value
    this.setState({ app: appCopy })
  }

  handleViewSubmissionPage = () => {
    Util.openInNewTab(`/present/${this.state.app._id}/ugc`)
  }

  handlePrivacyPolicyChange = (e) => {
    this.setState({ termsOfService: e.target.value })
  }

  handleMapCenterAddressSelect = (suggest) => {
    if (suggest === undefined) {
      this.setState({
        mapCenterAddress: '',
        mapCenterAddressCoords: {
          lat: null,
          lng: null,
        },
      })

      return
    }

    const appCopy = { ...this.state.app }
    appCopy.mapCenterAddress = suggest.label
    appCopy.mapCenterAddressCoords = {
      lat: suggest.location.lat,
      lng: suggest.location.lng,
    }

    this.setState({ app: appCopy, mapCenterAddress: suggest.label })
  }

  handleGeoSuggestChange = (value) => {
    this.setState({ mapCenterAddress: value })
  }

  handleMapZoomChange = (e) => {
    const appCopy = { ...this.state.app }
    appCopy.mapZoom = e.target.value
    this.setState({ app: appCopy })
  }

  handleDisplayOptionChange = (displayOption) => {
    this.setState({ displayOption: displayOption })
  }

  render() {
    const { user, classes, open, handleClose, handleDelete, mapStyles } = this.props

    return (
      <Dialog open={open} fullWidth maxWidth={'sm'} onClose={handleClose}>
        <div style={{ padding: 20 }}>
          <div className={clsx('subtitle', 'maincolor')}>Edit {this.state.app.name}</div>
        </div>
        <form style={{ margin: 16 }}>
          <FormControl margin='normal' fullWidth>
            <MultilineText
              id={'name'}
              title={'Name: (100 char limit)'}
              value={this.state.app.name}
              change={this.handleChange}
              maxLength={100}
            />
          </FormControl>

          <MultilineText
            id='description'
            title={'Description'}
            placeholder={'App Description'}
            change={this.handleChange}
            value={this.state.app.description ? this.state.app.description : ''} />

          <FormControl margin='normal' fullWidth>
            <TextField
              id='select-template'
              select
              label='Template'
              onChange={this.handleTemplateChange}
              value={this.state.templateName}
              SelectProps={{ MenuProps: { className: classes.menu } }}
              margin='normal'>
              {this.props.templates
                .map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>

          <FormControl
            margin='normal'
            fullWidth
            style={{ display: this.state.templateName === 'squares' ? 'block' : 'none' }}>
            <SquaresTemplateDisplayChooser
              handleChange={this.handleDisplayOptionChange}
              displayOption={this.state.displayOption} />
          </FormControl>

          <FormControl margin='normal' fullWidth>
            <TextField
              id='select-status'
              select
              label='Status'
              onChange={this.handleStatusChange}
              value={getStatus(this.state.app.status)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin='normal'
            >
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl margin='normal' fullWidth>
            <TextField
              id='select-stories-order'
              select
              label='Order of Stories'
              onChange={this.handleStoriesOrderChange}
              value={this.state.app.storiesOrder}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                }
              }}
              margin='normal'>
              {storiesOrder.map((order) => (
                <MenuItem key={order.value} value={order.value}>
                  {order.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {user?.isSuperAdmin && (
            <>
              <FormControl margin='normal' fullWidth>
                <MultilineText
                  id={'googleAnalyticsToken'}
                  title={'Google Analytics Token'}
                  placeholder={'UA-######-#'}
                  value={this.state.app.googleAnalyticsToken || ''}
                  change={this.handleChange}
                  maxLength={100}
                />
              </FormControl>
            </>
         )}

          <FormControl margin='normal' fullWidth>
            <FormTitle text={'Map Center Address:'} />
            <Geosuggest
              onSuggestSelect={this.handleMapCenterAddressSelect}
              onChange={this.handleGeoSuggestChange}
              initialValue={this.state.mapCenterAddress}
              placeholder={'Address, City, State Zip'} />
          </FormControl>

          <FormControl margin='normal' fullWidth>
            <TextField
              id='select-mapstyle'
              select
              label='Select Map Style'
              onChange={this.handleMapStyleChange}
              value={this.state.app.mapStyle}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin='normal'>
              {mapStyles.map((style) => (
                <MenuItem key={style.value} value={style.value}>
                  {style.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl margin='normal' fullWidth>
            <TextField
              select
              label='Select Map Zoom'
              onChange={this.handleMapZoomChange}
              value={this.state.app.mapZoom}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin='normal'>
              {mapZooms.map((zoom) => (
                <MenuItem key={zoom.value} value={zoom.value}>
                  {zoom.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <ColorPicker
            type={'Main'}
            color={this.state.app.mainColor}
            colorPickerChange={this.handleMainColorPickerChange} />

          <ColorPicker
            type={'Secondary'}
            color={this.state.app.secondaryColor}
            colorPickerChange={this.handleSecondaryColorPickerChange} />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Switch
              checked={this.state.viewTagsPublicly}
              change={this.handleTagPublicityChange}
              label='View Tags Publicly' />

            <div style={{ display: 'flex' }}>
              <Switch
                label={'Allow users to submit content'}
                checked={this.state.userGeneratedContentAllowed}
                change={this.handleUserGeneratedContentChange} />

              <div
                onClick={this.handleViewSubmissionPage}
                style={{
                  display: this.state.userGeneratedContentAllowed ? 'block' : 'none',
                  textAlign: 'center',
                }}
                className={clsx('category_button', 'secondbg', 'm10', 'white', 'bold')}>
                View Submission Page
              </div>
            </div>

            {this.state.userGeneratedContentAllowed && (
              <>
                <FormControl margin='normal' fullWidth>
                  <InputLabel htmlFor='redirect-link'>
                    Link to Redirect to:
                  </InputLabel>

                  <Input
                    id='redirect-link'
                    type='text'
                    classes={{ input: classes.cssOverrides }}
                    value={this.state.app.userGeneratedContentRedirectionLink}
                    onChange={this.handleChange} />
                </FormControl>

                <MultilineText
                  title={'Terms of Service'}
                  placeholder={'Enter your Terms of Service (default is Pass It Down\'s)'}
                  value={this.state.termsOfService}
                  change={this.handlePrivacyPolicyChange}
                />
              </>
            )}

          </div>

          <UploadImage
            id={'edit-app'}
            image={this.state.app.image}
            handleReceiveImage={this.handleReceiveImage}
          />
        </form>

        <div style={{ margin: 16 }}>
          <CategoryButton
            click={handleClose}
            className={clsx(classes.button, 'bold')}>
            Cancel
          </CategoryButton>

          <CategoryButton
            click={handleDelete}
            className={clsx(classes.button, 'bold')}>
            Delete
          </CategoryButton>
          <CategoryButton
            click={this.handleSave}
            className={clsx(classes.buttonSave, 'bold')}>
            Save Exhibit
          </CategoryButton>
        </div>

      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditAppDialog))
