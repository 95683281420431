import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import adminApi from '../../store/exhibitAdminApi'


const styles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export default function UploadImage(props) {
  const { id, handleReceiveImage, width } = props
  let { image } = props

  const classes = styles()

  const showImage =
    image === undefined || image === '' || image === null ? false : true

  if(showImage) image = adminApi.image().imageUrl(image, 500, 500)

  return (
    <div>
      <label htmlFor={id}>
        <Button variant='contained' component='span' className={classes.button}>
          Upload Image
        </Button>
      </label>

      <input
        data-testid='upload-image'
        accept='image/*'
        onChange={handleReceiveImage}
        className={classes.input}
        id={id}
        type='file'
      />

      <img
        style={{
          objectFit: 'cover',
          width: width ? width : 200,
          height: 200,
          display: showImage ? 'block' : 'none',
        }}
        src={image}
        alt={'org'}
      />
    </div>
  )
}
