import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import CreateAppDialog from './CreateAppDialog'
import Snackbar from '../util/Snackbar'
import { Route } from 'react-router-dom'
import AppsListPage from './AppsListPage'
import CollectionsPage from '../../CollectionsPage'
import UserContext from '../../context/UserContext'
import { showMessage } from '../../store/snackBarSlice'

import { loadByOrgIdAndUserId as loadExhibits, allExhibits } from '../../store/exhibitSlice'
import { loadMapStyles, allMapStyles } from '../../store/mapStyleSlice'
import { currentOrg } from '../../store/organizationSlice'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  drawerHeader: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  organization: {
    margin: 0,
    padding: '15px 2%',
    background: '#f1f1f1',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginTop: 20,
  },
  categoryButton: {
    color: '#06c',
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    background: '#06c',
    fontWeight: '700',
    letterSpacing: '-.6px',
  },
})

function Apps(props) {
  const orgId = props.match.params.orgId

  const dispatch = useDispatch()
  const apps = useSelector(allExhibits)
  const org = useSelector(currentOrg)
  const mapStyles = useSelector(allMapStyles)
  const currentUser = useContext(UserContext)

  const [openCreateAppDialog, setOpenCreateAppDialog] = useState(false)

  useEffect(() => {
    dispatch(loadMapStyles())
    dispatch(loadExhibits(orgId, currentUser))
  } ,[])

  const handleClick = async () => {
    const maxNumberOfApps = org.plan

    if (apps.length  >= maxNumberOfApps) {
      dispatch(showMessage('You have exceeded your maximum number of apps. Please contact sales to purchase more'))
      return
    }

    setOpenCreateAppDialog(true)
  }

  const handleCloseCreateAppDialog = () => {
    setOpenCreateAppDialog(false)
  }

  const handleAppClick = (appId) => () => {
    props.history.push(`${props.match.url}/${appId}`)
  }

  const handleAssetsClicked = () => {
    props.history.push(`${props.match.url}/assets`)
  }

  return (
    <div>
      <Route
        exact
        path={props.match.path}
        render={(props) => (
          <AppsListPage
            {...props}
            org={org}
            apps={apps}
            handleAddNewClicked={handleClick}
            assetsClicked={handleAssetsClicked}
            handleAppClick={handleAppClick}
          />
        )}
      />

      <Route
        path={`${props.match.path}/:appId`}
        render={(props) => (
          <CollectionsPage
            {...props}
            org={org}
            user={currentUser}
            drawerOpen={props.drawerOpen}
            toggleDrawer={props.toggleDrawer}
          />
        )}
      />

      <CreateAppDialog
        org={org}
        user={currentUser}
        mapStyles={mapStyles}
        open={openCreateAppDialog}
        handleClose={handleCloseCreateAppDialog}
      />

      <Snackbar />
    </div>
  )
}

export default withStyles(styles)(Apps)
