import { createSlice } from '@reduxjs/toolkit'
import adminApi from './exhibitAdminApi'

export const mapStyleSlice = createSlice({
  name: 'mapstyle',
  initialState: {
    all: []
  },
  reducers: {
    setMapStyles: (state, action) => {
      state.all = action.payload
    }
  }
})

export const { setMapStyles } = mapStyleSlice.actions

export const loadMapStyles = () => async (dispatch) => {
  const mapStyles = await adminApi.mapStyle().getMapStyles()

  dispatch(setMapStyles(mapStyles))
}

export const allMapStyles = state => state.mapstyle.all

export default mapStyleSlice.reducer

