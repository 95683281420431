import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import clsx from 'clsx'

const styles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      float: 'left',
      width: '85%',
    },
  })
)


export default function PlatformTop(props) {
  const { className } = props
  const classes = styles()

  return (
    <div className={clsx(classes.root, className && className)}>
      {props.children}
    </div>
  )
}
