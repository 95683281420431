import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Img } from 'react-progressive-loader'

import './StoryTile.css'
import clsx from 'clsx'

// https://stackoverflow.com/a/23633499/4455570
// Using viewport widths to make tiles square
const useStyles = makeStyles((theme) => ({
  storyTile: {
    margin: '3% 1.5vw',
    position: 'relative',
    float: 'left',
    '&:hover': {
      cursor: 'pointer',
      display: 'block',
    },
    [theme.breakpoints.up('xs')]: {
      width: '97vw',
      height: '97vw',
    },
    [theme.breakpoints.up('sm')]: {
      width: '43vw',
      height: '43vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '28vw',
      height: '28vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20vw',
      height: '20vw',
    },
  },
}))


export default function StoryTile(props) {
  const classes = useStyles()

  const { className, click, image } = props

  return (
    <div
      className={clsx(classes.storyTile, !!className && className)}
      onClick={click}>
      <Img
        src={image}
        className={'storyTile'}
        style={{ width: '100%', height: '100%' }}
        loadOnScreen
      />
      {props.children}
    </div>
  )
}
