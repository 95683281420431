import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import GrayArrow from '../../images/gray_arrow.png'
import { createStyles, withStyles } from '@material-ui/core'
import FormRow from '../UI/form/FormRow'
import Input from '../UI/Input'
import Container from '../UI/Container'
import FormTitle from '../UI/form/FormTitle'
import SubTitle from '../UI/SubTitle'
import CategoryButton from '../UI/CategoryButton'
import clsx from 'clsx'

const styles = () =>
  createStyles({
    saveButton: {
      margin: '10px 0 15px !important',
    },
    cancelButton: {
      backgroundColor: 'unset',
      display: 'flex',
      alignItems: 'center',
    },
  })


class CreateOrganizationDialog extends Component {
  render() {
    const {
      classes,
      open,
      newOrg,
      handleChange,
      handleClose,
      handleSave,
    } = this.props

    return (
      <Dialog open={open} onClose={handleClose}>
        <Container>
          <SubTitle>Add a new organization.</SubTitle>
          <FormRow>
            <Input
              text={'Organization Name:'}
              id={'orgname'}
              value={newOrg.name}
              placeholder="Name of your organization"
              change={handleChange}
            />
          </FormRow>
          <FormRow>
            <FormTitle text={'Plan:'} />
            <select
              onChange={handleChange}
              id="plan"
              style={{
                width: 180,
                background: `url(${GrayArrow})center center/contain no-repeat`,
                backgroundPosition: 'right',
              }}
            >
              <option value="1">1 Application</option>
              <option value="2">2 Applications</option>
              <option value="3">3 Applications</option>
              <option value="4">4 Applications</option>
              <option value="5">5 Applications</option>
              <option value="6">6 Applications</option>
              <option value="7">7 Applications</option>
              <option value="8">8 Applications</option>
              <option value="9">9 Applications</option>
              <option value="10">10 Applications</option>
              <option value="20">20 Applications</option>
              <option value="25">25 Applications</option>
              <option value="30">30 Applications</option>
              <option value="100000">Unlimited</option>
            </select>
          </FormRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CategoryButton
              click={handleClose}
              className={clsx(classes.cancelButton, 'bold')}
            >
              Cancel
            </CategoryButton>
            <div style={{ flexGrow: 1 }} />
            <CategoryButton
              click={handleSave}
              className={clsx(classes.saveButton, 'white', 'bold')}
            >
              Save Organization
            </CategoryButton>
          </div>
        </Container>
      </Dialog>
    )
  }
}

export default withStyles(styles, { withTheme: true })(CreateOrganizationDialog)
