import React from 'react'
import ReactPlayer from 'react-player'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        width: 100,
        height: 100,
        position: 'relative'
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0
    }
})

function YouTubeVideoUpload(props){

    const { link, click } = props

    const classes = useStyles()

    return (
        <div className={classes.root} onClick={click}>
            <ReactPlayer className={classes.reactPlayer} url={link} width='100%' height='100%' />
            {/* Prevents Youtube controls from working: https://stackoverflow.com/a/32601686/4455570 */}
            <div style={{
                position: 'absolute',
                background: 'rgba(0,0,0,0)',
                width: 'inherit',
                height: 'inherit',
                top: 0,
                left: 0
            }}/>
        </div>
    )
}

export default YouTubeVideoUpload